import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IFU from '../../../assets/IFU.svg'
import { Body } from '../../../containers/PatientInfo/PatientInfo'
import { TicketType } from '../../../containers/Tickets/constants/constants'
import { useSessionContext } from '../../../core/contexts/session-context'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import { DeploymentType } from '../../../core/entities/siteSettings.entity'
import { IFUPdfDownloader } from '../../IFUPdfDownloader/IFUPdfDownloader'
import { MarkDownTypes } from '../../MarkDownComponent/interfaces/interfaces'
import { ModalComponent } from '../../Modal/Modal'
import { AboutConfig, Section, SectionTitle } from '../About/About'
import { IfuWarningComponent } from '../IfuWarningComponent/IfuWarningComponent'
import { MarkDownSection } from '../MarkDownSection/MarkDownSection'
import {
  BodyWrapper,
  TitleComponent
} from '../ProductInformation/ProductInformation'
import { TicketSection } from '../TicketSection/TicketSection'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 31.25rem;
  padding: 2rem;
  gap: 1.5rem;
  border-radius: 2rem;
  background-color: var(--white-color);
`

interface SupportProps {
  closeFun: () => void
  config: AboutConfig
  showFragileInfo: boolean
  showTicketCreation: (ticketType: TicketType) => void
}
export const Support = ({
  closeFun,
  config,
  showFragileInfo,
  showTicketCreation
}: SupportProps) => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const { siteSettings } = useSiteSettingsContext()

  return (
    <ModalComponent closeFun={closeFun} closetestid="closeSupportModal">
      <Wrapper data-testid="supportModal">
        <Section>
          <SectionTitle>{`${config.deploymentName} ${t(
            'Support'
          )}`}</SectionTitle>
          <MarkDownSection
            userType={user?.type}
            markDownType={MarkDownTypes.phone}
          />
          <MarkDownSection
            userType={user?.type}
            markDownType={MarkDownTypes.link}
          />
          {showFragileInfo && (
            <Body>
              {siteSettings &&
                siteSettings.deploymentType !== DeploymentType.Production && (
                  <IfuWarningComponent
                    deploymentType={siteSettings.deploymentType}
                  />
                )}
              <BodyWrapper>
                <TitleComponent
                  images={[IFU]}
                  title={t('Instructions For Use (IFU)')}
                  markDown={null}
                  uncenterImage={false}
                />
                <IFUPdfDownloader />
              </BodyWrapper>
            </Body>
          )}
        </Section>
        <TicketSection
          closeFun={closeFun}
          showTicketCreation={showTicketCreation}
        />
      </Wrapper>
    </ModalComponent>
  )
}
