import { useTranslation } from 'react-i18next'
import {
  GenericBadgeStatus,
  StatusBadge
} from '../../SharedIntegrationComponents/StatusBadge/StatusBadge'
import { PMIStateValues } from '../ProviderPatients/ProviderPatientsInterfaces'

export const PatientManagedStatusBadge = ({
  state
}: {
  state: PMIStateValues
}) => {
  const { t } = useTranslation()
  const getPatientManagedStatusBadgeConfig = () => {
    switch (state) {
      case PMIStateValues.CONNECTING:
        return {
          text: t('not completed'),
          status: GenericBadgeStatus.warning
        }
      case PMIStateValues.CONNECTED:
        return { text: t('connected'), status: GenericBadgeStatus.ok }
      case PMIStateValues.DISCONNECTED:
        return {
          text: t('disconnected'),
          status: GenericBadgeStatus.warning
        }
      case PMIStateValues.NOT_SETUP:
        return {
          text: t('not setup'),
          status: GenericBadgeStatus.default
        }
    }
  }
  const config = getPatientManagedStatusBadgeConfig()
  return <StatusBadge text={config.text} status={config.status} />
}
