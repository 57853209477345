import { useMemo } from 'react'

export function useFiltersCount(filters: {
  [key: string]: string[] | string | undefined
}) {
  const count = useMemo(() => {
    return Object.values(filters)
      .map((value) => {
        if (Array.isArray(value)) {
          if (value.length > 0) return 1
          return 0
        }
        return 1
      })
      .reduce((acc: number, current: number) => acc + current, 0)
  }, [filters])

  return count
}
