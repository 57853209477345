import styled from 'styled-components'

export const Page = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
export const Wrapper = styled.div`
  display: flex;
  width: 37.5rem;
  height: 18.625rem;
  padding: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 2rem;
  background-color: var(--element-bg);
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translateY(50%) translateX(-50%);
  box-sizing: border-box;
`

export const ErrorHeader = styled.div`
  display: flex;
  flex-direction: column;
  ustify-content: flex-end;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
`
export const HeaderTitle = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase;
`
export const HeaderText = styled.span`
  color: var(--yellow);
  font-family: inter;
  font-size: 4rem;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.25rem;
`

export const Info = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  white-space: pre-line;
`
