import { useCallback, useEffect, useReducer, useState } from 'react'

export enum FetchStatus {
  Idle,
  Loading,
  Error,
  Success
}

enum FetchActionType {
  Fetch,
  Error,
  Success
}

export type FetchAction<T> =
  | {
      type: FetchActionType.Fetch
    }
  | {
      type: FetchActionType.Error
      payload: Error
    }
  | {
      type: FetchActionType.Success
      payload: T
    }

interface FetchState<T> {
  response: T | null
  error: Error | null
  status: FetchStatus
}

export function useFetch<DataType>(fetchFunction: any) {
  const [refetchTrigger, setRefetchTrigger] = useState(0)
  const [state, dispatch] = useReducer<
    React.Reducer<FetchState<DataType>, FetchAction<DataType>>
  >(fetchReducer, {
    response: null,
    error: null,
    status: FetchStatus.Idle
  })

  const fetchData = useCallback(
    async (controller: AbortController) => {
      dispatch({ type: FetchActionType.Fetch })
      try {
        const response = await fetchFunction({ signal: controller.signal })
        dispatch({
          type: FetchActionType.Success,
          payload: response as DataType
        })
      } catch (error) {
        if (!controller.signal.aborted) {
          dispatch({ type: FetchActionType.Error, payload: error as Error })
        }
      }
    },
    [fetchFunction]
  )

  useEffect(() => {
    const controller = new AbortController()

    fetchData(controller)

    return () => {
      controller.abort()
    }
  }, [fetchData, refetchTrigger])

  function refresh() {
    setRefetchTrigger((prev) => prev + 1)
  }

  return {
    ...state,
    refresh
  }
}

function fetchReducer<T>(
  state: FetchState<T>,
  action: FetchAction<T>
): FetchState<T> {
  switch (action.type) {
    case FetchActionType.Fetch:
      return {
        ...state,
        status: FetchStatus.Loading,
        error: null,
        response: null
      }
    case FetchActionType.Error:
      return {
        ...state,
        status: FetchStatus.Error,
        error: action.payload,
        response: null
      }
    case FetchActionType.Success:
      return {
        ...state,
        status: FetchStatus.Success,
        response: action.payload,
        error: null
      }
    default:
      return state
  }
}
