import styled from 'styled-components'

export enum GenericBadgeStatus {
  warning = 'warning',
  error = 'error',
  ok = 'ok',
  neutral = 'neutral',
  default = 'default'
}
interface StatusBadgeProps {
  status: GenericBadgeStatus
  text: string
}
const StatusBadgeWrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid;
  height: 2rem;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
`
const StatusBadgeText = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  font-family: inter;
  text-transform: uppercase;
  color: inherit;
  white-space: nowrap;
`

export const getStatusBadgeStyle = (state: GenericBadgeStatus) => {
  switch (state) {
    case GenericBadgeStatus.warning:
      return {
        color: 'var(--yellow)',
        borderColor: 'var(--yellow)',
        background: 'var(--yellow-gradient)'
      }
    case GenericBadgeStatus.error:
      return {
        color: 'var(--veryLow-darker-color)',
        borderColor: 'var(--veryLow-darker-color)',
        background: 'var(--veryLow-gradient)'
      }
    case GenericBadgeStatus.ok:
      return {
        color: 'var(--green-darker)',
        borderColor: 'var(--green-darker)',
        background: 'var(--green-gradient)'
      }
    case GenericBadgeStatus.neutral:
      return {
        color: 'var(--blue-darker)',
        borderColor: 'var(--blue-darker)',
        background: 'var(--blue-gradient)'
      }
    case GenericBadgeStatus.default:
      return {
        color: 'var(--text-lighter)',
        borderColor: 'var(--text-lightest)',
        backgroundColor: 'var(--white-color)'
      }
  }
}

export const StatusBadge = ({ status, text }: StatusBadgeProps) => {
  return (
    <StatusBadgeWrapper
      style={getStatusBadgeStyle(status)}
      data-testid="StatusBadgeWrapper"
    >
      <StatusBadgeText data-testid="StatusBadgeText">{text}</StatusBadgeText>
    </StatusBadgeWrapper>
  )
}
