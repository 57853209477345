import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
const TicketFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.625rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
`

const TicketSubmitButton = styled.input`
  padding: 0.75rem 2rem;
  height: 3rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background-color: var(--brand-primary-color);
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  color: var(--white-color);
  cursor: pointer;
  border: none;
  &:hover&:not(:disabled) {
    background-color: var(--brand-darker-color);
  }
  &:disabled {
    opacity: 0.16;
    cursor: default;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  justify-content: center;
  align-items: center;
  display: flex;
  height: 3rem;
  box-sizing: border-box;
`
const InfoCircle = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: var(--blue);
  line-height: 1.25rem;
`
const InfoText = styled.span`
  white-space: pre-wrap;
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
`

interface TicketFooterProps {
  isValid: boolean
}
export const TicketFooter = ({ isValid }: TicketFooterProps) => {
  const { t } = useTranslation()
  return (
    <TicketFooterWrapper>
      <InfoWrapper>
        <InfoCircle icon={faInfoCircle}></InfoCircle>
        <InfoText>{t('ticket_footer_info_box_paragraph')}</InfoText>
      </InfoWrapper>
      <TicketSubmitButton
        data-testid="SubmitTicketButton"
        type="submit"
        value={t('ticket_submit_button')}
        disabled={!isValid}
      ></TicketSubmitButton>
    </TicketFooterWrapper>
  )
}
