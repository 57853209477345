import {
  ProviderManagementTypes,
  ProviderPatient
} from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'
import { PaginatedRequest, PaginatedResponse } from './pagination'

export interface ProviderPatientsParams extends PaginatedRequest {
  searchString?: string
}

export async function getProviderPatients(
  params: ProviderPatientsParams,
  options: object = {},
  managementType: ProviderManagementTypes | null
): Promise<PaginatedResponse<ProviderPatient>> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/cmi/patients`,
    {
      ...options,
      params: { ...params, order: 'Ascending', managementType },
      paramsSerializer: {
        indexes: null
      }
    }
  )
  return response.data as PaginatedResponse<ProviderPatient>
}
