import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CMIIntegrationValue } from '../../../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { resetIntegration } from '../../../../../../core/api/reset-integration'
import {
  SnackBar,
  SnackBarType
} from '../../../../../../libraries/Toast/SnackBar'

const Wrapper = styled.button`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
  display: flex;
  padding: 0rem 0.625rem;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    border: 0.0625rem solid var(--brand-primary-16-color);
    background: var(--brand-primary-12-color);
    color: var(--brand-primary-color);
  }
  box-sizing: border-box;
  height: 2.25rem;
`
const Icon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.875rem;
  line-height: 2.25rem;
`
const Text = styled.span`
  color: inherit;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 600;
  text-decoration: none;
`
interface ResetIntegrationProps {
  rowValue: CMIIntegrationValue
  refresh: (() => void) | undefined
}

export const ResetIntegration = ({
  rowValue,
  refresh
}: ResetIntegrationProps) => {
  const { t } = useTranslation()

  const handleResetIntegration = async () => {
    try {
      await resetIntegration(rowValue.id)
    } catch (error) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('reset_integration_error_message')
      })
    } finally {
      refresh && refresh()
    }
  }

  return (
    <Wrapper onClick={handleResetIntegration}>
      <Icon icon={faArrowsRotate}></Icon>
      <Text>{t('reset_integration_table_text')}</Text>
    </Wrapper>
  )
}
