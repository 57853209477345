import {
  faCheck,
  faDownToLine,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CSSProperties } from 'styled-components'
import { uploadData } from '../../core/api/upload-data-api'
import { usePatientContext } from '../../core/contexts/patient-context'
import { usePeriodContext } from '../../core/contexts/period-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { UserType } from '../../core/entities/user.entity'
import { ErrorComponent as DuplicationErrorComponent } from '../ErrorComponent/ErrorComponent'
import { ErrorComponentModal } from '../ErrorComponent/ErrorComponentModal/ErrorComponentModal'
import { Modal } from '../Modal/Modal'
import {
  BrowseButton,
  DownloadComponent,
  DragFileElement,
  DragInfoSubText,
  DragInfoTitle,
  DragInfoWrapper,
  ErrorComponent,
  FileInput,
  FileInputLabel,
  FileInputTitle,
  LoadComponent,
  StatusLogo,
  SuccessComponent,
  UploadComponentWrapper,
  UploadDataWrapperFixed,
  UploadError,
  UploadSuccess
} from './UploadDataStyledComponents'
export enum UploaderType {
  medtronic = 'medtronic',
  dexcom = 'dexcom',
  abbott = 'abbott'
}

const GetUploadTypeString = (type: UploaderType) => {
  switch (type) {
    case UploaderType.medtronic:
      return 'medtroniccarelink'
    case UploaderType.dexcom:
      return 'dexcomclarity'
    case UploaderType.abbott:
      return 'abbottlibreview'
    default:
      return ''
  }
}
const GetUploadTypeTitle = (type: UploaderType) => {
  switch (type) {
    case UploaderType.medtronic:
      return 'Medtronic Carelink'
    case UploaderType.dexcom:
      return 'Dexcom Clarity'
    case UploaderType.abbott:
      return 'Abbott Libreview'
    default:
      return ''
  }
}

export enum CloudFileInspectionError {
  DuplicateData = 'DuplicateData',
  OverlappingDevices = 'OverlappingDevices'
}

interface UploadDataComponentProps {
  uploadertype: UploaderType
  patientId?: string
  strict?: boolean
  style?: CSSProperties
  showErrorInModal?: boolean
}

interface CloudFileInspectionErrorDay {
  day: string
  errors: CloudFileInspectionError[]
}

interface CloudFileInspectionErrors {
  days: CloudFileInspectionErrorDay[]
  totalDuplicates: number
  totalOverlapping: number
}

export interface CloudFileUploadsWrapper {
  errors: CloudFileInspectionErrors
}

export const UploadDataComponent = ({
  uploadertype,
  patientId,
  strict,
  style,
  showErrorInModal
}: UploadDataComponentProps) => {
  const { t } = useTranslation()
  const { patient, refreshPatient, setPatient } = usePatientContext()
  const { setPeriod } = usePeriodContext()
  const { user } = useSessionContext()
  // drag state
  const [dragActive, setDragActive] = useState(false)
  const [uploadError, setUploadError] = useState<UploadError | null>(null)
  const [uploadSuccess, setUploadSuccess] = useState<UploadSuccess | null>(null)
  const [currentFile, setCurrentFile] = useState<string | null>(null)
  // error content (duplication and overlap detection)
  const [duplicationErrorContent, setDuplicationErrorContent] = useState<
    string | null
  >(null)

  // ref
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (uploadSuccess !== null) {
      const t = setTimeout(() => {
        setCurrentFile(null)
        setUploadSuccess(null)
      }, 3600)
      return () => clearTimeout(t)
    }
  }, [uploadSuccess])

  useEffect(() => {
    if (uploadError !== null) {
      const t = setTimeout(() => {
        setCurrentFile(null)
        setUploadError(null)
      }, 3600)
      return () => clearTimeout(t)
    }
  }, [uploadError])

  const handleUpdateAfterUpload = () => {
    if (user?.type === UserType.Patient && patient) {
      const patientCopy = { ...patient }
      setPeriod(null)
      setPatient(null)
      setPatient(patientCopy)
    } else {
      refreshPatient && refreshPatient()
    }
  }

  const handleFile = async (file: FileList, type: UploaderType) => {
    const formData = new FormData()
    if (patient || patientId) {
      setCurrentFile(file[0].name)
      formData.append('file', file[0])
      const duplicationAndOverlapObject = {
        duplicationText: '',
        overlappingText: ''
      }
      try {
        const dataResponse = await uploadData(
          patient?.patientId ?? patientId ?? '',
          GetUploadTypeString(type),
          formData,
          strict
        )
        if (
          dataResponse.errors.totalDuplicates > 0 ||
          dataResponse.errors.totalOverlapping > 0
        ) {
          if (dataResponse.errors.totalDuplicates > 0) {
            duplicationAndOverlapObject.duplicationText = t(
              'libre_import_removed_days_error_duplicate_text',
              { count: dataResponse.errors.totalDuplicates }
            )
          }
          if (dataResponse.errors.totalOverlapping > 0) {
            duplicationAndOverlapObject.overlappingText = t(
              'libre_import_removed_days_error_overlapping_text',
              { count: dataResponse.errors.totalOverlapping }
            )
          }
          setDuplicationErrorContent(
            t(
              'libre_import_removed_days_error_title',
              duplicationAndOverlapObject
            )
          )
          setUploadSuccess(UploadSuccess.fileuploaded)
        } else {
          setUploadSuccess(UploadSuccess.fileuploaded)
          handleUpdateAfterUpload()
        }
      } catch (error: any) {
        setUploadError(UploadError.wrongfiletype)
      }
    }
  }
  const handleDrag = function (e: any) {
    setUploadError(null)
    setUploadSuccess(null)
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else {
      setDragActive(false)
    }
  }

  const handleDrop = function (e: any, type: UploaderType) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files, type)
    }
  }

  const handleChange = function (e: any, type: UploaderType) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files, type)
    }
  }

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click()
  }
  return (
    <>
      {showErrorInModal ? (
        <Modal isOpen={duplicationErrorContent !== null}>
          <ErrorComponentModal
            closeFun={() => {
              setDuplicationErrorContent(null)
              handleUpdateAfterUpload()
            }}
            content={duplicationErrorContent ?? ''}
            testId={'duplication_and_overlapping_error_wrapper'}
          ></ErrorComponentModal>
        </Modal>
      ) : (
        duplicationErrorContent && (
          <DuplicationErrorComponent
            testId={'duplication_and_overlapping_error_wrapper'}
            content={duplicationErrorContent}
          />
        )
      )}
      <UploadDataWrapperFixed
        style={style}
        data-testid={'uploadwrapper' + uploadertype}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <FileInput
          ref={inputRef}
          type="file"
          multiple={false}
          onClick={() => {
            setUploadError(null)
            setUploadSuccess(null)
          }}
          onChange={(event) => handleChange(event, uploadertype)}
          data-testid={'fileinput' + uploadertype}
        />
        <FileInputLabel
          id="label-file-upload"
          htmlFor="input-file-upload"
          active={dragActive}
          status={uploadError || uploadSuccess || null}
        >
          <FileInputTitle active={dragActive}>
            {t(GetUploadTypeTitle(uploadertype))}
          </FileInputTitle>
          <DragInfoWrapper>
            <DragInfoTitle active={dragActive}>
              {currentFile || t('Drag and drop file here')}
            </DragInfoTitle>
            {!dragActive && !uploadError && !uploadSuccess && (
              <DragInfoSubText>{t('or')}</DragInfoSubText>
            )}
            {!dragActive && !uploadError && !uploadSuccess && currentFile ? (
              <LoadComponent></LoadComponent>
            ) : (
              <BrowseButton
                data-testid={'browsebutton' + uploadertype}
                onClick={onButtonClick}
                disabled={uploadSuccess !== null || uploadError !== null}
              >
                {t('Browse')}
              </BrowseButton>
            )}
            {dragActive && <DownloadComponent icon={faDownToLine} />}
            {uploadError && (
              <ErrorComponent data-testid="errorcomponent">
                {uploadError}
                <StatusLogo icon={faExclamationTriangle} />
              </ErrorComponent>
            )}
            {uploadSuccess && (
              <SuccessComponent data-testid="successcomponent">
                {uploadSuccess}
                <StatusLogo icon={faCheck} />
              </SuccessComponent>
            )}
          </DragInfoWrapper>
        </FileInputLabel>
        {dragActive && (
          <DragFileElement
            data-testid={'dropcomponent' + uploadertype}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={(event) => handleDrop(event, uploadertype)}
          ></DragFileElement>
        )}
      </UploadDataWrapperFixed>
    </>
  )
}

export const UploadData = () => {
  const UploadComponents = (
    Object.keys(UploaderType) as Array<keyof typeof UploaderType>
  ).map((key) => (
    <UploadDataComponent
      key={key}
      uploadertype={key as UploaderType}
      showErrorInModal={true}
    />
  ))
  return (
    <UploadComponentWrapper data-testid="UploadComponentsWrapper">
      {UploadComponents}
    </UploadComponentWrapper>
  )
}
