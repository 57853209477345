export enum ClinicViewTypes {
  Patients = 'Patients',
  Integrations = 'Integrations',
  AuditLog = 'AuditLog',
  UploadActivity = 'UploadActivity',
  PenTitrations = 'PenTitrations',
  Tickets = 'Tickets'
}

export enum ClinicViewDisplays {
  Population = 'Population',
  AllPatient = 'AllPatients',
  IntegrationTODO = 'IntegrationTODO',
  LibreViewConsultations = 'LibreViewConsultations',
  ProviderPatients = 'ProviderPatients',
  ProviderIntegrations = 'ProviderIntegrations',
  Table = 'Table'
}
export interface ClinicView {
  page: ClinicViewTypes
  subViews: ClinicViewDisplays[]
}
export interface Clinic {
  clinicId: string
  name: string
  pages: ClinicView[]
}
