import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ContextMenuItem } from '../../../../../ContextMenu/ContextMenu'

export const OpenPatientIntegrations = ({
  patientId
}: {
  patientId: string
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goToPatientIntegrations = () => {
    navigate(`/portal/patients/${patientId}/more/integrations`)
  }
  return (
    <ContextMenuItem
      onClick={() => goToPatientIntegrations()}
      icon={faUser}
      data-testid="consultationsOptionsGoToPatient"
    >
      {t('row_action_go_to_patient_integrations')}
    </ContextMenuItem>
  )
}
