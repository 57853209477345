import { Cell, flexRender, Row } from '@tanstack/react-table'
import { useState } from 'react'
import {
  Td,
  TdPadding,
  Tr,
  TrFloat
} from '../../TableStyledElements/TableStyledElements'

export function TableRow<T>({
  row,
  handleRowClick
}: {
  row: Row<T>
  handleRowClick?: (row: T) => void
}) {
  const [hovered, setHovered] = useState(false)

  function getRowContent<T>({ cell }: { cell: Cell<T, unknown> }) {
    if (cell.column.id === 'options') {
      return hovered ? (
        flexRender(cell.column.columnDef.cell, cell.getContext())
      ) : (
        <></>
      )
    } else {
      return flexRender(cell.column.columnDef.cell, cell.getContext())
    }
  }
  return (
    <>
      <Tr
        data-testid="table_data_row"
        onClick={
          handleRowClick ? () => handleRowClick(row.original) : () => null
        }
      >
        {row.getVisibleCells().map(
          (cell) =>
            cell.column.id !== 'floating' && (
              <Td
                key={cell.id}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                $context={cell.column.id === 'upload_context'}
                $options={cell.column.id === 'options'}
              >
                {getRowContent({ cell })}
              </Td>
            )
        )}
      </Tr>
      {row.getIsExpanded() &&
        row.getVisibleCells().map(
          (cell) =>
            cell.column.id === 'floating' &&
            cell.getValue() && (
              <TrFloat>
                <TdPadding
                  key={cell.id}
                  colSpan={row.getAllCells().length - 1}
                  $hideTopBorder={true}
                >
                  {getRowContent({ cell })}
                </TdPadding>
              </TrFloat>
            )
        )}
    </>
  )
}
