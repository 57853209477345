import {
  faCheck,
  faDownToLine,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uploadConsultationCsv } from '../../../../../core/api/consultations/upload-consultation-csv'
import {
  BrowseButton,
  DownloadComponent,
  DragFileElement,
  DragInfoSubText,
  DragInfoTitle,
  DragInfoWrapper,
  ErrorComponent,
  FileInput,
  FileInputLabel,
  FileInputTitle,
  LoadComponent,
  StatusLogo,
  SuccessComponent,
  UploadDataWrapper,
  UploadError,
  UploadSuccess
} from '../../../../UploadData/UploadDataStyledComponents'
import { ConsultationsSummaryData } from '../../../ConsultationsSummary/ConsultationsSummary'

interface ImportConsultationsFileDropProps {
  setSummary: Dispatch<SetStateAction<ConsultationsSummaryData | undefined>>
  setRefreshSummary: Dispatch<SetStateAction<boolean>>
}
export const ImportConsultationsFileDrop = ({
  setSummary,
  setRefreshSummary
}: ImportConsultationsFileDropProps) => {
  const { t } = useTranslation()
  // drag state

  const [dragActive, setDragActive] = useState(false)
  const [uploadError, setUploadError] = useState<UploadError | null>(null)
  const [uploadSuccess, setUploadSuccess] = useState<UploadSuccess | null>(null)
  const [currentFile, setCurrentFile] = useState<string | null>(null)
  const [uploadedPatients, setUploadedPatients] = useState<number | null>(null)
  // ref
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (uploadSuccess !== null) {
      const t = setTimeout(() => {
        setCurrentFile(null)
        setUploadSuccess(null)
      }, 3600)
      return () => clearTimeout(t)
    }
  }, [uploadSuccess])

  useEffect(() => {
    if (uploadError !== null) {
      const t = setTimeout(() => {
        setCurrentFile(null)
        setUploadError(null)
      }, 3600)
      return () => clearTimeout(t)
    }
  }, [uploadError])

  const handleFile = (file: FileList) => {
    const formData = new FormData()
    formData.append('file', file[0])
    setCurrentFile(file[0].name)
    uploadConsultationCsv(formData)
      .then((response) => {
        setUploadSuccess(UploadSuccess.fileuploaded)
        setUploadedPatients(response)
        setSummary(undefined)
      })
      .catch((error: Error) => {
        console.log(error)
        setUploadError(UploadError.wrongfiletype)
      })
      .finally(() => {
        setRefreshSummary((value) => !value)
      })
  }

  const handleDrag = function (e: any) {
    setUploadError(null)
    setUploadSuccess(null)
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else {
      setDragActive(false)
    }
  }

  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files)
    }
  }

  const handleChange = function (e: any) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files)
    }
  }

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click()
  }

  return (
    <UploadDataWrapper
      data-testid={'uploadwrapper'}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <FileInput
        ref={inputRef}
        type="file"
        accept="text/csv"
        multiple={false}
        onClick={() => {
          setUploadError(null)
          setUploadSuccess(null)
        }}
        onChange={(event) => handleChange(event)}
        data-testid={'fileinput'}
      />
      <FileInputLabel
        id="label-file-upload"
        htmlFor="input-file-upload"
        active={dragActive}
        status={uploadError || uploadSuccess || null}
      >
        <FileInputTitle active={dragActive}>
          {t('import_patient_list_title')}
        </FileInputTitle>
        <DragInfoWrapper>
          <DragInfoTitle active={dragActive}>
            {currentFile || t('Drag and drop file here')}
          </DragInfoTitle>
          {!dragActive && !uploadError && !uploadSuccess && (
            <DragInfoSubText>{t('or')}</DragInfoSubText>
          )}
          {!dragActive && !uploadError && !uploadSuccess && currentFile ? (
            <LoadComponent></LoadComponent>
          ) : (
            <BrowseButton data-testid={'browsebutton'} onClick={onButtonClick}>
              {t('Browse')}
            </BrowseButton>
          )}
          {dragActive && <DownloadComponent icon={faDownToLine} />}
          {uploadError && (
            <ErrorComponent data-testid="errorcomponent">
              {uploadError}
              <StatusLogo icon={faExclamationTriangle} />
            </ErrorComponent>
          )}
          {uploadSuccess && (
            <SuccessComponent data-testid="successcomponent">
              {t('uploaded_patients_import', {
                patientAmount: uploadedPatients
              })}
              <StatusLogo icon={faCheck} />
            </SuccessComponent>
          )}
        </DragInfoWrapper>
      </FileInputLabel>
      {dragActive && (
        <DragFileElement
          data-testid={'dropcomponent'}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={(event) => handleDrop(event)}
        ></DragFileElement>
      )}
    </UploadDataWrapper>
  )
}
