import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Wrapper = styled.div`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  display: flex;
  height: 1.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  width: fit-content;
`
export const ArchivedBadge = () => {
  const { t } = useTranslation()
  return (
    <Wrapper data-testid="ticket_archived_badge">
      {t('status_badge_archived_text')}
    </Wrapper>
  )
}
