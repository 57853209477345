import styled, { css } from 'styled-components'

export const StickyTable = styled.table`
  border-collapse: seperate;
  border-spacing: 0;
  position: relative;
  width: 100%;
  table-layout: fixed;
  background: var(--element-bg-transparent-80);

  & thead {
    background: #fff;
  }

  & tbody {
    tr {
      &:hover {
        background: var(--element-bg-dark);
      }
    }
  }

  & th:last-child {
    border-right: 0 !important;
  }

  & tr:first-child {
    td {
      border-top: 0.0625rem solid var(--text-lightest);
    }
    & td:last-child {
      border-top-right-radius: 0.5rem;
    }
    & td:first-child {
      border-top-left-radius: 0.5rem;
    }
  }
  & tr:last-child {
    td {
      border-bottom: 0.0625rem solid var(--text-lightest);
    }
    & td:last-child {
      border-bottom-right-radius: 0.5rem;
    }
    & td:first-child {
      border-bottom-left-radius: 0.5rem;
    }
  }
`

export const FooterStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Th = styled.th<{ $options?: boolean }>`
  border-left: 0.0625rem solid var(--text-lightest);
  padding: 0.375rem 0.75rem 0.375rem 0.5rem;
  box-sizing: border-box;
  text-align: left;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-lighter);
  line-height: 1rem;
  height: 3rem;
  ${(props) =>
    props.$options === true
      ? css`
          border-left: 0 !important;
        `
      : css``}
  &:first-child {
    border-left: 0 !important;
  }
`

export const ThWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const Td = styled.td<{
  $options?: boolean
  $context?: boolean
  $hideTopBorder?: boolean
}>`
  border-top: ${(props) =>
    props.$hideTopBorder ? 'none' : '0.0625rem solid var(--text-lightest)'};
  border-left: 0.0625rem solid var(--text-lightest);
  &:last-child {
    border-right: 0.0625rem solid var(--text-lightest);
  }
  padding: 0.375rem 0.75rem 0.375rem 0.5rem;
  box-sizing: border-box;
  font-family: inter;
  font-size: 0.875rem;
  color: var(--text-primary);
  font-weight: 600;
  height: 3rem;
  ${(props) =>
    props.$options === true
      ? css`
          border-left: 0 !important;
          text-align: right;
        `
      : css`
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}
  ${(props) =>
    props.$context === true
      ? css`
          overflow-x: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          &::-webkit-scrollbar {
            display: none;
          }
        `
      : css``}
`
export const TdPadding = styled(Td)`
  padding: 0.5rem 1rem;
`
export const TableWrapper = styled.div`
  gap: 1rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const HeaderTopSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const HeaderTopSectionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
export const Tr = styled.tr``
export const TrFloat = styled.tr`
  pointer-events: none;
`
