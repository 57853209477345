import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const InfoContext = styled.div`
  background: var(--element-bg);
  border: 0;
  border-radius: 0.5rem;
  padding: 0.75rem;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
`

const Icon = styled(FontAwesomeIcon)`
  display: inline-block;
  margin-right: 0.375rem;
  color: var(--blue);
`

export function TicketFooterContext() {
  const { t } = useTranslation()
  return (
    <InfoContext>
      <Icon icon={faInfoCircle} />
      {t('tickets_table_context')}
    </InfoContext>
  )
}
