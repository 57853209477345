import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch, useOutletContext, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { SortEnum } from '../../../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { LoadMoreButton } from '../../../../components/LoadMoreButton/LoadMoreButton'
import { SearchBarComponent } from '../../../../components/SearchBarComponent/SearchBarComponent'
import { Spinner, spinnerSize } from '../../../../components/Spinner/Spinner'
import { ScrollTableComponent } from '../../../../components/Table/ScrollTableComponent'
import { loadIncrement } from '../../../../constants/loadIncrement'
import { useClinicViewsContext } from '../../../../core/contexts/clinic-view-context'
import { useSessionContext } from '../../../../core/contexts/session-context'
import { ClinicViewDisplays } from '../../../../core/entities/clinic.entity'
import { getTableData } from '../../functions/getTableData'
import { usePatientsHook } from '../../functions/usePatientsHook'
import { usePopulationsHook } from '../../functions/usePopulationsHook'
import { TableType } from '../interfaces'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1500px;
  width: 100%;
  margin-right: 2rem;
  margin-bottom: 2rem;
`
const Title = styled.div`
  font-family: inter;
  font-size: 1.25rem;
  color: var(--text-primary);
  line-height: 2.25rem;
  font-weight: 700;
`
const HeaderRow = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`
const HeaderSection = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-self: center;
`
const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
interface TableData {
  title: string
  header: JSX.Element
  tableGroup: JSX.Element
  tableHeader: JSX.Element
  tableBody: JSX.Element
}
interface GetTableProps {
  type: TableType
}

export const ClinicPatientsTable = ({ type }: GetTableProps) => {
  const { t } = useTranslation()
  const clinicViews = useClinicViewsContext()
  const { populationid } = useParams()
  const [refreshPatients, setRefreshPatients] = useState<boolean>(false)
  const [refreshPopulations, setRefreshPopulations] = useState<boolean>(false)
  const [sorting, setSorting] = useState<SortEnum>(SortEnum.Descending)
  const [searchString, setSearchString] = useState<string>('')
  const [size, setSize] = useState<number>(loadIncrement)
  const context: {
    updateMenu: boolean
    setUpdateMenu: Dispatch<SetStateAction<boolean>>
  } = useOutletContext()
  const passSearchString = (searchstring: string) => {
    setSearchString(searchstring)
  }
  const shouldGetPatients =
    useMatch('/portal/clinic/patients/all-patients') !== null ||
    populationid !== undefined
  const shouldGetPopulations = clinicViews.Patients.subViews.includes(
    ClinicViewDisplays.Population
  )

  const { user } = useSessionContext()
  const { patients, patientsError } = usePatientsHook(
    populationid,
    sorting,
    searchString,
    refreshPatients,
    shouldGetPatients,
    size
  )
  const { populations, populationsError } = usePopulationsHook(
    refreshPopulations,
    shouldGetPopulations
  )
  let data: TableData | undefined

  const updateContext = () => {
    context.setUpdateMenu(!context.updateMenu)
  }
  const updatePatients = () => {
    setRefreshPatients(!refreshPatients)
  }
  const updatePopulations = () => {
    setRefreshPopulations(!refreshPopulations)
  }
  if (type === TableType.patient) {
    data = getTableData({
      type,
      t,
      error: patientsError,
      user,
      config: {
        data: patients,
        sorting,
        setSorting,
        populationid,
        populations,
        refresh: updatePatients
      }
    })
  }
  if (type === TableType.population) {
    data = getTableData({
      type,
      t,
      error: populationsError,
      user,
      config: { data: populations, refresh: updatePopulations, updateContext }
    })
  }

  return (
    <Wrapper>
      {data ? (
        <>
          <HeaderRow>
            <HeaderSection>
              <Title>{data.title}</Title>
              {type === TableType.patient && (
                <SearchBarComponent
                  searchString={searchString}
                  passSearchString={passSearchString}
                />
              )}
            </HeaderSection>
            <HeaderSection>{data.header}</HeaderSection>
          </HeaderRow>
          <TableWrapper>
            <ScrollTableComponent group={data.tableGroup}>
              {data.tableHeader}
              {data.tableBody}
            </ScrollTableComponent>
            {patients && patients.size > size && (
              <LoadMoreButton
                loadAmount={size}
                setLoadAmount={setSize}
                maxSize={patients?.size}
              />
            )}
          </TableWrapper>
        </>
      ) : (
        <Spinner spinnersize={spinnerSize.large}></Spinner>
      )}
    </Wrapper>
  )
}
