import { faLinkSimple } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ConsultationsAccountButtonProps } from '../../constants/constants'
import {
  HoverableWrapper,
  Icon,
  Text
} from '../ConsultationsAccountLinkStyledComponents/ConsultationsAccountLinkStyledComponents'

export const FitbitConsultationsButton = ({
  providerPatient,
  inOptionsMenu
}: ConsultationsAccountButtonProps) => {
  const navigate = useNavigate()
  const goToPatient = () => {
    navigate(
      `/portal/patients/${providerPatient.assignedPatientId}/more/integrations`
    )
  }
  const { t } = useTranslation()
  return (
    <>
      {inOptionsMenu ? null : (
        <HoverableWrapper onClick={goToPatient}>
          <Icon icon={faLinkSimple}></Icon>
          <Text>
            {t(
              `data-filter.patientaccountlink.${providerPatient.providerType.toLowerCase()}`
            )}
          </Text>
        </HoverableWrapper>
      )}
    </>
  )
}
