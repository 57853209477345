import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getUploads } from '../../core/api/get-uploads'
import { PaginatedResponse } from '../../core/api/pagination'
import { useDataTableParams } from '../../hooks/useDataTableParams'
import { useFetch } from '../../hooks/useFetch'
import { useFiltersCount } from '../../hooks/useFiltersCount'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import { PatientLink } from '../PatientLink/PatientLink'
import {
  DataFilter,
  DataFilterType
} from '../TableComponents/Filters/DataFilter/DataFilter'
import { FiltersManager } from '../TableComponents/Filters/DataFilter/FiltersManager'
import { FilterFunctionButton } from '../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { TableLayout } from '../TableComponents/TableLayout/TableLayout'
import { TableSearchBar } from '../TableComponents/TableSearchBar/TableSearchBar'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  StickyTable,
  TableHeader,
  TableWrapper
} from '../TableComponents/TableStyledElements/TableStyledElements'
import { Title } from '../Title/Title'
import {
  ApplicationUploadActivityType,
  dataState,
  Filter,
  Filters,
  Manufacturer,
  TIME_PERIOD_MONTHS,
  Upload
} from './constants'
import { UploadActivityContext } from './UploadActivityContext'
import { UploadActivityOptions } from './UploadActivityOptions'
import { UploadPlatformContext } from './UploadContext/UploadContext'
import { UploadedBy } from './UploadedBy'

const DataTableWrapper = styled(StickyTable)`
  tr {
    th:first-child,
    td:first-child {
      width: 6.5625rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 4.9375rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 8rem;
    }
  }
`
export type UploadActivityFilterTypes = Filters & {
  cutoff?: string
  types?: ApplicationUploadActivityType[]
  manufacturers?: Manufacturer[]
}

const manufacturersDefault: string[] = []

const typesDefault: string[] = [
  ApplicationUploadActivityType.ClinicUploader,
  ApplicationUploadActivityType.HomeUploader,
  ApplicationUploadActivityType.App,
  ApplicationUploadActivityType.Legacy,
  ApplicationUploadActivityType.File
]

const defaults = {
  [Filter.types]: typesDefault,
  [Filter.manufacturers]: manufacturersDefault
}

export function UploadActivity() {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)
  const {
    tableParams,
    requestParams,
    setSearchString,
    setPagination,
    setFilters
  } = useDataTableParams<UploadActivityFilterTypes>({
    params: {
      searchString: '',
      cutoff: dayjs().subtract(TIME_PERIOD_MONTHS, 'months').format()
    },
    filters: defaults
  })
  const filtersCount = useFiltersCount(tableParams.filters)

  const fetchFunction = useCallback(
    (options: AxiosRequestConfig) => getUploads(requestParams, options),
    [requestParams]
  )
  const { response, error, status, refresh } =
    useFetch<PaginatedResponse<Upload>>(fetchFunction)

  const columns: ColumnDef<Upload>[] = useMemo(
    () => [
      {
        id: 'upload_date',
        header: t('Date'),
        sortDescFirst: true,
        accessorFn: (row: Upload) =>
          row.uploaded
            ? dayjs(row.uploaded).format('DD/MM/YYYY')
            : dataState.empty
      },
      {
        id: 'time',
        header: t('Time'),
        enableSorting: false,
        accessorFn: (row: Upload) =>
          row.uploaded
            ? dayjs(row.uploaded).format('HH:mm').toString()
            : dataState.empty
      },
      {
        id: 'cpr',
        header: t('CPR'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) => (
          <PatientLink patientId={row.original.patientId}>
            {row.original.patientMRN.substring(0, 6) +
              '-' +
              row.original.patientMRN.substring(6, 11)}
          </PatientLink>
        )
      },
      {
        id: 'patient_name',
        header: t('Patient name'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) => (
          <PatientLink patientId={row.original.patientId}>
            {row.original.patientName}
          </PatientLink>
        )
      },
      {
        id: 'manufacturer',
        header: t('Manufacturer'),
        enableSorting: false,
        accessorFn: (row: Upload) =>
          row.manufacturer ? row.manufacturer : dataState.empty
      },
      {
        id: 'device',
        header: t('Device'),
        enableSorting: false,
        accessorFn: (row: Upload) => row.deviceContext.modelDisplayName
      },
      {
        id: 'upload_context',
        header: t('Context'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) => (
          <UploadPlatformContext context={row.original.uploadContext} />
        ),
        size: 150
      },
      {
        id: 'upload_user',
        header: t('Upload user'),
        enableSorting: false,
        cell: ({ row }: { row: Row<Upload> }) =>
          row.original.user ? (
            <UploadedBy uploadedByUser={row.original.user} />
          ) : (
            <>-</>
          )
      },
      {
        id: 'options',
        cell: ({ row }: { row: Row<Upload> }) => (
          <UploadActivityOptions
            upload={row.original}
            onDelete={() => refresh()}
          />
        )
      }
    ],
    [refresh, t]
  )

  const table = useReactTable({
    data: response?.data || [],
    columns,
    rowCount: response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      pagination: tableParams.pagination,
      sorting: [{ id: 'upload_date', desc: false }]
    },
    onPaginationChange: setPagination,
    onSortingChange: () => ({}),
    getRowId: (originalRow) => originalRow.id
  })

  return (
    <ErrorBoundary>
      <TableWrapper>
        <TableHeader>
          <HeaderTopSection>
            <Title>{t('Upload Activity')}</Title>
            <HeaderTopSectionButtons>
              <TableSearchBar handleChange={setSearchString} />
              <FilterFunctionButton
                filtersCount={filtersCount}
                handleClick={handleFilterExpand}
                icon={faBarsFilter}
                text={t('Filters')}
              />
            </HeaderTopSectionButtons>
          </HeaderTopSection>
          {showFilters && (
            <FiltersManager>
              <DataFilter
                title={t('Context')}
                slug={Filter.types}
                type={DataFilterType.multiSelect}
                data={Object.values(ApplicationUploadActivityType)}
                initialState={defaults[Filter.types]}
                currentState={tableParams.filters[Filter.types]}
                setSelected={(filterValue) =>
                  setFilters(Filter.types, filterValue)
                }
              />
              <DataFilter
                title={t('Manufacturers')}
                slug={Filter.manufacturers}
                type={DataFilterType.multiSelect}
                data={Object.values(Manufacturer)}
                initialState={defaults[Filter.manufacturers]}
                currentState={tableParams.filters[Filter.manufacturers]}
                setSelected={(filterValue) =>
                  setFilters(Filter.manufacturers, filterValue)
                }
              />
            </FiltersManager>
          )}
        </TableHeader>
        <TableLayout
          table={table}
          context={<UploadActivityContext />}
          fetchStatus={status}
          fetchError={error}
          usesPagination={true}
          WrapperElement={DataTableWrapper}
        />
      </TableWrapper>
    </ErrorBoundary>
  )

  function handleFilterExpand() {
    setShowFilters((isVisible) => !isVisible)
  }
}
