import { faArrowRightLong, faClipboard } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import dayjs from '../../../../core/dayjs/dayjs'
import { SnackBar, SnackBarType } from '../../../../libraries/Toast/SnackBar'
import { ProviderPatient } from '../../../IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import {
  UploadDataComponent,
  UploaderType
} from '../../../UploadData/UploadData'
import { ConsultationsData } from '../../constants/constants'

const ConsultationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 27.25rem;
  padding: 2rem;
  gap: 2rem;
  border-radius: 2rem;
  background-color: var(--white-color);
`
const ModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const ModalHeaderText = styled.span`
  font-family: inter;
  font-weight 600;
  font-size; 0.875rem;
  color: var(--text-lighter);
`
const ModalTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
const ModalTitle = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
const ModalSubTitle = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-primary);
`

const ModalLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const LinkBox = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg);
  color: var(--text-medium);
  &:hover {
    background-color: var(--brand-primary-12-color);
    border-color: var(--brand-primary-color);
    color: var(--brand-primary-color);
  }
`

const LinkArrow = styled(FontAwesomeIcon)`
  font-weight: 400;
  font-size: 0.875rem;
  color: inherit;
`
const LinkText = styled.a`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  color: inherit;
  cursor: pointer;
`
const LinkIcon = styled(FontAwesomeIcon)`
  font-weight: 400;
  font-size: 0.875rem;
  color: inherit;
`

const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
const InstructionsSteps = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.25rem;
`
const InstructionsText = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--text-lighter);
`
interface ConsultationModalComponentProps {
  link: string | null
  providerPatient: ProviderPatient
  row: ConsultationsData | undefined
}

export const AbbottLibreviewConsultationsModal = ({
  link,
  providerPatient,
  row
}: ConsultationModalComponentProps) => {
  const { t } = useTranslation()

  const handleCopyToClipboard = async (link: string) => {
    try {
      await navigator.clipboard.writeText(link)
      SnackBar({
        type: SnackBarType.Success,
        message: t('Successfully copied to clipboard')
      })
    } catch (error) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error copying to clipboard')
      })
    }
  }

  const openLink = (link: URL) => {
    window.open(link, '_blank')
  }

  return (
    <ConsultationModalWrapper>
      <ModalHeaderWrapper>
        <ModalHeaderText>{t('Abbott LibreView user')}</ModalHeaderText>
      </ModalHeaderWrapper>
      <ModalTitleWrapper>
        <ModalTitle>{providerPatient.name}</ModalTitle>
        <ModalTitle>
          {row ? dayjs(row.consultationTime).format('DD/MM/YYYY') : '-'}
        </ModalTitle>
        <ModalTitle>{providerPatient.email ?? '-'}</ModalTitle>
      </ModalTitleWrapper>
      <ModalLinkWrapper>
        {link && (
          <>
            <LinkBox>
              <LinkText
                data-testid="link"
                onClick={() => openLink(new URL(link))}
              >
                {t('Open patient in LibreView')}
              </LinkText>
              <LinkArrow icon={faArrowRightLong} />
            </LinkBox>

            <LinkBox
              data-testid="linkCopy"
              onClick={() => handleCopyToClipboard(link)}
            >
              <LinkIcon icon={faClipboard}></LinkIcon>
              <LinkText>{t('Copy Link')}</LinkText>
            </LinkBox>
          </>
        )}
      </ModalLinkWrapper>
      {providerPatient.assignedPatientId !== null && (
        <>
          <InstructionsWrapper>
            <InstructionsText style={{ textTransform: 'uppercase' }}>
              {t('instructions')}
            </InstructionsText>
            <InstructionsSteps>
              <InstructionsText>
                1. {t('Open patient in LibreView')}
              </InstructionsText>
              <InstructionsText>
                2.{' '}
                {t(
                  'Download the CSV file from their profile (solve Captcha if needed)'
                )}
              </InstructionsText>
              <InstructionsText>
                3. {t('Upload the CSV file below')}
              </InstructionsText>
            </InstructionsSteps>
          </InstructionsWrapper>
          <UploadDataComponent
            uploadertype={UploaderType.abbott}
            patientId={row?.patientId ?? ''}
            strict={true}
            style={{ width: '100%' }}
          />
        </>
      )}
    </ConsultationModalWrapper>
  )
}
