import { useTranslation } from 'react-i18next'
import {
  ErrorHeader,
  HeaderText,
  HeaderTitle,
  Info,
  Wrapper
} from './ErrorStyles'

export const NotResponding = ({ errorMessage }: { errorMessage: string }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <ErrorHeader>
        <HeaderTitle>{t('server_not_responding_title')}</HeaderTitle>
        <HeaderText>{errorMessage}</HeaderText>
      </ErrorHeader>
      <Info>{t('server_not_responding_description')}</Info>
    </Wrapper>
  )
}
