import { ReactNode, useEffect } from 'react'
import Modal from 'react-modal'
import { Navigate, Route, Routes } from 'react-router-dom'
import * as ModalComponent from '../src/components/Modal/Modal'
import './App.css'
import { Consultations } from './components/Consultations/Consultations'
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary'
import { IdleDetector } from './components/IdleDetector/IdleDetector'
import { useIdleDetection } from './components/IdleDetector/useIdleDetection'
import { ClinicIntegrationsTable } from './components/IntegrationComponents/ClinicLevelComponents/ClinicIntegrationsTable/ClinicIntegrationsTable'
import { ProviderPatients } from './components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatients'
import { ProviderPatientsTableType } from './components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { PortalLayout } from './components/PortalLayout/PortalLayout/PortalLayout'
import { UploadActivity } from './components/UploadActivity/UploadActivity'
import { UploadData } from './components/UploadData/UploadData'
import { ExportDataComponent } from './components/Uploads/ExportDataComponent/ExportDataComponent'
import { PatientUploads } from './components/Uploads/Uploads'
import { AuditLog } from './containers/AuditLog/AuditLog'
import { BgLog } from './containers/BgLog/BgLog'
import { Clinic } from './containers/Clinic/Clinic'
import { ClinicIntegrations } from './containers/ClinicIntegrations/ClinicIntegrations'
import { ClinicPatients } from './containers/ClinicPatients/ClinicPatients'
import { ClinicPatientsTable } from './containers/ClinicPatients/tables/ClinicPatientsTable/ClinicPatientsTable'
import { TableType } from './containers/ClinicPatients/tables/interfaces'
import { DeviceSettings } from './containers/DeviceSettings/DeviceSettings'
import { GetUploader } from './containers/DownloadUploader/GetUploader'
import { NotFound } from './containers/ErrorComponent/NotFound'
import { Events } from './containers/Events/Events'
import { Login } from './containers/Login/Login'
import { More } from './containers/More/More'
import { OpenUploaderPage } from './containers/OpenUploader/OpenUploaderPage'
import { Overview } from './containers/Overview/Overview'
import { PatientDetails } from './containers/PatientDetails/PatientDetails'
import { PatientInfo } from './containers/PatientInfo/PatientInfo'
import { Integrations } from './containers/PatientIntegrations/PatientIntegrations'
import { PatientSearch } from './containers/PatientSearch/PatientSearch'
import { PenDosingPage } from './containers/PenDosing/PenDosing'
import { PenTitrations } from './containers/PenTitrations/PenTitrations'
import { StackedDays } from './containers/StackedDaily/StackedDaily'
import { TicketPage } from './containers/Tickets/TicketPage/TicketPage'
import { Tickets } from './containers/Tickets/Tickets'
import { Trends } from './containers/Trends/Trends'
import ClinicViewsContext, {
  useClinicViewsContext
} from './core/contexts/clinic-view-context'
import SessionContext, {
  useSessionContext
} from './core/contexts/session-context'
import SiteSettingsContext from './core/contexts/site-settings-context'
import dayjs from './core/dayjs/dayjs'
import { getDayjsLanguageString } from './core/dayjs/getDayjsLanguageString'
import { ClinicViewDisplays } from './core/entities/clinic.entity'
import { Capabilities } from './core/entities/siteSettings.entity'
import { UserType } from './core/entities/user.entity'
import i18n from './core/i18n'
import { PrivateRoute } from './core/keycloak/PrivateRoute'
import { useSession } from './core/session/use-session'
import { restructureClinicPageArray } from './helpers/restructureClinicPageArray'
import { useCapabilities } from './hooks/use-capabilities'
import { useSiteSettings } from './hooks/use-site-settings'
dayjs.locale(getDayjsLanguageString(i18n.language))

const modalRoot = document.createElement('div')
modalRoot.setAttribute('id', 'modal-root')
document.body.appendChild(modalRoot)

Modal.setAppElement(modalRoot)

function App() {
  const session = useSession()
  const clinicPages = restructureClinicPageArray(session.clinic?.pages)

  const siteSettings = useSiteSettings()
  const { open, handleStillHere } = useIdleDetection(session)

  useEffect(() => {
    if (siteSettings.siteSettings?.deploymentName) {
      document.title = siteSettings.siteSettings?.deploymentName
    }
  }, [siteSettings.siteSettings?.deploymentName])

  return (
    <ErrorBoundary>
      <SessionContext.Provider value={session}>
        <SiteSettingsContext.Provider value={siteSettings}>
          <ClinicViewsContext.Provider value={clinicPages}>
            {session.isAuthenticated && (
              <ModalComponent.Modal isOpen={open}>
                <IdleDetector
                  stayActive={handleStillHere}
                  logout={session.logout}
                ></IdleDetector>
              </ModalComponent.Modal>
            )}
            <MainRoutes />
          </ClinicViewsContext.Provider>
        </SiteSettingsContext.Provider>
      </SessionContext.Provider>
    </ErrorBoundary>
  )
}
interface CustomRoute {
  path?: string
  element: () => ReactNode
  index?: boolean
  show: () => boolean
  children?: CustomRoute[]
}
function MainRoutes() {
  const session = useSessionContext()
  const { isCapabilitySupportedBySite, deviceSettingsOrInfo } =
    useCapabilities()
  const clinicViews = useClinicViewsContext()

  function renderRoutes(routes: CustomRoute[]) {
    return routes
      .filter((route) => route.show() === true)
      .map((route) => (
        <Route
          key={'route' + route.path}
          path={route.path}
          element={route.element()}
          index={route.index}
        >
          {route.children &&
            route.children.length > 0 &&
            renderRoutes(route.children)}
        </Route>
      ))
  }

  const routes: CustomRoute[] = [
    {
      path: '/',
      element: () => <Navigate to="/portal/login" />,
      show: () => true
    },
    { path: '/portal/login', element: () => <Login />, show: () => true },
    {
      path: '/portal',
      element: () => (
        <PrivateRoute>
          <PortalLayout />
        </PrivateRoute>
      ),
      show: () => true,
      children: [
        {
          element: () => <Navigate to={`patients/me/insights`} />,
          index: true,
          show: () =>
            session.user ? session.user.type === UserType.Patient : false
        },
        {
          element: () => <Navigate to="patients/search" />,
          index: true,
          show: () => !session.user || session.user.type !== UserType.Patient
        },
        {
          path: 'clinic',
          element: () => <Clinic />,
          show: () => true,
          children: [
            {
              path: '',
              index: true,
              element: () => (
                <Navigate
                  to={`${
                    clinicViews.PenTitrations.show
                      ? 'titration_overview'
                      : 'patients'
                  }`}
                />
              ),
              show: () => true
            },
            {
              path: 'titration_overview',
              element: () => <PenTitrations />,
              show: () => clinicViews.PenTitrations.show
            },
            {
              path: 'patients',
              element: () => <ClinicPatients />,
              show: () => clinicViews.Patients.show,
              children: [
                {
                  path: '',
                  index: true,
                  element: () => <Navigate to="all-patients" />,
                  show: () => true
                },
                {
                  path: 'all-patients',
                  element: () => (
                    <ClinicPatientsTable type={TableType.patient} />
                  ),
                  show: () =>
                    clinicViews.Patients.subViews.includes(
                      ClinicViewDisplays.AllPatient
                    )
                },
                {
                  path: 'population-management',
                  element: () => (
                    <ClinicPatientsTable type={TableType.population} />
                  ),
                  show: () =>
                    clinicViews.Patients.subViews.includes(
                      ClinicViewDisplays.Population
                    )
                },
                {
                  path: 'population-management/:populationid',
                  element: () => (
                    <ClinicPatientsTable type={TableType.patient} />
                  ),
                  show: () =>
                    clinicViews.Patients.subViews.includes(
                      ClinicViewDisplays.Population
                    )
                }
              ]
            },
            {
              path: 'integrations',
              element: () => <ClinicIntegrations />,
              show: () => clinicViews.Integrations.show,
              children: [
                {
                  path: '',
                  index: true,
                  element: () => <Navigate to="patient-integration-warnings" />,
                  show: () => true
                },
                {
                  path: 'consultations',
                  element: () => <Consultations />,
                  show: () =>
                    clinicViews.Integrations.subViews.includes(
                      ClinicViewDisplays.LibreViewConsultations
                    )
                },
                {
                  path: 'patient-integration-warnings',
                  element: () => (
                    <ProviderPatients
                      tableType={
                        ProviderPatientsTableType.patientIntegrationsWarnings
                      }
                    />
                  ),
                  show: () =>
                    clinicViews.Integrations.subViews.includes(
                      ClinicViewDisplays.IntegrationTODO
                    )
                },

                {
                  path: 'patient-mapping',
                  element: () => (
                    <ProviderPatients
                      tableType={ProviderPatientsTableType.patientMapping}
                    />
                  ),
                  show: () =>
                    clinicViews.Integrations.subViews.includes(
                      ClinicViewDisplays.ProviderPatients
                    )
                },

                {
                  path: 'clinic-integrations',
                  element: () => <ClinicIntegrationsTable />,
                  show: () =>
                    clinicViews.Integrations.subViews.includes(
                      ClinicViewDisplays.ProviderIntegrations
                    )
                },
                {
                  path: 'clinic-integrations/:providerId',
                  element: () => (
                    <ProviderPatients
                      tableType={ProviderPatientsTableType.individual}
                    />
                  ),
                  show: () =>
                    clinicViews.Integrations.subViews.includes(
                      ClinicViewDisplays.ProviderPatients
                    )
                }
              ]
            },
            {
              path: 'uploadactivity',
              element: () => <UploadActivity />,
              show: () => clinicViews.UploadActivity.show
            },
            {
              path: 'auditlog',
              element: () => <AuditLog />,
              show: () => clinicViews.AuditLog.show
            },
            {
              path: 'tickets',
              element: () => <Tickets />,
              show: () => clinicViews.Tickets.show
            },
            {
              path: 'tickets/:ticketId',
              element: () => <TicketPage />,
              show: () => clinicViews.Tickets.show
            }
          ]
        },
        {
          path: 'patients/search',
          element: () => <PatientSearch />,
          show: () => true
        },
        {
          path: 'patients/:patientId',
          element: () => <PatientDetails />,
          show: () => true,
          children: [
            {
              path: 'insights',
              element: () => <Trends />,
              show: () => true
            },
            {
              path: 'overview',
              element: () => <Overview />,
              show: () => true
            },
            {
              path: 'daily',
              element: () => <StackedDays />,
              show: () => true
            },
            {
              path: 'bg_log',
              element: () => <BgLog />,
              show: () => true
            },
            {
              path: deviceSettingsOrInfo,
              element: () => <DeviceSettings />,
              show: () => true
            },
            {
              path: 'insulin_titration',
              element: () => <PenDosingPage></PenDosingPage>,
              show: () => true
            },
            {
              path: 'nocturnal_predictions',
              element: () => <Events />,
              show: () => true
            },
            {
              path: 'more',
              element: () => <More />,
              show: () => true,
              children: [
                {
                  path: '',
                  index: true,
                  element: () => <Navigate to="integrations" />,
                  show: () => true
                },
                {
                  path: 'integrations',
                  element: () => <Integrations />,
                  show: () => true
                },
                {
                  path: 'data-upload',
                  element: () => <UploadData />,
                  show: () => true
                },
                {
                  path: 'data-export',
                  element: () => <ExportDataComponent />,
                  show: () => true
                },
                {
                  path: 'history',
                  element: () => <PatientUploads />,
                  show: () => true
                },
                {
                  path: 'info',
                  element: () => <PatientInfo />,
                  show: () => true
                }
              ]
            }
          ]
        },
        {
          path: 'uploader',
          element: () => <GetUploader />,
          show: () => isCapabilitySupportedBySite(Capabilities.uploaderInPortal)
        }
      ]
    },
    {
      path: '/portal/uploader/open',
      element: () => (
        <PrivateRoute>
          <OpenUploaderPage />
        </PrivateRoute>
      ),
      show: () => isCapabilitySupportedBySite(Capabilities.uploaderInPortal)
    },
    {
      path: '*',
      element: () => (
        <PrivateRoute>
          <PortalLayout />
        </PrivateRoute>
      ),
      show: () => true,
      children: [
        {
          path: '*',
          element: () => <NotFound />,
          show: () => true
        }
      ]
    }
  ]
  return <Routes>{renderRoutes(routes)}</Routes>
}

export default App
