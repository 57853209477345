import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortEnum } from '../../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { getAllPatients } from '../../../core/api/get-all-patients'
import { PatientSearchResponse, PatientSort } from '../tables/interfaces'

export const usePatientsHook = (
  populationId: string | undefined,
  sorting: SortEnum,
  searchString: string,
  refreshPatients: boolean,
  shouldGetPatients: boolean,
  size: number
) => {
  const [patients, setPatients] = useState<PatientSearchResponse | null>(null)
  const [patientsError, setPatientsError] = useState<Error | null>(null)
  const { t } = useTranslation()
  useEffect(() => {
    setPatients(null)
    setPatientsError(null)
    if (!shouldGetPatients) return
    const handleGetPatients = async () => {
      try {
        const allPopulations = await getAllPatients({
          selection: {
            searchString,
            order: sorting,
            sortBy: PatientSort.Mrn,
            populationIds: populationId ? [populationId] : []
          },
          size
        })
        setPatients(allPopulations)
      } catch (error) {
        setPatientsError(new Error('Error getting patients'))
      }
    }
    handleGetPatients()
  }, [
    populationId,
    t,
    sorting,
    searchString,
    refreshPatients,
    shouldGetPatients,
    size
  ])
  return {
    patients,
    patientsError
  }
}
