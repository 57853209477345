import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  Header,
  HeaderButton,
  HeaderButtons,
  HeaderTitle,
  InfoContainer,
  InfoIcon,
  InfoWrapper,
  MainBodyText,
  PrimaryHeaderButton
} from '../../components/Header/NameComponent/ChoosePatientModal/ChoosePatientModal'
import {
  RadioButton,
  WardWrapper
} from '../../components/Header/NameComponent/ChoosePatientModal/WardComponent/WardComponent'
import { ModalComponent } from '../../components/Modal/Modal'
import { Localization } from '../../core/entities/siteSettings.entity'

const Wrapper = styled.div`
  display: flex;
  width: 46.625rem;
  padding: 1rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 28px;
  background-color: var(--white-color);
  box-shadow: 0rem 0.25rem 1rem 0rem rgba(51, 51, 51, 0.16);
  background-color: var(--white-color);
  gap: 0.5rem;
`

const Row = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`
const Text = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
`

interface LanguageSelectorModalProps {
  closeFun: () => void
  changeLanguage: (value: string) => void
  localization: Localization | undefined
  currentLanguage: string
}

export const LanguageSelectorModal = ({
  closeFun,
  changeLanguage,
  localization,
  currentLanguage
}: LanguageSelectorModalProps) => {
  const [tempSelected, setTempSelected] = useState<string>(currentLanguage)
  const { t } = useTranslation()

  const LanguageOption = ({ language }: { language: string }) => {
    const isActive = tempSelected === language
    return (
      <WardWrapper
        onClick={() => setTempSelected(language)}
        data-testid={'LanguageOption' + language}
      >
        <Row>
          <RadioButton $isActive={isActive}></RadioButton>
          <Text>
            {t(`language.${language}`)}
            {!(currentLanguage === language) &&
              ` (${t(`language.${language}`, { lng: language })})`}
          </Text>
        </Row>
      </WardWrapper>
    )
  }

  return (
    <ModalComponent
      closeFun={closeFun}
      closetestid="closeLanguageSelectorModal"
    >
      <Wrapper data-testid={'currentLanguage' + currentLanguage}>
        <Header>
          <HeaderTitle>{t('Select language')}</HeaderTitle>
          <HeaderButtons>
            <HeaderButton onClick={closeFun}>{t('Cancel')}</HeaderButton>
            <PrimaryHeaderButton
              data-testid="applyLanguage"
              disabled={currentLanguage === tempSelected}
              onClick={() => changeLanguage(tempSelected)}
            >
              {t('Apply Changes')}
            </PrimaryHeaderButton>
          </HeaderButtons>
        </Header>
        <InfoContainer>
          <InfoWrapper>
            <InfoIcon icon={faInfoCircle}></InfoIcon>
            <MainBodyText>
              {t('localizationInfo', {
                country: t(`localization.${localization?.localization}`)
              })}
            </MainBodyText>
          </InfoWrapper>
        </InfoContainer>
        {localization ? (
          localization.languages.map((language) => (
            <LanguageOption language={language} key={language} />
          ))
        ) : (
          <Text>
            {t(
              'Something went wrong when fetching the available languages for this system.'
            )}
          </Text>
        )}
      </Wrapper>
    </ModalComponent>
  )
}
