import {
  faAddressCard,
  faArrowLeft,
  faCircleInfo,
  faLink,
  faScrewdriverWrench
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Spinner, spinnerSize } from '../../../components/Spinner/Spinner'
import dayjs from '../../../core/dayjs/dayjs'
import { useTicket } from '../../../hooks/useTicket'
import { NotResponding } from '../../ErrorComponent/NotResponding'
import { ReportingUserBadge } from '../TicketTable/ReportingUserBadge/ReportingUserBadge'
import { TicketStatusBadge } from '../TicketTable/TicketStatusBadge/TicketStatusBadge'
import { TicketTypeBadge } from '../TicketTable/TicketTypeBadge/TicketTypeBadge'
import { TicketBadgeSize, TicketStatus } from '../constants/constants'
import { ArchivedBadge } from './ArchivedBadge/ArchivedBadge'
import { DownloadTicketAttachments } from './DownloadTicketAttachments/DownloadTicketAttachments'

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
const Wrapper = styled.div`
  display: flex;
  width: 45rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
`
const Header = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`
const ReturnWrapper = styled(Link)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0rem;
  height: 1.5rem;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  color: var(--brand-primary-color);
  &:hover {
    color: var(--brand-darker-color);
  }
`

const ReturnArrow = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 1rem;
  line-height: 1rem;
`

const ReturnText = styled.span`
  font-family: inter;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: inherit;
`

const TitleWrapper = styled.div`
  display: flex;
  height: 2.25rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const TitleLeftStack = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
`

const SubTitle = styled(Title)`
  color: var(--text-lighter);
`

const ClipboardIcon = styled(FontAwesomeIcon)`
  color: var(--text-lighter);
  font-size: 1.25rem;
  line-height: 2.25rem;
  cursor: pointer;
  &:hover {
    color: var(--brand-primary-color);
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`
const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;
`

const SectionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
`
const SectionIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: var(--text-primary);
`
const SectionTitle = styled.span`
  font-family: inter;
  color: var(--text-primary);
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: 700;
`
const SectionBody = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
  border-radius: 1.5rem;
  border: 0.0625rem solid var(--text-lightest);
`
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;
  flex: 33%;
`
const Key = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--text-lighter);
  line-height: 1.5rem;
`

const Value = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 1.5rem;
`

const DeepLink = styled.a`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 1.5rem;
  text-decoration: none;
  color: var(--brand-primary-color);
  &:visited {
    color: var(--brand-primary-color);
  }
`
const IssueDescription = styled.div`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  padding: 1.5rem;
  background-color: var(--element-bg);
  border-radius: 0.5rem;
  width: 100%;
  white-space: pre-line;
  box-sizing: border-box;
  display: inline-block;
  word-break: break-all;
  overflow-wrap: break-word;
`
const MailButton = styled.a`
  font-family: inter;
  font-weight: 600;
  font-size: 1rem;
  color: var(--brand-primary-color);
  line-height: 1.5rem;
  cursor: pointer;
  text-decoration: none;
`
export const TicketPage = () => {
  const { t } = useTranslation()
  const { ticketId } = useParams()
  const { ticket, ticketError, ticketLoading } = useTicket(ticketId)
  const deepLink = ticket ? atob(ticket.applicationInfo.context.deepLink) : '-'

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Container>
      <Wrapper>
        {ticket ? (
          <>
            <Header data-testid="ticket_page_header">
              <ReturnWrapper to={'/portal/clinic/tickets'}>
                <ReturnArrow icon={faArrowLeft}></ReturnArrow>
                <ReturnText>{t('ticket_page_return_text')}</ReturnText>
              </ReturnWrapper>
              <TitleWrapper>
                <TitleLeftStack>
                  <Title>{t('ticket_page_title')}</Title>
                  <SubTitle data-testid="ticket_id">{ticket.id}</SubTitle>
                </TitleLeftStack>
                <ClipboardIcon
                  icon={faLink}
                  onClick={handleCopyToClipboard}
                ></ClipboardIcon>
              </TitleWrapper>
            </Header>
            <SectionBody>
              <Row>
                <Column>
                  <Key>{t('ticket_view_type_key')}</Key>
                  <TicketTypeBadge
                    size={TicketBadgeSize.large}
                    type={ticket.type}
                    withBackground={false}
                  />
                </Column>
                <Column>
                  <Key>{t('ticket_view_status_key')}</Key>
                  <TicketStatusBadge
                    status={ticket.status}
                    withBackground={false}
                    size={TicketBadgeSize.large}
                  />
                </Column>
                <Column>
                  <Key>{t('ticket_view_jira_key')}</Key>
                  <Value>{t('jira_link_not_linked')}</Value>
                </Column>
              </Row>
              <Row>
                <Column>
                  <Key>{t('ticket_view_creation_time_key')}</Key>
                  <Value>
                    {dayjs(ticket.created).format('DD/MM/YYYY HH:mm')}
                  </Value>
                </Column>
                <Column>
                  <Key>{t('ticket_view_age_of_ticket_key')}</Key>
                  <Value>
                    {ticket.age} {t('days')}
                  </Value>
                </Column>
                <Column>
                  <Key>{t('ticket_view_expires_in_key')}</Key>
                  <Value>
                    {ticket.daysUntilArchived} {t('days')}
                  </Value>
                </Column>
              </Row>
              {ticket.attachmentAmount && (
                <Row>
                  <Column>
                    <Key>{t('ticket_view_attachments_key')}</Key>
                    {ticket.status === TicketStatus.Archived ? (
                      <ArchivedBadge />
                    ) : (
                      <Value>{ticket.attachmentAmount}</Value>
                    )}
                  </Column>
                  {ticket.status !== TicketStatus.Archived && (
                    <DownloadTicketAttachments ticketId={ticket.id} />
                  )}
                </Row>
              )}
              <Row>
                <Column>
                  <Key>{t('ticket_view_description_of_issue_key')}</Key>
                  <IssueDescription>
                    {ticket.status === TicketStatus.Archived ? (
                      <ArchivedBadge />
                    ) : (
                      ticket.description
                    )}
                  </IssueDescription>
                </Column>
              </Row>
            </SectionBody>

            <Section>
              <SectionHeader>
                <SectionIconWrapper>
                  <SectionIcon icon={faCircleInfo}></SectionIcon>
                </SectionIconWrapper>
                <SectionTitle>
                  {t('ticket_view_reporting_context_title')}
                </SectionTitle>
              </SectionHeader>
              <SectionBody>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_section_key')}</Key>
                    <Value>{ticket.applicationInfo.context.section}</Value>
                  </Column>
                  <Column>
                    <Key>{t('ticket_view_patient_id_key')}</Key>
                    {ticket.status === TicketStatus.Archived ? (
                      <ArchivedBadge />
                    ) : (
                      <Value>
                        {ticket.applicationInfo.context.patientId ?? '-'}
                      </Value>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_deeplink_key')}</Key>
                    <DeepLink href={deepLink} target="_blank">
                      {deepLink}
                    </DeepLink>
                  </Column>
                </Row>
              </SectionBody>
            </Section>
            <Section>
              <SectionHeader>
                <SectionIconWrapper>
                  <SectionIcon icon={faAddressCard}></SectionIcon>
                </SectionIconWrapper>
                <SectionTitle>
                  {t('ticket_view_reporting_user_title')}
                </SectionTitle>
              </SectionHeader>
              <SectionBody>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_user_name_key')}</Key>
                    {ticket.status === TicketStatus.Archived ? (
                      <ArchivedBadge />
                    ) : (
                      <Value>{ticket.user?.username ?? '-'}</Value>
                    )}
                  </Column>
                  <Column>
                    <Key>{t('ticket_view_user_type_key')}</Key>
                    <ReportingUserBadge
                      type={ticket.user?.userType ?? null}
                      size={TicketBadgeSize.large}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_authentication_method_key')}</Key>
                    {ticket.user?.authenticationMethod ? (
                      <Value>{ticket.user.authenticationMethod}</Value>
                    ) : (
                      <Value>-</Value>
                    )}
                  </Column>
                  <Column>
                    <Key>{t('ticket_view_user_id_key')}</Key>
                    {ticket.status === TicketStatus.Archived ? (
                      <ArchivedBadge />
                    ) : (
                      <Value>{ticket.user?.userId ?? '-'}</Value>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_language_key')}</Key>
                    <Value>{ticket.applicationInfo.context.language}</Value>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_email_key')}</Key>
                    {ticket.status === TicketStatus.Archived ? (
                      <ArchivedBadge />
                    ) : ticket.contactInfo.email ? (
                      <MailButton href={`mailto:${ticket.contactInfo.email}`}>
                        {ticket.contactInfo.email}
                      </MailButton>
                    ) : (
                      <Value>-</Value>
                    )}
                  </Column>
                  <Column>
                    <Key>{t('ticket_view_phone_key')}</Key>
                    {ticket.status === TicketStatus.Archived ? (
                      <ArchivedBadge />
                    ) : (
                      <Value>{ticket.contactInfo.phone ?? '-'}</Value>
                    )}
                  </Column>
                </Row>
              </SectionBody>
            </Section>
            <Section>
              <SectionHeader>
                <SectionIconWrapper>
                  <SectionIcon icon={faScrewdriverWrench}></SectionIcon>
                </SectionIconWrapper>
                <SectionTitle>
                  {t('ticket_view_application_info_title')}
                </SectionTitle>
              </SectionHeader>
              <SectionBody>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_product_key')}</Key>
                    <Value>{ticket.applicationInfo.product}</Value>
                  </Column>
                  <Column>
                    <Key>{t('ticket_view_release_version_key')}</Key>
                    <Value>
                      {ticket.applicationInfo.context.releaseVersion}
                    </Value>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_deployment_name_key')}</Key>
                    <Value>{ticket.applicationInfo.deploymentName}</Value>
                  </Column>
                  <Column>
                    <Key>{t('ticket_view_product_udi_key')}</Key>
                    <Value>{ticket.applicationInfo.productUDI}</Value>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Key>{t('ticket_view_browser_type_key')}</Key>
                    <Value>{ticket.applicationInfo.context.browser.type}</Value>
                  </Column>
                  <Column>
                    <Key>{t('ticket_view_browser_version_key')}</Key>
                    <Value>
                      {ticket.applicationInfo.context.browser.version}
                    </Value>
                  </Column>
                </Row>
              </SectionBody>
            </Section>
          </>
        ) : ticketLoading ? (
          <Spinner spinnersize={spinnerSize.small}></Spinner>
        ) : (
          ticketError && <NotResponding errorMessage={ticketError} />
        )}
      </Wrapper>
    </Container>
  )
}
function SnackBar(arg0: { type: any; message: string }) {
  throw new Error('Function not implemented.')
}
