import { SortingState } from '@tanstack/react-table'
import { Filters } from '../../../components/UploadActivity/constants'
import { UserType } from '../../../core/entities/user.entity'

export enum TicketStatus {
  Open = 'Open',
  Archived = 'Archived'
}
export enum TicketType {
  Support = 'Support',
  Feedback = 'Feedback'
}
export interface Browser {
  type: string
  version: string
}

export interface UserContext {
  username: string
  userType: UserType
  authenticationMethod: string | null
  userId: string
}
export interface ContactInfo {
  email: string | null
  phone: string | null
}

export enum TicketProduct {
  Portal = 'Portal',
  Uploader = 'Uploader'
}

export interface Attachment {
  name: string
  data: Blob
}
export interface PortalContext {
  section: string
  patientId: string | null
  deepLink: string
  browser: Browser
  backendVersion: string
  portalVersion: string
  releaseVersion: string
  language: string
  os: {
    name: string
  }
  type: TicketProduct
}
export interface UploaderContext {
  section: string
  patientId: string | null
  deepLink: string
  browser: Browser
  backendVersion: string
  releaseVersion: string
  language: string
  os: {
    name: string
  }
  type: TicketProduct
}
export interface TicketDTO {
  id: string
  type: TicketType
  status: TicketStatus
  created: string
  updated: string
  age: number
  daysUntilArchived: number
  attachmentAmount: number | null
  description: string
  jiraInfo: { link: string | null }
  applicationInfo: {
    product: TicketProduct
    context: PortalContext | UploaderContext
    deploymentName: string
    productUDI: string
  }
  user: UserContext | null
  contactInfo: ContactInfo
}
export enum TicketReportingTime {
  within2days = 'within2days',
  olderthan2days = 'olderthan2days',
  olderthan7days = 'olderthan7days',
  olderthan14days = 'olderthan14days',
  olderthan30days = 'olderthan30days',
  olderthan90days = 'olderthan90days'
}
export enum TicketReportingUser {
  Patient = 'Patient',
  HCP = 'HCP',
  hcpadmin = 'HCPAdmin',
  notauthenticated = 'NotAuthenticated'
}
export enum TicketSystemContext {
  Portal = 'Portal',
  ClinicUploader = 'ClinicUploader',
  HomeUploader = 'HomeUploader'
}
export enum TicketSoftwareVersion {
  latest = 'latest',
  earlier = 'earlier'
}
export enum TicketFiltersEnum {
  status = 'status',
  types = 'types',
  reportingTime = 'reportingTime',
  reportingUser = 'reportingUser',
  systemContext = 'systemContext',
  softwareVersion = 'softwareVersion'
}

export type SupportFeedbackParams = Filters & {
  status?: Array<TicketStatus>
  types?: Array<TicketType>
  reportingTime?: TicketReportingTime
  reportingUser?: Array<TicketReportingUser>
  systemContext?: Array<TicketSystemContext>
  softwareVersion?: TicketSoftwareVersion
  sorting: SortingState
}
export enum TicketBadgeSize {
  small,
  large
}
