import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { TicketDTO } from '../containers/Tickets/constants/constants'
import { getTicket } from '../core/api/getTicket'

export const useTicket = (ticketId: string | undefined) => {
  const [ticket, setTicket] = useState<null | TicketDTO>(null)
  const [ticketError, setTicketError] = useState<string | null>(null)
  const [ticketLoading, setTicketLoading] = useState(false)
  useEffect(() => {
    if (!ticketId) return

    const getTicketById = async () => {
      try {
        setTicketLoading(true)
        const ticket = await getTicket(ticketId)
        setTicket(ticket)
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response && error.response.status) {
            setTicketError(error.response.status.toString())
          } else {
            setTicketError(error.message)
          }
        }
      } finally {
        setTicketLoading(false)
      }
    }

    getTicketById()
  }, [ticketId])
  return { ticket, ticketError, ticketLoading }
}
