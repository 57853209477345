import { IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export enum FilterFunctionButtonTheme {
  default,
  blue
}

const getThemeColors = (theme?: FilterFunctionButtonTheme) => {
  switch (theme) {
    case FilterFunctionButtonTheme.default:
      return {
        backgroundColor: 'var(--element-bg)',
        color: 'var(--text-primary)',
        hoverBackgroundColor: 'var(--element-bg-dark)',
        hoverColor: 'var(--brand-primary-color)'
      }
    case FilterFunctionButtonTheme.blue:
      return {
        backgroundColor: 'var(--blue)',
        color: 'var(--white-color)',
        hoverBackgroundColor: 'var(--blue-darker)',
        hoverColor: 'var(--white-color)'
      }
    default:
      return {
        backgroundColor: 'var(--element-bg)',
        color: 'var(--text-primary)',
        hoverBackgroundColor: 'var(--element-bg-dark)',
        hoverColor: 'var(--brand-primary-color)'
      }
  }
}

export const Wrapper = styled.div<{
  $theme?: FilterFunctionButtonTheme
}>`
  padding: 0.75rem;
  display: inline-flex;
  gap: 0.375rem;
  height: 2.5rem;
  box-sizing: border-box;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${(props) => getThemeColors(props.$theme).backgroundColor};
  color: ${(props) => getThemeColors(props.$theme).color};
  cursor: pointer;
  &:hover {
    color: ${(props) => getThemeColors(props.$theme).hoverColor};
    background-color: ${(props) =>
      getThemeColors(props.$theme).hoverBackgroundColor};
  }
`

export const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: inherit;
`
export const Text = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
`

const FilterCountText = styled.span`
  width: 1.125rem;
  height: 1.125rem;
  background-color: var(--blue);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--text-primary);
`

interface FunctionButtonProps {
  icon?: IconDefinition
  text?: string
  filtersCount?: number
  handleClick: () => void
  theme?: FilterFunctionButtonTheme
  datatestid?: string
}
export const FilterFunctionButton = ({
  icon,
  text,
  filtersCount,
  handleClick,
  theme,
  datatestid
}: FunctionButtonProps) => {
  return (
    <Wrapper onClick={handleClick} $theme={theme} data-testid={datatestid}>
      {icon && <Icon icon={icon}></Icon>}
      {text && <Text>{text}</Text>}
      {filtersCount ? <FilterCountText>{filtersCount}</FilterCountText> : null}
    </Wrapper>
  )
}
