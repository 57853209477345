import { Filters } from '../../../UploadActivity/constants'

export enum ProviderManagementTypes {
  patientmanaged = 'patientmanaged',
  clinicmanaged = 'clinicmanaged'
}

export enum ProviderPatientProviderType {
  MedtronicCarelinkWebCMIProvider = 'MedtronicCarelinkWebCMIProvider',
  AbbottLibreViewWebCMIProvider = 'AbbottLibreViewWebCMIProvider',
  DexcomAPICMIProvider = 'DexcomAPICMIProvider',
  FitbitAPICMIProvider = 'FitbitAPICMIProvider',
  YpsomedAPICMIProvider = 'YpsomedAPICMIProvider'
}

export enum ClinicManagedProviderPatientProviderType {
  MedtronicCarelinkWebCMIProvider = 'MedtronicCarelinkWebCMIProvider',
  AbbottLibreViewWebCMIProvider = 'AbbottLibreViewWebCMIProvider'
}
export enum PatientManagedProviderPatientProviderType {
  DexcomAPICMIProvider = 'DexcomAPICMIProvider',
  FitbitAPICMIProvider = 'FitbitAPICMIProvider',
  YpsomedAPICMIProvider = 'YpsomedAPICMIProvider'
}

export enum PMIStateValues {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  NOT_SETUP = 'NOT_SETUP'
}

export enum FetchStateEnum {
  Ready = 'Ready',
  NotReady = 'NotReady',
  UpToDate = 'UpToDate'
}
export interface FetchState {
  rateLimitReset: { hours: number; minutes: number } | null
  fetchState: FetchStateEnum
}

export interface ProviderPatient {
  birthday: string | null
  newestLocalData: string | null
  newestProviderData: string | null
  assignedPatientId: string | null
  deleted: boolean
  suspended: boolean
  ignored: boolean
  mrn: string | null
  localPatientName: string | null
  localPatientMrn: string | null
  providerType: ProviderPatientProviderType
  providerName: string
  email: string | null
  id: string
  name: string
  providerId: string
  providerPatientId: string
  managementType: ProviderManagementTypes
  pmiStateValue?: PMIStateValues
  latestFetchTime: string | null
  fetchState: FetchState | null
}

export interface ProviderPatientsObject {
  providerPatients: Array<ProviderPatient>
}

export enum ClinicManagedCloudPatientStatus {
  NoLink = 'NoLink',
  Linked = 'Linked',
  Ignored = 'Ignored'
}

export type ProviderPatientsFilterTypes = Filters & {
  patientMappingState?: ClinicManagedCloudPatientStatus[]
  patientIntegrationState?: PMIStateValues[]
  providerTypes?: ProviderPatientProviderType[]
  providerId?: string
}

export enum SortEnum {
  Ascending = 'Ascending',
  Descending = 'Descending'
}
export enum Filter {
  patientMappingState = 'patientMappingState',
  providerTypes = 'providerTypes',
  patientIntegrationState = 'patientIntegrationState'
}
export enum ProviderPatientsTableType {
  patientIntegrationsWarnings,
  patientMapping,
  individual
}
