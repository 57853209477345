import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { formatCPR } from '../../../../../helpers/helpers'
import { ProviderPatient } from '../ProviderPatientsInterfaces'

const Text = styled.span<{ $patientExists: boolean }>`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2.25rem;
  &:hover {
    color: ${(props) =>
      props.$patientExists
        ? 'var(--brand-primary-color)'
        : 'var(--text-primary)'};
    cursor: ${(props) => (props.$patientExists ? 'pointer' : 'auto')};
  }
`
export const MRNField = ({ row }: { row: ProviderPatient }) => {
  const navigate = useNavigate()
  const goToPatient = () => {
    navigate(`/portal/patients/${row.assignedPatientId}/insights`)
  }
  return (
    <Text
      onClick={() => (row.assignedPatientId ? goToPatient() : null)}
      $patientExists={row.assignedPatientId !== null}
    >
      {formatCPR(row.localPatientMrn ?? '')}
    </Text>
  )
}
