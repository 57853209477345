import { faCode } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenuItem } from '../../../../../ContextMenu/ContextMenu'
import { Modal } from '../../../../../Modal/Modal'
import { ProviderPatient } from '../../ProviderPatientsInterfaces'
import { GetLatestApiResponseModal } from './GetLatestApiResponseModal/GetLatestApiResponseModal'

export const GetLatestAPIResponse = ({
  providerPatient
}: {
  providerPatient: ProviderPatient
}) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={showModal}>
        <GetLatestApiResponseModal
          closeFun={() => setShowModal(false)}
          providerPatientId={providerPatient.id}
        />
      </Modal>
      <ContextMenuItem
        onClick={() => setShowModal(true)}
        icon={faCode}
        data-testid="ProviderPatientsOptionsLatestAPIResponse"
      >
        {t('provider_patients_table_options_latest_api_response')}
      </ContextMenuItem>
    </>
  )
}
