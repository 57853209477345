import axios, { AxiosInstance } from 'axios'

export class AxiosRequest {
  private static accessToken: string

  static setAccessToken(token: string) {
    this.accessToken = token
  }

  static create(config: object = {}): AxiosInstance {
    const _config = {
      headers: {},
      ...config
    }
    const instance = axios.create({
      ..._config,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        ..._config.headers
      }
    })

    instance.interceptors.request.use((config) => {
      if (config.params) {
        // Clean up empty query parameter values
        config.params = Object.fromEntries(
          Object.entries(config.params).filter(
            ([_, paramValue]) => paramValue != null && paramValue !== ''
          )
        )
      }
      return config
    })

    instance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          window.location.replace('/portal')
        }
        return Promise.reject(error)
      }
    )

    return instance
  }
}
