import { faRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterFunctionButton } from '../FilterFunctionButton/FilterFunctionButton'
import {
  FilterSection,
  FiltersWrapper,
  TopRight
} from '../FilterStyledElements/FilterStyledElements'
import { DataFilterType } from './DataFilter'
import { FiltersManagerContext } from './FiltersManagerContext'

export interface DataFilterProps {
  title: string
  slug: string
  type: DataFilterType
  defaults: string | string[] | undefined
  currentFilters: string | string[] | undefined
  data: string[]
}

export function FiltersManager({
  children
}: Readonly<{ children: React.ReactNode }>) {
  const { t } = useTranslation()
  const [resetAll, setResetAll] = useState(false)

  const resetFilters = () => {
    setResetAll(true)
  }

  return (
    <FiltersManagerContext.Provider value={{ resetAll, setResetAll }}>
      <FiltersWrapper data-testid="filtersManager">
        <FilterSection>{children}</FilterSection>
        <TopRight>
          <FilterFunctionButton
            handleClick={resetFilters}
            icon={faRotateRight}
            text={t('Reset to defaults')}
          />
        </TopRight>
      </FiltersWrapper>
    </FiltersManagerContext.Provider>
  )
}
