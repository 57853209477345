import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`
export const InputFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  position: relative;
`

export const InputField = styled.input`
  width: 100%;
  padding: 1rem;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: inter;
  color: var(--text-primary);
  box-sizing: border-box;
  &:focus,
  active {
    border-color: var(--brand-primary-color);
    box-shadow: 0rem 0rem 0rem 0.375rem var(--brand-primary-16-color);
    outline: none;
  }
`

export const InputFieldLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InputFieldOptionalText = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
`
export const InputFieldLabel = styled.span`
  font-size: 0.875rem;
  font-family: inter;
  font-weight: 600;
  color: var(--text-medium);
`
export const FormButton = styled.button<{ assigned: boolean }>`
  border: ${(props) =>
    props.assigned
      ? '0.0625rem solid var(--text-lightest)'
      : '0.0625rem solid var(--brand-primary-color)'};
  width: 100%;
  padding: 1rem;
  background-color: ${(props) =>
    props.assigned ? 'var(--element-bg)' : 'var(--brand-primary-color)'};
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: inter;
  color: ${(props) =>
    props.assigned ? 'var(--text-primary)' : 'var(--white-color)'};
  margin-top: 1.375rem;
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: default;
  
`
