import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  SubmitHandler,
  UseFormRegister,
  UseFormSetValue,
  useForm
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { createPatient } from '../../core/api/create-patient'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { getValidation } from '../../helpers/MRNValidation/getValidation'
import { MRNTypes } from '../../helpers/MRNValidation/interfaces'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import {
  InputField,
  InputFieldLabel,
  InputFieldWrapper,
  InputWrapper
} from '../FormStyles/FormStyles'
import { ModalComponent } from '../Modal/Modal'

interface CreatePatientModalProps {
  mrn?: string | null
  name?: string | null
  closeModal: () => void
  refresh: () => void
}

const Wrapper = styled.div`
  background-color: var(--white-color);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
  width: 31.25rem;
  gap: 2rem;
`

const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const SubmitButton = styled.button`
  border: 0.0625rem solid var(--brand-primary-color);
  width: 100%;
  padding: 1rem;
  background-color:var(--brand-primary-color);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  font-family: inter;
  color: var(--white-color);
  text-transform: uppercase;
  cursor: pointer;
  &:disabled {
    opacity: 0.16;
    cursor: default;
    border: 0.0625rem solid rgb(0,0,0,0);
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
  width: 100%;
  box-sizing: border-box;
`
const InfoBoxIcon = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  font-weight: 900;
  color: var(--yellow);
`
const InfoBoxText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--yellow-darker);
`
interface FormValues {
  MRN: string
  FullName: string
}
interface InputComponentProps {
  label: 'MRN' | 'FullName'
  register: UseFormRegister<FormValues>
  resetError: Dispatch<SetStateAction<Error | undefined>>
  errorState?: Error | undefined
  setValue: UseFormSetValue<any>
  getValues: (field?: string | string[] | undefined) => any
}
const InfoBoxComponent = () => {
  const { t } = useTranslation()
  return (
    <InfoBox data-testid="createPatientInfoBox">
      <InfoBoxIcon icon={faTriangleExclamation}></InfoBoxIcon>
      <InfoBoxText>
        {t('An error occurred when creating the patient')}
      </InfoBoxText>
    </InfoBox>
  )
}

const InputComponent = ({
  label,
  register,
  resetError,
  errorState,
  setValue,
  getValues
}: InputComponentProps) => {
  const { t } = useTranslation()
  const [currentMRN, setCurrentMRN] = useState('')
  const { siteSettings } = useSiteSettingsContext()

  const validation = getValidation({
    mrntype: siteSettings?.mrnType ?? MRNTypes.dkcpr,
    setPatientError: () => resetError(undefined),
    setValue,
    getValues,
    setCurrentMRN,
    currentMRN
  })
  const getLabel = (label: 'MRN' | 'FullName') => {
    switch (label) {
      case 'MRN':
        return t(`${siteSettings?.mrnType}.mrn-title`)
      case 'FullName':
        return t('Full name')
    }
  }
  const getPlaceHolder = (label: 'MRN' | 'FullName') => {
    switch (label) {
      case 'MRN':
        return t(`${siteSettings?.mrnType}.mrn-enter`)
      case 'FullName':
        return t('Enter full name of patient')
    }
  }

  return (
    <InputWrapper>
      <InputFieldLabel
        style={
          errorState !== undefined ? { color: 'var(--yellow-darker)' } : {}
        }
      >
        {getLabel(label)}
      </InputFieldLabel>
      <InputFieldWrapper>
        <InputField
          data-testid={`createPatientInput${label}`}
          style={
            errorState !== undefined ? { borderColor: 'var(--yellow)' } : {}
          }
          {...register(label, {
            onChange:
              label === 'MRN'
                ? () => validation.changeFunction()
                : () => resetError(undefined),
            required: true,
            pattern:
              label === 'MRN'
                ? {
                    value: validation.pattern,
                    message: t('wrong format')
                  }
                : undefined
          })}
          placeholder={getPlaceHolder(label)}
          autoComplete="off"
        ></InputField>
      </InputFieldWrapper>
    </InputWrapper>
  )
}
export const CreatePatientModal = ({
  mrn = '',
  name = '',
  closeModal,
  refresh
}: CreatePatientModalProps) => {
  const [errorState, setErrorState] = useState<Error | undefined>()
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      MRN: '',
      FullName: ''
    }
  })
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await createPatient({
        mrn: data.MRN.replace(/-/g, ''),
        name: data.FullName
      })
      closeModal()
      refresh()
      SnackBar({
        type: SnackBarType.Success,
        message: t('The patient has been created')
      })
    } catch (err) {
      setErrorState(new Error())
    }
  }

  useEffect(() => {
    setValue('MRN', mrn || '')
    setValue('FullName', name || '')
    trigger('MRN')
    trigger('FullName')
  }, [mrn, name, setValue, trigger])

  return (
    <ModalComponent closeFun={closeModal} closetestid="closeCreatePatientModal">
      <Wrapper
        onClick={function (event) {
          event.stopPropagation()
        }}
        data-testid="createPatientModal"
      >
        <Title>{t('Create new patient')}</Title>
        {errorState !== undefined && <InfoBoxComponent />}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputComponent
            label={'MRN'}
            register={register}
            resetError={setErrorState}
            errorState={errorState}
            setValue={setValue}
            getValues={getValues}
          />
          <InputComponent
            label={'FullName'}
            register={register}
            resetError={setErrorState}
            setValue={setValue}
            getValues={getValues}
          />
          <SubmitButton
            disabled={!isValid}
            type={'submit'}
            data-testid="createPatientButton"
          >
            {t('create patient')}
          </SubmitButton>
        </Form>
      </Wrapper>
    </ModalComponent>
  )
}
