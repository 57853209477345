import { useTranslation } from 'react-i18next'
import { CMIIntegrationState } from '../../../../containers/ClinicIntegrations/ClinicIntegrations'
import {
  GenericBadgeStatus,
  StatusBadge
} from '../../SharedIntegrationComponents/StatusBadge/StatusBadge'

interface ClinicIntegrationStatusBadgeProps {
  state: CMIIntegrationState
}

export const ClinicIntegrationStatusBadge = ({
  state
}: ClinicIntegrationStatusBadgeProps) => {
  const { t } = useTranslation()
  const getClinicIntegrationStatusBadgeConfig = () => {
    switch (state) {
      case CMIIntegrationState.initial:
        return {
          status: GenericBadgeStatus.neutral,
          text: t('cmi_integration_status_initial')
        }
      case CMIIntegrationState.running:
        return {
          status: GenericBadgeStatus.neutral,
          text: t('cmi_integration_status_running')
        }
      case CMIIntegrationState.verificationFailed:
        return {
          status: GenericBadgeStatus.error,
          text: t('cmi_integration_status_verification_failed')
        }
      case CMIIntegrationState.verifying:
        return {
          status: GenericBadgeStatus.neutral,
          text: t('cmi_integration_status_verifying')
        }
      case CMIIntegrationState.idle:
        return {
          status: GenericBadgeStatus.ok,
          text: t('cmi_integration_status_idle')
        }
      case CMIIntegrationState.failed:
        return {
          status: GenericBadgeStatus.error,
          text: t('cmi_integration_status_failed')
        }
      case CMIIntegrationState.failing:
        return {
          status: GenericBadgeStatus.warning,
          text: t('cmi_integration_status_failing')
        }
    }
  }
  const config = getClinicIntegrationStatusBadgeConfig()
  return <StatusBadge text={config.text} status={config.status} />
}
