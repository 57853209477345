import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { SubmitHandler, UseFormRegister, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CMIIntegrationValue } from '../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { deleteProvider } from '../../../../core/api/delete-provider'
import { useSiteSettingsContext } from '../../../../core/contexts/site-settings-context'
import { Capabilities } from '../../../../core/entities/siteSettings.entity'
import { FormButton } from '../../../FormStyles/FormStyles'
import { IntegrationTypeLabel } from '../../../IntegrationTypeLabel/IntegrationTypeLabel'
import { ModalComponent } from '../../../Modal/Modal'
import { ProviderPatientProviderType } from '../ProviderPatients/ProviderPatientsInterfaces'
import { ClinicIntegrationStatusBadge } from '../StatusBadges/ClinicIntegrationStatusBadge'
import {
  getIntegrationType,
  getProviderTypeInfoText,
  handleProviderEdit,
  handleProviderSubmit
} from './ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'
import { SetupButtonComponent } from './SetupButton/SetupButtonComponent'
import { FormPage } from './SetupPage/FormPage/FormPage'
import { SuccessPage } from './SetupPage/SuccessPage/SuccessPage'
export interface FormValues {
  alias: string
  username: string
  password: string
  emailAddress?: string
  emailHost?: string
  emailPassword?: string
  clientId: string
  clientSecret: string
}

interface ProviderIntegrationModelProps {
  handleShowProviderSetup: () => void
  provider: CMIIntegrationValue | null
  refreshProviders: () => void
}

export const Relative = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 4;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 27.25rem;
  padding: 2rem;
  gap: 2rem;
  border-radius: 2rem;
  background-color: var(--white-color);
`
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`
export const Title = styled.span`
  font-size: 1.25rem;
  font-family: inter;
  font-weight: 700;
  color: var(--text-primary);
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1rem;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const DeleteProvider = styled.div`
  font-family inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--destructive);
  text-decoration: underline;
  cursor: pointer;
  white-space:nowrap;
`

const CancelButton = styled(FormButton)`
  background-color: var(--element-bg);
  color: var(--text-primary);
  border: 0.0625rem solid var(--text-lightest);
  &:hover {
    background-color: var(--element-bg-dark);
  }
`
export const BodyText = styled.span`
  font-size: 0.875rem;
  font-family: inter;
  font-weight: 600;
  color: var(--text-medium);
  white-space: pre-line;
`
const SuccesIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  color: var(--green);
`

export const ProviderIntegrationModel = ({
  handleShowProviderSetup,
  provider,
  refreshProviders
}: ProviderIntegrationModelProps) => {
  const { siteSettings } = useSiteSettingsContext()
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      alias: provider ? provider.alias : '',
      username: provider ? provider.username : '',
      password: '',
      emailAddress: provider ? provider.email : '',
      emailHost: provider ? provider.emailHost : '',
      emailPassword: '',
      clientId: provider ? provider.clientId : '',
      clientSecret: provider ? '*******************' : ''
    }
  })

  const { t } = useTranslation()
  const [currentProvider, setCurrentProvider] =
    useState<ProviderPatientProviderType | null>(
      provider ? provider.type : null
    )
  const [formDisabled, setFormDisabled] = useState<boolean>(true)
  const [setupState, setSetupState] = useState(provider ? 3 : 0)

  const goToNext = () => {
    setSetupState(setupState + 1)
  }

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (setupState === 1) {
      await handleProviderSubmit(currentProvider, data)
      goToNext()
    }
    if (setupState === 2) {
      setSetupState(0)
      setCurrentProvider(null)
      refreshProviders()
      handleShowProviderSetup()
    }
    if (setupState === 3) {
      handleEdit()
    }
  }
  const handleDelete = async () => {
    if (provider) {
      try {
        await deleteProvider({ providerId: provider.id })
        handleShowProviderSetup()
        refreshProviders()
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleEdit = () => {
    const editedData = {
      name: getValues().alias,
      username: getValues().username,
      password: getValues().password === '' ? null : getValues().password,
      email: getValues().emailAddress,
      emailHost: getValues().emailHost,
      emailPassword:
        getValues().emailPassword === '' ? null : getValues().emailPassword,
      clientId: getValues().clientId === '' ? null : getValues().clientId
    }
    provider &&
      handleProviderEdit({
        currentProvider,
        data: editedData,
        providerId: provider.id,
        t
      })
    handleShowProviderSetup()
    refreshProviders()
  }

  const handleChange = async () => {
    if (setupState === 3) {
      const {
        alias,
        username,
        password,
        emailAddress,
        emailHost,
        emailPassword
      } = getValues()
      if (
        alias !== provider?.alias ||
        username !== provider?.username ||
        password !== '' ||
        emailAddress !== provider.email ||
        emailHost !== provider.emailHost ||
        emailPassword !== ''
      )
        setFormDisabled(false)
      else {
        setFormDisabled(true)
      }
    }
  }

  const showSetupButtonComponent = (provider: ProviderPatientProviderType) => {
    if (provider === ProviderPatientProviderType.FitbitAPICMIProvider) {
      return siteSettings?.capabilities.includes(Capabilities.adaptt2d)
    } else if (provider === ProviderPatientProviderType.YpsomedAPICMIProvider)
      return false
    else return true
  }
  const getPage = (
    state: number,
    register: UseFormRegister<FormValues>,
    handleChange: () => Promise<void>
  ) => {
    if (state === 0) {
      const buttons = (
        Object.keys(ProviderPatientProviderType) as Array<
          keyof typeof ProviderPatientProviderType
        >
      ).map((provider) =>
        showSetupButtonComponent(provider as ProviderPatientProviderType) ? (
          <SetupButtonComponent
            key={provider}
            provider={provider as ProviderPatientProviderType}
            next={() => {
              goToNext()
              setCurrentProvider(provider as ProviderPatientProviderType)
            }}
          />
        ) : null
      )
      return buttons
    }
    if (state === 1)
      return (
        <FormPage
          register={register}
          provider={null}
          handleChange={handleChange}
          currentProvider={currentProvider}
        />
      )
    if (state === 2) return <SuccessPage currentProvider={currentProvider} />
    else
      return (
        <FormPage
          register={register}
          provider={provider}
          handleChange={handleChange}
          currentProvider={currentProvider}
        />
      )
  }
  const getSubmitText = (state: number) => {
    if (state === 1) return t('connect account')
    if (state === 2) return t('ok, got it!')
    else return t('save changes')
  }

  const getTitle = () => {
    if (setupState === 0) return t('Select Integration Type')
    if (setupState === 1)
      return `${t('Setup')} ${t(getProviderTypeInfoText(currentProvider))}`
    if (setupState === 2)
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <SuccesIcon icon={faCircleCheck} />
          {`${getProviderTypeInfoText(currentProvider)} ${t('Connected')}`}
        </div>
      )
    else return provider && t(getProviderTypeInfoText(provider.type))
  }
  return (
    <ModalComponent
      closeFun={handleShowProviderSetup}
      closetestid="ProviderIntegrationModel"
    >
      <Wrapper>
        <Header>
          <TitleWrapper>
            <Title data-testid="ProviderIntegrationModelTitle">
              {getTitle()}
            </Title>
            {currentProvider && (
              <IntegrationTypeLabel
                type={getIntegrationType(currentProvider)}
              />
            )}
          </TitleWrapper>
          {provider && <ClinicIntegrationStatusBadge state={provider.state} />}
        </Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Body>{getPage(setupState, register, handleChange)}</Body>
          {setupState > 0 && (
            <Footer>
              {provider && (
                <DeleteProvider
                  data-testid="deleteProvider"
                  onClick={handleDelete}
                >
                  {t('Remove integration')}
                </DeleteProvider>
              )}
              <ButtonWrapper>
                {setupState === 3 && (
                  <CancelButton
                    assigned={false}
                    onClick={handleShowProviderSetup}
                    type={'button'}
                  >
                    {t('Cancel')}
                  </CancelButton>
                )}
                <FormButton
                  assigned={false}
                  disabled={setupState === 3 ? formDisabled : !isValid}
                  type={'submit'}
                  data-testid="providerIntegrationFormButton"
                >
                  {getSubmitText(setupState)}
                </FormButton>
              </ButtonWrapper>
            </Footer>
          )}
        </Form>
      </Wrapper>
    </ModalComponent>
  )
}
