import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalComponent } from '../../../../../components/Modal/Modal'
import {
  getSupportedFileTypesString,
  supportedFileTypes
} from '../../../../../constants/supportedFileTypes'

const Wrapper = styled.div`
  display: flex;
  width: 31.25rem;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 1.5rem;
  background: var(--white-color);
`

const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: pre-line;
`
const ListWrapper = styled.ul`
  margin-left: 0;
  padding-left: 0;
  list-style-position: inside;
`
const Text = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  white-space: pre-line;
`
const ListText = styled.li`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  white-space: pre-line;
`
export const SupportedFormats = ({ closeFun }: { closeFun: () => void }) => {
  const { t } = useTranslation()
  return (
    <ModalComponent
      closeFun={closeFun}
      closetestid={'close_supported_formats_modal'}
    >
      <Wrapper>
        <Title>{t('supported_formats_title')}</Title>
        <TextWrapper>
          <Text>{t('supported_formats_text_one')}</Text>
          <ListWrapper>
            {supportedFileTypes.map((fileType) => (
              <ListText>{getSupportedFileTypesString(fileType)}</ListText>
            ))}
          </ListWrapper>
          <Text>{t('supported_formats_text_two')}</Text>
        </TextWrapper>
      </Wrapper>
    </ModalComponent>
  )
}
