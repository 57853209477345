import { Dispatch, SetStateAction } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CountrySelector, ParsedCountry } from 'react-international-phone'
import styled from 'styled-components'
import {
  InputField,
  InputFieldLabel,
  InputFieldLabelWrapper,
  InputFieldOptionalText,
  InputWrapper
} from '../../../../components/FormStyles/FormStyles'
import {
  LeftColumn,
  RightColumn,
  Section,
  SectionDescription,
  SectionTitle,
  SectionTitleBox,
  TicketFormValues
} from '../TicketCreation'
import './countrySelector.css'
const PhoneWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`
const CountryCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`
const CountrySelectContainer = styled.div`
  width: 7.25rem;
  height: 3rem;
  position: relative;
`

const CountryCodeText = styled.span`
  position: absolute;
  right: calc(50% - 0.25rem);
  bottom: 50%;
  transform: translateX(50%) translateY(50%);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
`
interface TicketContactInformationProps {
  register: UseFormRegister<TicketFormValues>
  country: ParsedCountry
  setCountry: Dispatch<SetStateAction<ParsedCountry>>
}
export const TicketContactInformation = ({
  register,
  country,
  setCountry
}: TicketContactInformationProps) => {
  const { t } = useTranslation()
  return (
    <Section>
      <LeftColumn>
        <SectionTitleBox>
          <SectionTitle>{t('ticket_creation_section_title_2')}</SectionTitle>
          <SectionDescription>
            {t('ticket_creation_section_description_2')}
          </SectionDescription>
        </SectionTitleBox>
      </LeftColumn>
      <RightColumn style={{ gap: '1rem' }}>
        <InputWrapper>
          <InputFieldLabelWrapper>
            <InputFieldLabel>
              {t('ticket_creation_contact_email_title')}
            </InputFieldLabel>
            <InputFieldOptionalText>
              {t('input_field_optional_text')}
            </InputFieldOptionalText>
          </InputFieldLabelWrapper>
          <InputField
            type="text"
            {...register('email', {
              required: false
            })}
            placeholder={t('input_field_email_placeholder')}
          ></InputField>
        </InputWrapper>
        <PhoneWrapper>
          <CountryCodeWrapper>
            <InputFieldLabel>
              {t('ticket_creation_contact_country_code_title')}
            </InputFieldLabel>
            <CountrySelectContainer>
              <CountrySelector
                selectedCountry={country.iso2}
                onSelect={(parsedCountry) => setCountry(parsedCountry)}
              />
              <CountryCodeText>{`+${country.dialCode}`}</CountryCodeText>
            </CountrySelectContainer>
          </CountryCodeWrapper>
          <InputWrapper>
            <InputFieldLabelWrapper>
              <InputFieldLabel>
                {t('ticket_creation_contact_phone_title')}
              </InputFieldLabel>
              <InputFieldOptionalText>
                {t('input_field_optional_text')}
              </InputFieldOptionalText>
            </InputFieldLabelWrapper>
            <InputField
              type="text"
              {...register('phone', {
                required: false
              })}
              placeholder={t('input_field_phone_placeholder')}
            ></InputField>
          </InputWrapper>
        </PhoneWrapper>
      </RightColumn>
    </Section>
  )
}
