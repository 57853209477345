import { createContext, Dispatch, SetStateAction, useContext } from 'react'

interface FiltersManagerContextState {
  resetAll: boolean
  setResetAll: Dispatch<SetStateAction<boolean>>
}

export const FiltersManagerContext = createContext<FiltersManagerContextState>({
  resetAll: false,
  setResetAll: () => null
})

export function useFiltersManagerContext() {
  return useContext(FiltersManagerContext)
}
