import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IFUCDAADAPT from '../../assets/PDFs/IFU-ADAPT-ver1-DA-clinician.pdf'
import IFUPDAADAPT from '../../assets/PDFs/IFU-ADAPT-ver1-DA.pdf'
import IFUCDA from '../../assets/PDFs/IFU-LinePortal-ver21-DA-clinician.pdf'
import IFUPDA from '../../assets/PDFs/IFU-LinePortal-ver21-DA.pdf'
import IFUCEN from '../../assets/PDFs/IFU-LinePortal-ver21-EN-clinician.pdf'
import IFUPEN from '../../assets/PDFs/IFU-LinePortal-ver21-EN.pdf'

import { useSessionContext } from '../../core/contexts/session-context'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import dayjs from '../../core/dayjs/dayjs'
import { Capabilities } from '../../core/entities/siteSettings.entity'
import { UserType } from '../../core/entities/user.entity'
const PDFDownload = styled.a`
  font-size: 0.875rem;
  font-weight: 700;
  font-family: inter;
  text-decoration: none;
  color: var(--brand-primary-color);
  &:visited {
    color: var(--brand-primary-color);
  }
`

export const IFUPdfDownloader = () => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const language = dayjs.locale()
  const { siteSettings } = useSiteSettingsContext()
  const getFile = () => {
    if (siteSettings?.capabilities.includes(Capabilities.adaptt2d)) {
      if (user?.type === UserType.Patient && language === 'da') {
        return { file: IFUPDAADAPT, name: 'IFU-ADAPT-ver1-DA.pdf' }
      } else if (user?.type === UserType.Patient && language === 'en') {
        return { file: IFUPDAADAPT, name: 'IFU-ADAPT-ver1-DA.pdf' }
      } else if (language === 'da') {
        return {
          file: IFUCDAADAPT,
          name: 'IFU-ADAPT-ver1-DA-clinician.pdf'
        }
      } else {
        return {
          file: IFUCDAADAPT,
          name: 'IFU-ADAPT-ver1-DA-clinician.pdf'
        }
      }
    } else {
      if (user?.type === UserType.Patient) {
        if (language === 'da') {
          return { file: IFUPDA, name: 'IFU-LinePortal-ver21-DA.pdf' }
        } else return { file: IFUPEN, name: 'IFU-LinePortal-ver21-EN.pdf' }
      } else {
        if (language === 'da') {
          return { file: IFUCDA, name: 'IFU-LinePortal-ver21-DA-clinician.pdf' }
        } else {
          return { file: IFUCEN, name: 'IFU-LinePortal-ver21-EN-clinician.pdf' }
        }
      }
    }
  }
  return (
    <PDFDownload href={getFile().file} download={getFile().name}>
      {t('Download PDF')}
    </PDFDownload>
  )
}
