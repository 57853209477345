import { faFileZipper } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import saveAs from 'file-saver'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { downloadPatientData } from '../../../core/api/downloadPatientData'
import { usePatientContext } from '../../../core/contexts/patient-context'
import { usePeriodContext } from '../../../core/contexts/period-context'
import dayjs from '../../../core/dayjs/dayjs'
import { Spinner, spinnerSize } from '../../Spinner/Spinner'
const ExportDataButton = styled.button`
  border: 0.0625rem solid var(--text-lightest);
  background-color: var(--white-color);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  width: 12rem;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
`

const Text = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-medium);
`
const Logo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  font-weight: 400;
  color: var(--text-medium);
`

export const ExportDataComponent = () => {
  const { t } = useTranslation()
  const { period } = usePeriodContext()
  const { patient } = usePatientContext()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const downloadData = async () => {
    if (isDownloading) return
    try {
      setIsDownloading(true)
      if (period) {
        const data = await downloadPatientData(patient?.patientId ?? '', {
          start: dayjs(
            new Date(
              new Date(period.endDate).setDate(
                period.endDate.getDate() - period.lengthOfPeriod + 1
              )
            )
          ).format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs(
            new Date(new Date(period.endDate).setDate(period.endDate.getDate()))
          ).format('YYYY-MM-DDTHH:mm:ss')
        })
        saveAs(data, `${patient?.patientId}.zip`)
        setIsDownloading(false)
      } else {
        throw new Error('No Period')
      }
    } catch (error) {
      setIsDownloading(false)
      console.log(error)
    }
  }
  return (
    <ExportDataButton data-testid="downloadPatientData" onClick={downloadData}>
      <Text>{isDownloading ? t('Downloading data') : t('Export data')}</Text>
      {isDownloading ? (
        <Spinner spinnersize={spinnerSize.small} />
      ) : (
        <Logo icon={faFileZipper}></Logo>
      )}
    </ExportDataButton>
  )
}
