import {
  faLifeRing,
  faLightbulb,
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TicketBadgeSize, TicketType } from '../../constants/constants'

const Wrapper = styled.div<{
  $config: {
    icon: IconDefinition
    color: string
    backgroundColor: string
    text: string
  }
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: ${(props) => props.$config.color};
`
const WrapperWithBackground = styled(Wrapper)<{
  $config: {
    icon: IconDefinition
    color: string
    backgroundColor: string
    text: string
  }
}>`
  height: 2rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${(props) => props.$config.color};
  background: ${(props) => props.$config.backgroundColor};
  color: ${(props) => props.$config.color};
  box-sizing: border-box;
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: inherit;
`

const Text = styled.span<{ $size: TicketBadgeSize }>`
  color: inherit;
  font-family: inter;
  font-size: ${(props) =>
    props.$size === TicketBadgeSize.large ? '1rem' : '0.75rem'};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: ${(props) =>
    props.$size === TicketBadgeSize.large ? 'capitalize' : 'uppercase'};
`
const getConfig = (type: TicketType) => {
  switch (type) {
    case TicketType.Support:
      return {
        icon: faLifeRing,
        color: 'var(--yellow)',
        backgroundColor: 'var(--yellow-gradient)',
        text: 'support_text'
      }
    case TicketType.Feedback:
      return {
        icon: faLightbulb,
        color: 'var(--brand-lighter-color)',
        backgroundColor: 'var(--brand-gradient)',
        text: 'feedback_text'
      }
  }
}
interface TicketTypeBadgeProps {
  type: TicketType
  withBackground: boolean
  size: TicketBadgeSize
}
export const TicketTypeBadge = ({
  type,
  withBackground,
  size
}: TicketTypeBadgeProps) => {
  const { t } = useTranslation()
  const config = getConfig(type)

  return (
    <>
      {withBackground ? (
        <WrapperWithBackground
          $config={config}
          data-testid="TicketTypeBadgeWithBackground"
        >
          <Icon icon={config.icon}></Icon>
          <Text data-testid="TicketTypeBadgeText" $size={size}>
            {t(config.text)}
          </Text>
        </WrapperWithBackground>
      ) : (
        <Wrapper
          $config={config}
          data-testid="TicketTypeBadgeWithoutBackground"
        >
          <Icon icon={config.icon}></Icon>
          <Text data-testid="TicketTypeBadgeText" $size={size}>
            {t(config.text)}
          </Text>
        </Wrapper>
      )}
    </>
  )
}
