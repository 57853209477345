import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { SortEnum } from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { LoadMoreButton } from '../../components/LoadMoreButton/LoadMoreButton'
import { Modal } from '../../components/Modal/Modal'
import { loadIncrement } from '../../constants/loadIncrement'
import { doHypoAlgo } from '../../core/api/do_hypo_algo'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'
import { EventsHeader } from './EventsHeader/EventsHeader'
import { EventsTable } from './EventsTable/EventsTable'
import { useEvents } from './hooks/useEvents'
import { HypoPredictionModal } from './HypoPredictionModal/HypoPredictionModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
`

export const Events = () => {
  const { patient } = usePatientContext()
  const { siteSettings } = useSiteSettingsContext()
  const { t } = useTranslation()
  const [order, setOrder] = useState<SortEnum>(SortEnum.Descending)
  const [size, setSize] = useState<number>(loadIncrement)
  const [update, setUpdate] = useState<boolean>(false)
  const [loadingHypo, setLoadingHypo] = useState<boolean>(false)

  const { events, eventsError } = useEvents({
    patientId: patient?.patientId,
    size,
    order,
    update
  })
  const [hypoAlgoModal, setHypoAlgoModal] = useState<boolean>(false)

  const handleHypoPrediction = async () => {
    try {
      setLoadingHypo(true)
      await doHypoAlgo(patient?.patientId, siteSettings?.releaseVersion)
      setHypoAlgoModal(false)
      setUpdate((update) => !update)
    } catch (e) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Something went wrong with the nocturnal prediction')
      })
    } finally {
      setLoadingHypo(false)
    }
  }

  return (
    <Wrapper>
      <Modal isOpen={hypoAlgoModal}>
        <HypoPredictionModal
          closeFun={() => setHypoAlgoModal(false)}
          handleHypoPrediction={handleHypoPrediction}
          loadingHypo={loadingHypo}
        />
      </Modal>
      <EventsHeader setHypoAlgoModal={setHypoAlgoModal} />
      <EventsTable
        events={events}
        eventsError={eventsError}
        order={order}
        setOrder={setOrder}
      />
      {events && events.size > size && (
        <LoadMoreButton
          loadAmount={size}
          setLoadAmount={setSize}
          maxSize={events?.size}
        />
      )}
    </Wrapper>
  )
}
