import {
  SupportFeedbackParams,
  TicketSoftwareVersion
} from '../../containers/Tickets/constants/constants'
import config from '../config'
import { AxiosRequest } from './axios-request'
import { PaginatedResponse } from './pagination'

export async function getSupportTickets(
  params: SupportFeedbackParams & {
    page: number
    resultsPerPage: number
    newestPortalVersion: string
    newestUploaderVersion: string
  },
  options: object = {}
): Promise<PaginatedResponse<any> | undefined> {
  // handle sorting
  const sortDesc =
    params.sorting.length > 0 ? params.sorting[0].desc : undefined

  const portalSoftwareVersion =
    params.softwareVersion === TicketSoftwareVersion.earlier
      ? `!${params.newestPortalVersion}`
      : params.softwareVersion === undefined
      ? undefined
      : params.newestPortalVersion
  const uploaderSoftwareVersion =
    params.softwareVersion === TicketSoftwareVersion.earlier
      ? `!${params.newestUploaderVersion}`
      : params.softwareVersion === undefined
      ? undefined
      : params.newestUploaderVersion

  const formattedParams = {
    page: params.page,
    resultsPerPage: params.resultsPerPage,
    status: params.status,
    types: params.types,
    systemContext: params.systemContext,
    reportingTime: params.reportingTime,
    searchString: params.searchString,
    reportingUser: params.reportingUser,
    portalVersion: portalSoftwareVersion,
    uploaderVersion: uploaderSoftwareVersion,
    sortDesc
  }

  const response = await AxiosRequest.create().get(
    `${config.API_URL}/tickets`,
    {
      ...options,
      params: formattedParams,
      paramsSerializer: {
        indexes: null
      }
    }
  )
  return response.data as PaginatedResponse<any>
}
