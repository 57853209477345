import { faSearch, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { useDebounce } from '../../../hooks/useDebounce'
import { FilterFunctionButton } from '../Filters/FilterFunctionButton/FilterFunctionButton'

interface TableSearchBarProps {
  handleChange: (search: string) => void
}

export const searchBarGrow = () => keyframes`
0% { width: 0rem; visibility: visible;}
100% { width: 26.25rem; visibility: visible }
`

export const searchBarShrink = () => keyframes`
  0% { width: 26.25rem; visibility: visible;}
100% { width: 0rem; visibility: hidden }
`

const SearchFieldWrapper = styled.div`
  visibility: hidden;
  display: flex;
  width: 0rem;
  height 2.5rem;
  box-sizing: border-box;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--brand-primary-color);
  box-shadow: 0rem 0rem 0rem 0.25rem var(--brand-primary-16-color);

  &.searchBarGrow {
    animation-name: ${searchBarGrow};
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
    &.searchBarShrink {
    animation-name: ${searchBarShrink};
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-delay: 0s;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`
const SearchField = styled.input`
  width: 21rem;
  border: none;
  color: var(--text-primary);
  font-family: inter;
  font-weight: 500;
  font-size: 0.875rem;
  &:focus {
    outline: none;
  }

  &.searchBarGrow {
    visibility: visible;
  }
  &.searchBarShrink {
    visibility: hidden;
  }
`

const SearchLogo = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 400;
  color: var(--brand-primary-color);
  margin-left: 0.75rem;
  &.searchBarGrow {
    visibility: visible;
  }
  &.searchBarShrink {
    visibility: hidden;
  }
`
const DeleteLogo = styled(FontAwesomeIcon)`
  font-size: 1rem;
  font-weight: 900;
  color: inherit;
`
const DeleteButtonWrapper = styled.button`
  border: none;
  background: none;
  margin: 0 auto;
  padding: 0;
  &:disabled {
    opacity: 0;
    cursor: auto;
  }
  width: 1.75rem;
  cursor: pointer;
  color: var(--text-lighter);
  &:hover {
    color: var(--text-medium);
  }
  &.searchBarGrow {
    visibility: visible;
  }
  &.searchBarShrink {
    visibility: hidden;
  }
`

export const TableSearchBar = ({ handleChange }: TableSearchBarProps) => {
  const { t } = useTranslation()
  const [searchString, setSearchString] = useState('')
  const [showButton, setShowButton] = useState(true)
  const [searchBarClass, setSearchBarClass] = useState('')
  const firstRender = useRef(true)
  const searchBarOuterRef = useRef<HTMLDivElement | null>(null)
  const searchBarInnerRef = useRef<HTMLInputElement | null>(null)
  const debouncedSearch = useDebounce(searchString, 300)

  const initializeSearch = () => {
    setShowButton(false)
    setSearchBarClass('searchBarGrow')

    setTimeout(() => {
      if (searchBarInnerRef.current) {
        searchBarInnerRef.current.focus()
      }
    }, 1)
  }

  const quitSearch = () => {
    setSearchBarClass('searchBarShrink')
    setShowButton(true)
  }

  const handleOnBlur = () => {
    if (searchString === undefined || searchString === '') {
      quitSearch()
    }
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    handleChange(debouncedSearch)
  }, [debouncedSearch, handleChange])

  return (
    <>
      {showButton && (
        <FilterFunctionButton
          icon={faSearch}
          text={t('Search')}
          handleClick={initializeSearch}
        />
      )}
      <SearchFieldWrapper ref={searchBarOuterRef} className={searchBarClass}>
        <SearchLogo icon={faSearch} className={searchBarClass} />
        <SearchField
          type="text"
          data-testid="SearchBarInput"
          onChange={(e) => setSearchString(e.target.value)}
          onBlur={handleOnBlur}
          value={searchString}
          ref={searchBarInnerRef}
          className={searchBarClass}
        />
        <DeleteButtonWrapper
          className={searchBarClass}
          onClick={() => setSearchString('')}
          data-testid="clearFieldButton"
        >
          <DeleteLogo icon={faTimesCircle} />
        </DeleteButtonWrapper>
      </SearchFieldWrapper>
    </>
  )
}
