import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ModalComponent } from '../../Modal/Modal'
import { ErrorComponent } from '../ErrorComponent'

const Wrapper = styled.div`
  display: flex;
  width: 31.25rem;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  border-radius: 1.5rem;
  background-color: var(--white-color);
`

const Button = styled.button`
  display: flex;
  padding: 0.75rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--brand-primary-color);
  border: none;
  color: var(--white-color);
  height: 3rem;
  box-sizing: border-box;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  cursor: pointer;
  &:hover {
    background-color: var(--brand-darker-color);
  }
`
interface ErrorComponentModalProps {
  closeFun: () => void
  content: string
  testId?: string
}
export const ErrorComponentModal = ({
  closeFun,
  content,
  testId
}: ErrorComponentModalProps) => {
  const { t } = useTranslation()
  return (
    <ModalComponent closeFun={closeFun} closetestid={`${testId}_modal`}>
      <Wrapper>
        <ErrorComponent testId={testId} content={content}></ErrorComponent>
        <Button onClick={closeFun}>
          {t('error_component_modal_understand_text')}
        </Button>
      </Wrapper>
    </ModalComponent>
  )
}
