import { useTranslation } from 'react-i18next'
import {
  ErrorHeader,
  HeaderText,
  HeaderTitle,
  Info,
  Page,
  Wrapper
} from './ErrorStyles'

export const NotFound = () => {
  const { t } = useTranslation()
  return (
    <Page>
      <Wrapper>
        <ErrorHeader>
          <HeaderTitle>{t('page not found!')}</HeaderTitle>
          <HeaderText>404</HeaderText>
        </ErrorHeader>
        <Info>{t('pageNotFound')}</Info>
      </Wrapper>
    </Page>
  )
}
