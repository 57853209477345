import { faArrowDownToArc } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import saveAs from 'file-saver'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getTicketAttachments } from '../../../../core/api/ticket-download-attachments'
import { DownloadStatus, useDownloader } from '../../../../hooks/use-downloader'

const Wrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.75rem;
  background-color: var(--element-bg);
  border-radius: 0.5rem;
  backdrop-filter: blur(0.5rem);
  border: none;
  &:hover {
    background-color: var(--element-bg-dark);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: var(--element-bg-80);
    color: var(--text-lighter);
  }
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: var(--text-primary);
`

const Text = styled.span`
  font-family: inter;
  font-size: 1rem;
  color: var(--text-primary);
  font-weight: 600;
`

export const DownloadTicketAttachments = ({
  ticketId
}: {
  ticketId: string
}) => {
  const { t } = useTranslation()
  const { state, download } = useDownloader()
  const handleDownloadTicketAttachments = async () => {
    async function downloadTicketAttachments() {
      const data = await download(
        (progressCb: (progress: ProgressEvent) => void) => {
          return getTicketAttachments(
            {
              id: ticketId
            },
            progressCb
          )
        }
      )
      if (data) {
        saveAs(data, t('ticket_view_attachments_filename', { id: ticketId }))
      }
    }
    downloadTicketAttachments()
  }
  return (
    <Wrapper
      onClick={handleDownloadTicketAttachments}
      data-testid="ticket_attachments_downloader"
      disabled={state.status === DownloadStatus.Downloading}
    >
      <Icon icon={faArrowDownToArc}></Icon>
      <Text>{t('ticket_view_download_attachments_zip')}</Text>
    </Wrapper>
  )
}
