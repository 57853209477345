import { faArrowsRotate } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { fetchDataApi } from '../../../../../../core/api/fetchData-api'
import {
  SnackBar,
  SnackBarType
} from '../../../../../../libraries/Toast/SnackBar'
import { ContextMenuItem } from '../../../../../ContextMenu/ContextMenu'
import { ProviderPatient } from '../../ProviderPatientsInterfaces'

export const FetchData = ({
  providerPatient
}: {
  providerPatient: ProviderPatient
}) => {
  const { t } = useTranslation()
  const fetchData = async () => {
    try {
      await fetchDataApi(providerPatient.id, providerPatient.providerType)
    } catch {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error fetching dexcom data')
      })
    }
  }
  return (
    <ContextMenuItem
      onClick={fetchData}
      icon={faArrowsRotate}
      data-testid="ProviderPatientsOptionsFetchData"
    >
      {t('provider_patients_table_options_fetch_data')}
    </ContextMenuItem>
  )
}
