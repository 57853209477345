import { ProviderPatientProviderType } from '../ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'

export const getProviderPlatformTitle = (type: ProviderPatientProviderType) => {
  switch (type) {
    case ProviderPatientProviderType.AbbottLibreViewWebCMIProvider:
      return 'Abbott Libreview'
    case ProviderPatientProviderType.MedtronicCarelinkWebCMIProvider:
      return 'Medtronic Carelink'
    case ProviderPatientProviderType.DexcomAPICMIProvider:
      return 'Dexcom API'
    case ProviderPatientProviderType.FitbitAPICMIProvider:
      return 'Fitbit API'
    case ProviderPatientProviderType.YpsomedAPICMIProvider:
      return 'Ypsomed API'
  }
}
