import styled from 'styled-components'
import { TicketType } from '../../../containers/Tickets/constants/constants'

import { faLifeRing, faLightbulb } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-self: stretch;
`

const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`

const Wrapper = styled.div`
  border-radius: 1rem;
  border: 0.0625rem solid var(--text-lightest);
  display: flex;
  height: 4rem;
  padding: 1rem 1.5rem 1rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  cursor: pointer;
  box-sizing: border-box;
`
const FeedbackWrapper = styled(Wrapper)`
  &:hover {
    background-color: var(--brand-primary-12-color);
    border: none;
  }
`
const SupportWrapper = styled(Wrapper)`
  &:hover {
    background-color: var(--yellow-12);
    border: none;
  }
`
const FeedbackIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  line-height: 1rem;
  color: var(--brand-lighter-color);
`
const FeedbackText = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: var(--brand-lighter-color);
`
const SupportText = styled(FeedbackText)`
  color: var(--yellow-darker);
`
const SupportIcon = styled(FeedbackIcon)`
  color: var(--yellow-darker);
`
interface TicketSectionProps {
  closeFun: () => void
  showTicketCreation: (ticketType: TicketType) => void
}
export const TicketSection = ({
  closeFun,
  showTicketCreation
}: TicketSectionProps) => {
  const { t } = useTranslation()
  const [ticketState, setTicketState] = useState<TicketType | null>(null)

  return (
    <>
      <Buttons data-testid="ticketSupoortSection">
        <SupportWrapper onClick={() => showTicketCreation(TicketType.Support)}>
          <BodyWrapper>
            <SupportIcon icon={faLifeRing}></SupportIcon>
            <SupportText>{t('initialize_support_button_text')}</SupportText>
          </BodyWrapper>
        </SupportWrapper>
        <FeedbackWrapper
          onClick={() => showTicketCreation(TicketType.Feedback)}
        >
          <BodyWrapper>
            <FeedbackIcon icon={faLightbulb}></FeedbackIcon>
            <FeedbackText>{t('initialize_feedback_button_text')}</FeedbackText>
          </BodyWrapper>
        </FeedbackWrapper>
      </Buttons>
    </>
  )
}
