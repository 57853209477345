import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContextMenu } from '../../../../ContextMenu/ContextMenu'
import { ProviderPatient } from '../ProviderPatientsInterfaces'
import { ProviderPatientsOptionsPatient } from './ProviderPatientsOptionsPatient/ProviderPatientsOptionsPatient'

export function ProviderPatientsOptions({ row }: { row: ProviderPatient }) {
  const { t } = useTranslation()
  const items: ReactNode[] = useMemo(() => {
    const list = [
      <ProviderPatientsOptionsPatient
        providerPatient={row}
      ></ProviderPatientsOptionsPatient>
    ]

    return list
  }, [row])

  return <ContextMenu items={items} />
}
