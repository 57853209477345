import { useOutletContext, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CMIIntegrationValue } from '../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { PaginatedResponse } from '../../../../core/api/pagination'
import { ErrorBoundary } from '../../../ErrorBoundary/ErrorBoundary'
import {
  ClinicManagedCloudPatientStatus,
  Filter,
  PMIStateValues,
  ProviderManagementTypes,
  ProviderPatientsTableType
} from './ProviderPatientsInterfaces'
import { ProviderPatientsClinicManagedTable } from './ProviderPatientsTables/ProviderPatientsClinicManagedTable/ProviderPatientsClinicManagedTable'
import { ProviderPatientsMappingTable } from './ProviderPatientsTables/ProviderPatientsMappingTable/ProviderPatientsMappingTable'
import { ProviderPatientsPatientManagedTable } from './ProviderPatientsTables/ProviderPatientsPatientManagedTable/ProviderPatientsPatientManagedTable'
import { ProviderPatientsWarningsTable } from './ProviderPatientsTables/ProviderPatientsWarningsTable/ProviderPatientsWarningsTable'

export const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;

  cursor: pointer;
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  min-height: 2.125rem;
`

export const RowBoxWrapper = styled.div`
  min-height: 2.125rem;
  display: flex;
  align-items: center;
`

const getDefaultFilters = (
  tableType: ProviderPatientsTableType
): { [key: string]: string | string[] | undefined } => {
  switch (tableType) {
    case ProviderPatientsTableType.patientIntegrationsWarnings:
      return {
        [Filter.patientIntegrationState]: [
          PMIStateValues.DISCONNECTED,
          PMIStateValues.CONNECTING
        ],
        [Filter.providerTypes]: []
      }
    case ProviderPatientsTableType.patientMapping:
      return {
        [Filter.patientMappingState]: [ClinicManagedCloudPatientStatus.NoLink],
        [Filter.providerTypes]: []
      }
    case ProviderPatientsTableType.individual:
      return {
        [Filter.patientMappingState]: [],
        [Filter.patientIntegrationState]: [],
        [Filter.providerTypes]: []
      }
  }
}

interface ProviderPatientsProps {
  tableType: ProviderPatientsTableType
}
export const ProviderPatients = ({ tableType }: ProviderPatientsProps) => {
  const { providerId } = useParams()
  const outletContext = useOutletContext<
    | {
        response: PaginatedResponse<CMIIntegrationValue> | null
      }
    | undefined
  >()
  const context = outletContext
  const getProviderPatientsTable = () => {
    switch (tableType) {
      case ProviderPatientsTableType.patientIntegrationsWarnings:
        return (
          <ProviderPatientsWarningsTable
            defaultFilters={getDefaultFilters(tableType)}
          />
        )
      case ProviderPatientsTableType.patientMapping:
        return (
          <ProviderPatientsMappingTable
            defaultFilters={getDefaultFilters(tableType)}
          />
        )
      case ProviderPatientsTableType.individual: {
        const provider = context?.response?.data.filter(
          (provider) => provider.id === providerId
        )[0]
        switch (provider?.managedBy) {
          case ProviderManagementTypes.clinicmanaged:
            return (
              <ProviderPatientsClinicManagedTable
                defaultFilters={getDefaultFilters(tableType)}
                provider={provider}
              />
            )
          case ProviderManagementTypes.patientmanaged:
            return (
              <ProviderPatientsPatientManagedTable
                defaultFilters={getDefaultFilters(tableType)}
                provider={provider}
              />
            )
          default:
            return <></>
        }
      }
    }
  }
  return <ErrorBoundary>{getProviderPatientsTable()}</ErrorBoundary>
}
