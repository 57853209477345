import { faCircleCheck } from '@fortawesome/pro-light-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Spinner, spinnerSize } from '../../../../components/Spinner/Spinner'

const Wrapper = styled.div`
  display: flex;
  width: 42rem;
  padding: 2rem;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background-color: var(--white-color);
  border-radius: 1.5rem;
`

const CheckIcon = styled(FontAwesomeIcon)`
  color: var(--lime-green);
  font-size: 4rem;
`

const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
`

const Description = styled.span`
  color: var(--text-medium);
  text-align: center;
  font-family: inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
`

const ErrorWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background-color: var(--yellow-12);
`

const ErrorIcon = styled(FontAwesomeIcon)`
  color: var(--yellow);
  font-size: 1.125rem;
  line-height: 1.25rem;
`
const ErrorDescription = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: pre-line;
`

const FailedIcon = styled(FontAwesomeIcon)`
  color: var(--yellow);
  font-size: 4rem;
`

const FinishButton = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--brand-primary-color);
  font-family: inter;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
  color: var(--white-color);
  &:hover {
    background: var(--brand-darker-color);
  }
  cursor: pointer;
`

interface TicketSubmissionFinishedProps {
  loading: boolean
  error: boolean
  retry: () => void
  removedFiles: string[]
  closeFun: () => void
}

export const TicketSubmissionFinished = ({
  loading,
  error,
  retry,
  removedFiles,
  closeFun
}: TicketSubmissionFinishedProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper data-testid="ticketSubmissionFinishedWrapper">
      {loading ? (
        <Spinner spinnersize={spinnerSize.large}></Spinner>
      ) : error ? (
        <FailedIcon
          icon={faTriangleExclamation}
          data-testid="faTriangleExclamation"
        ></FailedIcon>
      ) : (
        <CheckIcon icon={faCircleCheck} data-testid="faCircleCheck"></CheckIcon>
      )}
      {!loading && (
        <>
          <Title>
            {error
              ? t('ticket_failed_submitted_title')
              : t('ticket_successfully_submitted_title')}
          </Title>
          <Description>
            {error
              ? t('ticket_failed_submitted_description')
              : t('ticket_successfully_submitted_description')}
          </Description>
          {removedFiles.length > 0 && (
            <ErrorWrapper>
              <ErrorIcon icon={faTriangleExclamation}></ErrorIcon>
              <ErrorDescription>
                {t('ticket_successfully_submitted_file_no_longer_exists', {
                  fileNames: removedFiles.join('\n')
                })}
              </ErrorDescription>
            </ErrorWrapper>
          )}
          <FinishButton
            onClick={() => (error ? retry() : closeFun())}
            data-testid="finish_ticket_submission_final_button"
          >
            {error
              ? t('ticket_failed_submitted_finish_text')
              : t('ticket_successfully_submitted_finish_text')}
          </FinishButton>
        </>
      )}
    </Wrapper>
  )
}
