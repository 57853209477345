import { ConsultationsAccountLinkButton } from '../../../../../Consultations/ConsultationsAccountLink/ConsultationsAccountLink'
import {
  PMIStateValues,
  ProviderManagementTypes,
  ProviderPatient
} from '../../ProviderPatientsInterfaces'
import { FetchData } from '../FetchData/FetchData'
import { GetLatestAPIResponse } from '../GetLatestAPIResponse/GetLatestAPIResponseButton'
import { OpenPatientIntegrations } from '../OpenPatientIntegrations/OpenPatientIntegrations'

interface ProviderPatientsOptionsPatientProps {
  providerPatient: ProviderPatient
}

export function ProviderPatientsOptionsPatient({
  providerPatient
}: ProviderPatientsOptionsPatientProps) {
  return (
    <>
      {providerPatient.assignedPatientId && (
        <OpenPatientIntegrations
          patientId={providerPatient.assignedPatientId}
        />
      )}
      {providerPatient.managementType ===
        ProviderManagementTypes.patientmanaged &&
        providerPatient.pmiStateValue === PMIStateValues.CONNECTED && (
          <FetchData providerPatient={providerPatient} />
        )}
      {providerPatient.managementType ===
        ProviderManagementTypes.patientmanaged && (
        <GetLatestAPIResponse providerPatient={providerPatient} />
      )}
      {providerPatient.managementType ===
        ProviderManagementTypes.clinicmanaged && (
        <ConsultationsAccountLinkButton
          providerPatient={providerPatient}
          inOptionsMenu={true}
        />
      )}
    </>
  )
}
