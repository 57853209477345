import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal } from '../../../../components/Modal/Modal'
import { useSessionContext } from '../../../../core/contexts/session-context'
import { TicketType } from '../../constants/constants'
import {
  InputTitle,
  InputWrapper,
  LeftColumn,
  RightColumn,
  Section,
  SectionDescription,
  SectionTitle,
  SectionTitleBox,
  TicketFormValues
} from '../TicketCreation'
import { SupportedFormats } from './SupportedFormats/SupportedFormats'
import { TicketAttachments } from './TicketAttachments/TicketAttachments'
import { TicketAttachmentUploader } from './TicketAttachmentUploader/TicketAttachmentUploader'

const DescriptionTextArea = styled.textarea`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  resize: none;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  &:focus,
  active {
    border-color: var(--brand-primary-color);
    box-shadow: 0rem 0rem 0rem 0.375rem var(--brand-primary-16-color);
    outline: none;
  }
  padding: 1rem;
`

interface TicketDescriptionProps {
  register: UseFormRegister<TicketFormValues>
  attachments: File[] | null
  setAttachments: Dispatch<SetStateAction<File[] | null>>
  type: TicketType
}
export const TicketDescription = ({
  register,
  attachments,
  setAttachments,
  type
}: TicketDescriptionProps) => {
  const { user } = useSessionContext()
  const [showSupportedFormats, setShowSupportedFormats] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { t } = useTranslation()
  return (
    <>
      <Modal isOpen={showSupportedFormats}>
        <SupportedFormats
          closeFun={() => setShowSupportedFormats(false)}
        ></SupportedFormats>
      </Modal>
      <Section>
        <LeftColumn>
          <SectionTitleBox>
            <SectionTitle>
              {t(`ticket_creation_section_title_1_${type.toLowerCase()}`)}
            </SectionTitle>
            <SectionDescription>
              {t(`ticket_creation_section_description_1_${type.toLowerCase()}`)}
            </SectionDescription>
          </SectionTitleBox>
        </LeftColumn>
        <RightColumn>
          <InputWrapper>
            <InputTitle>
              {t('ticket_creation_in_depth_description_title')}
            </InputTitle>
            <DescriptionTextArea
              data-testid="TicketDescriptionTextArea"
              rows={12}
              maxLength={1000}
              {...register('description', {
                required: true
              })}
              placeholder={t(
                `ticket_description_area_${type.toLowerCase()}_placeholder`
              )}
            />
          </InputWrapper>
          {user !== null && (
            <TicketAttachmentUploader
              attachments={attachments}
              setAttachments={setAttachments}
              setShowSupportedFormats={setShowSupportedFormats}
              inputRef={inputRef}
            />
          )}
          <TicketAttachments
            attachments={attachments}
            setAttachments={setAttachments}
            inputRef={inputRef}
          />
        </RightColumn>
      </Section>
    </>
  )
}
