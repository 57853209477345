import { faUserPlus } from '@fortawesome/pro-solid-svg-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { formatCPR } from '../../../helpers/helpers'
import { ContextMenuItem } from '../../ContextMenu/ContextMenu'
import { CreatePatientModal } from '../../CreatePatientModal/CreatePatientModal'
import { OpenPatientIntegrations } from '../../IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsOptions/OpenPatientIntegrations/OpenPatientIntegrations'
import { Modal } from '../../Modal/Modal'

interface ConsultationsOptionsPatientProps {
  patientId: string | null
  mrn: string | null
  name: string | null
  onPatientCreated: () => void
}

export function ConsultationsOptionsPatient({
  patientId,
  mrn,
  name,
  onPatientCreated
}: ConsultationsOptionsPatientProps) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()

  return (
    <>
      <Modal isOpen={showModal}>
        <CreatePatientModal
          mrn={mrn ? formatCPR(mrn) : null}
          name={name}
          closeModal={() => setShowModal(false)}
          refresh={() => onPatientCreated()}
        />
      </Modal>
      {patientId ? (
        <OpenPatientIntegrations patientId={patientId} />
      ) : (
        <ContextMenuItem
          icon={faUserPlus}
          onClick={() => setShowModal(true)}
          data-testid="consultationsOptionsCreatePatient"
        >
          {t('Create patient')}
        </ContextMenuItem>
      )}
    </>
  )
}
