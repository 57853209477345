import { TicketDTO } from '../../containers/Tickets/constants/constants'
import config from '../config'
import { AxiosRequest } from './axios-request'

export const getTicket = async (ticketId: string) => {
  const response = await AxiosRequest.create().get(
    config.API_URL + `/tickets/${ticketId}`
  )
  return response.data as TicketDTO
}
