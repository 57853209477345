import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons'
import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import {
  HeaderWrapper,
  SortIconWrapper
} from '../../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatients'
import { SortEnum } from '../../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { spinnerSize } from '../../../components/Spinner/Spinner'
import { ErrorRow } from '../../../components/Table/ErrorRow'
import { NoDataRow } from '../../../components/Table/NoDataRow'
import {
  ScrollTableComponent,
  THead,
  TText,
  Th
} from '../../../components/Table/ScrollTableComponent'
import { ScrollTableLoading } from '../../../components/Table/ScrollTableLoading.tsx'
import {
  SortIconDown,
  SortIconUp
} from '../../../components/Uploads/UploadsHeaderComponent/UploadsHeaderComponent'
import { PatientLogSearchResult } from '../interfaces'
import { EventsTableRow } from './EventsTableRow/EventsTableRow'

const EventsTableGroup = () => {
  return (
    <colgroup data-testid="EventsTableGroup">
      <col style={{ width: '18%' }}></col>
      <col style={{ width: '26%' }}></col>
      <col style={{ width: '19%' }}></col>
      <col style={{ width: '37%' }}></col>
    </colgroup>
  )
}

const ColumnTitle = ({ title }: { title: string }) => {
  return (
    <Th>
      <HeaderWrapper>
        <TText>{title}</TText>
      </HeaderWrapper>
    </Th>
  )
}
interface PatientHeaderProps {
  order: SortEnum
  setOrder: Dispatch<SetStateAction<SortEnum>>
}
export const EventsTableHeader = ({ order, setOrder }: PatientHeaderProps) => {
  return (
    <THead data-testid="eventTableHeader">
      <Th>
        <HeaderWrapper>
          <TText>{t('Date & time')}</TText>
          <SortIconWrapper
            data-testid="sortbutton"
            onClick={() =>
              setOrder(
                order === SortEnum.Ascending
                  ? SortEnum.Descending
                  : SortEnum.Ascending
              )
            }
          >
            <SortIconUp
              data-testid="sortUp"
              icon={faSortUp}
              $active={order === SortEnum.Ascending}
            />
            <SortIconDown
              data-testid="sortDown"
              icon={faSortDown}
              $active={order === SortEnum.Descending}
            />
          </SortIconWrapper>
        </HeaderWrapper>
      </Th>
      <ColumnTitle title={t('Prediction')} />
      <ColumnTitle title={t('User')} />
      <ColumnTitle title={t('More')} />
    </THead>
  )
}

interface EventsTableProps {
  eventsError: Error | null
  events: PatientLogSearchResult | null
  order: SortEnum
  setOrder: Dispatch<SetStateAction<SortEnum>>
}
export const EventsTable = ({
  events,
  eventsError,
  order,
  setOrder
}: EventsTableProps) => {
  const renderRows = () => {
    if (eventsError) {
      return <ErrorRow></ErrorRow>
    }
    if (events) {
      if (events.logs.length === 0) {
        return <NoDataRow />
      } else {
        return events.logs.map((log, index) => (
          <EventsTableRow key={'patientLog' + index} log={log} />
        ))
      }
    } else {
      return <ScrollTableLoading size={spinnerSize.large} />
    }
  }

  return (
    <ScrollTableComponent group={<EventsTableGroup />}>
      <EventsTableHeader order={order} setOrder={setOrder} />
      {renderRows()}
    </ScrollTableComponent>
  )
}
