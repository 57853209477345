import { TFunction } from 'react-i18next'
import { User, UserType } from '../../../core/entities/user.entity'
import { ProviderManagementTypes } from '../ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'

export const getProviderManagementTypeText = (
  user: User | null,
  managedBy: ProviderManagementTypes,
  t: TFunction<'translation', undefined>
) => {
  if (user?.type === UserType.Patient) {
    return t('managed_by_you_text')
  } else if (managedBy === ProviderManagementTypes.clinicmanaged) {
    return t('managed_by_clinic_text')
  } else {
    return t('managed_by_patient_text')
  }
}
