import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderPatientProviderType } from '../../IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import {
  ConsultationsAccountButtonProps,
  ConsultationsData
} from '../constants/constants'
import { AbbottLibreviewConsultationsButton } from './AbbottLibreviewConsultations/AbbottLibreviewConsultationsButton'
import {
  NoAccountText,
  NoAccountWrapper
} from './ConsultationsAccountLinkStyledComponents/ConsultationsAccountLinkStyledComponents'
import { DexcomConsultationsButton } from './DexcomConsultationsButton/DexcomConsultationsButton'
import { FitbitConsultationsButton } from './FitbitConsultationsButton/FItbitConsultationsButton'
import { MedtronicCarelinkConsultationsButton } from './MedtronicCarelinkConsultations/MedtronicCarelinkConsultationsButton'
import { YpsomedConsultationsButton } from './YpsomedConsultationsButton/YpsomedConsultationsButton'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.375rem;
`
export const ConsultationsAccountLinkButton = ({
  providerPatient,
  row,
  inOptionsMenu
}: ConsultationsAccountButtonProps) => {
  const getProviderButton = () => {
    switch (providerPatient.providerType) {
      case ProviderPatientProviderType.AbbottLibreViewWebCMIProvider:
        return (
          <AbbottLibreviewConsultationsButton
            providerPatient={providerPatient}
            row={row}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case ProviderPatientProviderType.MedtronicCarelinkWebCMIProvider:
        return (
          <MedtronicCarelinkConsultationsButton
            providerPatient={providerPatient}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case ProviderPatientProviderType.DexcomAPICMIProvider:
        return (
          <DexcomConsultationsButton
            providerPatient={providerPatient}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case ProviderPatientProviderType.YpsomedAPICMIProvider:
        return (
          <YpsomedConsultationsButton
            providerPatient={providerPatient}
            inOptionsMenu={inOptionsMenu}
          />
        )
      case ProviderPatientProviderType.FitbitAPICMIProvider:
        return (
          <FitbitConsultationsButton
            providerPatient={providerPatient}
            inOptionsMenu={inOptionsMenu}
          />
        )
      default:
        return null
    }
  }

  return <>{getProviderButton()}</>
}

export const ConsultationsAccountLink = ({
  row
}: {
  row: ConsultationsData
}) => {
  const { t } = useTranslation()

  return (
    <>
      {row.providerPatients ? (
        <Wrapper>
          {row.providerPatients.map((providerPatient) => (
            <ConsultationsAccountLinkButton
              key={providerPatient.id + providerPatient.providerType}
              providerPatient={providerPatient}
              row={row}
              inOptionsMenu={false}
            />
          ))}
        </Wrapper>
      ) : (
        <NoAccountWrapper>
          <NoAccountText>{t('no_linked_accounts')}</NoAccountText>
        </NoAccountWrapper>
      )}
    </>
  )
}
