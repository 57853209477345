import { faEye, faPencil } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  InputField,
  InputFieldLabel,
  InputFieldWrapper,
  InputWrapper
} from '../../../../../../FormStyles/FormStyles'
import { FormValues } from '../../../ProviderIntegrationModel'

interface InputComponentProps {
  label:
    | 'alias'
    | 'username'
    | 'password'
    | 'emailAddress'
    | 'emailHost'
    | 'emailPassword'
    | 'clientId'
    | 'clientSecret'
  register: UseFormRegister<FormValues>
  disabled: boolean
  handleChange: () => void
}

const placeholderMapping = {
  alias: 'alias',
  username: 'username',
  password: 'password',
  emailAddress: 'email address',
  emailHost: 'email host',
  emailPassword: 'email password',
  clientId: 'Client ID',
  clientSecret: 'Client Secret'
}

const ShowPassword = styled(FontAwesomeIcon)<{ $isPassword: boolean }>`
  font-size: 0.875rem;
  font-weight: 900;
  color: ${(props) =>
    props.$isPassword ? 'var(--text-lighter)' : 'var(--brand-primary-color)'};
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  &:hover {
    color: var(--brand-primary-color);
  }
`
const EditIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 900;
  color: var(--text-lighter);
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  &:hover {
    color: var(--brand-primary-color);
  }
`
export const jumpToEndOfInput = (inputRef: HTMLInputElement) => {
  setTimeout(() => {
    if (inputRef) {
      inputRef.selectionStart = inputRef.selectionStart = inputRef.value.length
    }
  }, 0)
}
export const InputComponent = ({
  label,
  register,
  disabled,
  handleChange
}: InputComponentProps) => {
  const { t } = useTranslation()
  const [isPassword, setIsPassword] = useState<boolean>(
    label === 'password' ||
      label === 'emailPassword' ||
      label === 'clientSecret'
  )
  const [allowEdit, setAllowEdit] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { ref, ...reg } = register(label, {
    required: `${t('Please enter')} ${label}`,
    onChange: handleChange
  })

  const handleEdit = () => {
    if (allowEdit) {
      setAllowEdit(false)
    } else {
      setAllowEdit(true)
    }
  }
  const handleDisabled = () => {
    if (allowEdit) {
      return false
    }
    return disabled
  }
  return (
    <InputWrapper>
      <InputFieldLabel
        data-testid="InputComponentText"
        style={{ textTransform: 'capitalize' }}
      >
        {label === 'alias' ? t('Integration alias') : t(label)}
      </InputFieldLabel>
      <InputFieldWrapper>
        <InputField
          data-testid={`InputComponentInput${label}`}
          {...reg}
          placeholder={`${t('Insert')} ${t(placeholderMapping[label])}`}
          autoComplete="off"
          type={isPassword ? 'password' : 'text'}
          ref={(e) => {
            ref(e)
            inputRef.current = e
          }}
          disabled={handleDisabled()}
        ></InputField>
        {(label === 'password' || label === 'emailPassword') && !disabled && (
          <ShowPassword
            $isPassword={isPassword}
            data-testid="showPassword"
            icon={faEye}
            onClick={() => {
              setIsPassword(!isPassword)
              inputRef.current && inputRef.current.focus()
              // Only works in chrome if using a timeout of 0.
              jumpToEndOfInput(inputRef.current as HTMLInputElement)
            }}
          />
        )}
        {disabled && label !== 'clientSecret' && label !== 'clientId' && (
          <EditIcon
            icon={faPencil}
            onClick={handleEdit}
            data-testid="editField"
          />
        )}
      </InputFieldWrapper>
    </InputWrapper>
  )
}
