import { useTranslation } from 'react-i18next'
import {
  GenericBadgeStatus,
  StatusBadge
} from '../../SharedIntegrationComponents/StatusBadge/StatusBadge'

export const ClinicManagedStatusBadge = ({
  assignedPatientId,
  ignored
}: {
  assignedPatientId: string | null
  ignored: boolean
}) => {
  const { t } = useTranslation()
  const getClinicManagedStatusBadgeConfig = () => {
    if (ignored) {
      return { text: t('ignored'), status: GenericBadgeStatus.neutral }
    } else if (assignedPatientId === null) {
      return { text: t('no link'), status: GenericBadgeStatus.default }
    } else {
      return { text: t('linked'), status: GenericBadgeStatus.ok }
    }
  }
  const config = getClinicManagedStatusBadgeConfig()
  return <StatusBadge text={config.text} status={config.status} />
}
