import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { AxiosRequestConfig } from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getProviderPatients } from '../../../../../../core/api/get-provider-patients'
import { PaginatedResponse } from '../../../../../../core/api/pagination'
import { useSiteSettingsContext } from '../../../../../../core/contexts/site-settings-context'
import dayjs from '../../../../../../core/dayjs/dayjs'
import { useDataTableParams } from '../../../../../../hooks/useDataTableParams'
import { useFetch } from '../../../../../../hooks/useFetch'
import { useFiltersCount } from '../../../../../../hooks/useFiltersCount'
import { ErrorBoundary } from '../../../../../ErrorBoundary/ErrorBoundary'
import {
  DataFilter,
  DataFilterType
} from '../../../../../TableComponents/Filters/DataFilter/DataFilter'
import { FiltersManager } from '../../../../../TableComponents/Filters/DataFilter/FiltersManager'
import { FilterFunctionButton } from '../../../../../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { TableLayout } from '../../../../../TableComponents/TableLayout/TableLayout'
import { TableSearchBar } from '../../../../../TableComponents/TableSearchBar/TableSearchBar'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  StickyTable,
  TableHeader,
  TableWrapper
} from '../../../../../TableComponents/TableStyledElements/TableStyledElements'
import { getProviderPlatformTitle } from '../../../../SharedIntegrationFunctions/getProviderPlatformTitle'
import { LinkPatientButton } from '../../LinkPatientButton/LinkPatientButton'
import { MRNField } from '../../MRNField/MRNField'
import { NameField } from '../../NameField/NameField'
import { ProviderPatientsContext } from '../../ProviderPatientsContext/ProviderPatientsContext'
import {
  ClinicManagedCloudPatientStatus,
  ClinicManagedProviderPatientProviderType,
  Filter,
  ProviderManagementTypes,
  ProviderPatient,
  ProviderPatientsFilterTypes,
  ProviderPatientsTableType
} from '../../ProviderPatientsInterfaces'
import { ProviderPatientsOptions } from '../../ProviderPatientsOptions/ProviderPatientsOptions'
const Title = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  font-family: inter;
  color: var(--text-primary);
`

interface ProviderPatientsIndividualTableProps {
  defaultFilters: { [key: string]: string | string[] | undefined }
}
export const ProviderPatientsMappingTable = ({
  defaultFilters
}: ProviderPatientsIndividualTableProps) => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)
  const { siteSettings } = useSiteSettingsContext()
  const {
    tableParams,
    requestParams,
    setSearchString,
    setPagination,
    setFilters
  } = useDataTableParams<ProviderPatientsFilterTypes>({
    params: {
      searchString: ''
    },
    filters: defaultFilters
  })
  const filtersCount = useFiltersCount(tableParams.filters)

  const fetchFunction = useCallback(
    (options: AxiosRequestConfig) =>
      getProviderPatients(
        requestParams,
        options,
        ProviderManagementTypes.clinicmanaged
      ),
    [requestParams]
  )
  const { response, error, status, refresh } =
    useFetch<PaginatedResponse<ProviderPatient>>(fetchFunction)

  const columns: ColumnDef<ProviderPatient>[] = useMemo(
    () => [
      {
        id: 'status',
        header: t('provider_patient_table_title_status'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) => (
          <LinkPatientButton patient={row.original} refresh={refresh} />
        )
      },
      {
        id: 'local_name',
        header: t('provider_patient_table_title_local_name'),
        sortDescFirst: true,
        accessorFn: true,
        cell: ({ row }: { row: Row<ProviderPatient> }) => (
          <NameField row={row.original} />
        )
      },
      {
        id: 'local_mrn',
        header: t(
          `${siteSettings?.mrnType}.provider_patient_table_title_local_mrn`
        ),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) => (
          <MRNField row={row.original} />
        )
      },
      {
        id: 'provider_name',
        header: t('provider_patient_table_title_provider_name'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) => row.original.name
      },
      {
        id: 'provider_birthday',
        header: t('provider_patient_table_title_provider_birthday'),
        enableSorting: false,
        accessorFn: (row: ProviderPatient) => row.birthday
      },
      {
        id: 'provider_email',
        header: t('provider_patient_table_title_provider_email'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) =>
          row.original.email ? row.original.email : '-'
      },
      {
        id: 'provider_cloud',
        header: t('provider_patient_table_title_provider_cloud'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) =>
          getProviderPlatformTitle(row.original.providerType)
      },
      {
        id: 'latest_local_data',
        header: t('provider_patient_table_title_latest_local_data'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) =>
          row.original.newestLocalData
            ? dayjs(row.original.newestLocalData).format('DD/MM/YYYY HH:mm')
            : '-'
      },
      {
        id: 'latest_provider_data',
        header: t('provider_patient_table_title_latest_provider_data'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) =>
          row.original.newestProviderData
            ? dayjs(row.original.newestProviderData).format('DD/MM/YYYY HH:mm')
            : '-'
      },
      {
        id: 'options',
        cell: ({ row }: { row: Row<ProviderPatient> }) => (
          <ProviderPatientsOptions row={row.original} />
        )
      }
    ],
    [refresh, siteSettings?.mrnType, t]
  )

  const table = useReactTable({
    data: response?.data || [],
    columns,
    rowCount: response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: tableParams.pagination,
      sorting: [{ id: 'local_name', desc: false }]
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: () => ({}),
    getRowId: (originalRow) => originalRow.id
  })

  function handleFilterExpand() {
    setShowFilters((isVisible) => !isVisible)
  }

  return (
    <>
      <ErrorBoundary>
        <TableWrapper>
          <TableHeader>
            <HeaderTopSection>
              <Title>{t('patient_mapping_table_title')}</Title>
              <HeaderTopSectionButtons>
                <TableSearchBar handleChange={setSearchString} />
                <FilterFunctionButton
                  filtersCount={filtersCount}
                  handleClick={handleFilterExpand}
                  icon={faBarsFilter}
                  text={t('Filters')}
                />
              </HeaderTopSectionButtons>
            </HeaderTopSection>
            {showFilters && (
              <FiltersManager>
                <DataFilter
                  title={t('provider_patients_filter_title_status')}
                  slug={Filter.patientMappingState}
                  type={DataFilterType.multiSelect}
                  data={Object.values(ClinicManagedCloudPatientStatus)}
                  initialState={defaultFilters[Filter.patientMappingState]}
                  currentState={tableParams.filters[Filter.patientMappingState]}
                  setSelected={(filterValue) =>
                    setFilters(Filter.patientMappingState, filterValue)
                  }
                />
                <DataFilter
                  title={t('provider_patients_filter_title_provider_cloud')}
                  slug={Filter.providerTypes}
                  type={DataFilterType.multiSelect}
                  data={Object.values(ClinicManagedProviderPatientProviderType)}
                  initialState={defaultFilters[Filter.providerTypes]}
                  currentState={tableParams.filters[Filter.providerTypes]}
                  setSelected={(filterValue) =>
                    setFilters(Filter.providerTypes, filterValue)
                  }
                />
              </FiltersManager>
            )}
          </TableHeader>
          <TableLayout
            table={table}
            context={
              <ProviderPatientsContext
                tableType={ProviderPatientsTableType.patientMapping}
              />
            }
            fetchError={error}
            fetchStatus={status}
            usesPagination={true}
            WrapperElement={StickyTable}
          />
        </TableWrapper>
      </ErrorBoundary>
    </>
  )
}
