import { faCircleSmall, faHouseMedical } from '@fortawesome/pro-solid-svg-icons'
import {
  Icon,
  Seperator,
  Text,
  Wrapper
} from '../../../../../components/UploadActivity/UploadContext/UploadContext'
import { UploaderContext } from '../../../constants/constants'

interface TicketUploaderContextProps {
  context: UploaderContext
}
export const TicketUploaderContext = ({
  context
}: TicketUploaderContextProps) => {
  return (
    <Wrapper data-testid="TicketUploaderContextWrapper">
      <Icon icon={faHouseMedical}></Icon>
      <Text></Text>
      <Seperator icon={faCircleSmall}></Seperator>
      <Text></Text>
      <Seperator icon={faCircleSmall}></Seperator>
    </Wrapper>
  )
}
