export const checkIfFileExists = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    const chunkSize = 1 // Read the first 1B of the file
    fileReader.onload = function (e: any) {
      resolve(true)
    }
    fileReader.onerror = function () {
      resolve(false)
    }
    const blob = file.slice(0, chunkSize)
    fileReader.readAsArrayBuffer(blob)
  })
}
