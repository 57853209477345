import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FileInput } from '../../../../../components/UploadData/UploadDataStyledComponents'
import { supportedFileTypes } from '../../../../../constants/supportedFileTypes'
import { InputTitle, InputWrapper } from '../../TicketCreation'

const maxFileSize = 50000000
const FileUploader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SupportedFormatsText = styled.span`
  font-family: inter;
  font-size: 0.75rem;
  color: var(--brand-primary-color);
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;
`
const FileDragAndDropWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
`

const FileDragAndDropBox = styled.div`
  border-radius: 0.5rem;
  height: 3rem;
  box-sizing: border-box;
  border: 0.0625rem dashed var(--text-lightest);
  background: var(--element-bg-80);
  padding: 1rem;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--text-lighter);
  width: 28.125rem;
`

const FileDragAndDropButton = styled.div`
  padding: 0.75rem;
  height: 3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--text-primary);
  border-radius: 8px;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--element-bg);
  backdrop-filter: blur(0.5rem);
  align-self: stretch;
  width: 11.25rem;
  cursor: pointer;
  &:hover {
    background: var(--element-bg-dark);
  }
`
interface TicketAttachmentUploaderProps {
  attachments: File[] | null
  setAttachments: Dispatch<SetStateAction<File[] | null>>
  setShowSupportedFormats: Dispatch<SetStateAction<boolean>>
  inputRef: MutableRefObject<HTMLInputElement | null>
}
export const TicketAttachmentUploader = ({
  attachments,
  setAttachments,
  setShowSupportedFormats,
  inputRef
}: TicketAttachmentUploaderProps) => {
  const [dragActive, setDragActive] = useState(false)
  const [uploadError, setUploadError] = useState<Error | null>(null)
  const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null)

  const { t } = useTranslation()
  const handleFile = (files: FileList) => {
    const fileList = Array.from(attachments ?? [])
    // check for duplicate file
    if (
      fileList.find(
        (file) =>
          file.size === files[0].size &&
          file.name === files[0].name &&
          file.type === files[0].type
      ) === undefined
    ) {
      let currentSize = files[0].size
      // check if files are more than 50 MB combined
      if (fileList.length > 0) {
        currentSize += fileList.reduce((n, file) => n + file.size, 0)
      }
      if (
        currentSize < maxFileSize &&
        supportedFileTypes.includes(files[0].type)
      ) {
        fileList.push(files[0])
        setAttachments(fileList)
      }
    }
  }
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setUploadError(null)
    setUploadSuccess(null)
    setDragActive(e.type === 'dragenter' || e.type === 'dragover')
  }

  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files)
    }
  }

  const handleChange = function (e: any) {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files)
    }
  }

  const onButtonClick = () => {
    if (inputRef.current) inputRef.current.click()
  }
  return (
    <>
      <FileInput
        data-testid="TicketCreationFileInput"
        ref={inputRef}
        type="file"
        multiple={false}
        onClick={() => {
          setUploadError(null)
          setUploadSuccess(null)
        }}
        onChange={(event) => handleChange(event)}
        accept={supportedFileTypes.join(',')}
      />
      <FileUploader
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={(event) => handleDrop(event)}
        data-testid="TicketCreationDragInput"
      >
        <InputWrapper>
          <TitleWrapper>
            <InputTitle>{t('ticket_creation_file_upload_title')}</InputTitle>
            <SupportedFormatsText
              onClick={() => setShowSupportedFormats(true)}
              data-testid="show_supported_formats_modal"
            >
              {t('supported_formats_title')}
            </SupportedFormatsText>
          </TitleWrapper>
          <FileDragAndDropWrapper>
            <FileDragAndDropBox>
              {dragActive
                ? t('ticket_creation_file_upload_dragover_placeholder')
                : t('ticket_creation_file_upload_placeholder')}
            </FileDragAndDropBox>
            <FileDragAndDropButton onClick={onButtonClick}>
              {t('ticket_creation_file_upload_button')}
            </FileDragAndDropButton>
          </FileDragAndDropWrapper>
        </InputWrapper>
      </FileUploader>
    </>
  )
}
