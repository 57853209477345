import { SortEnum } from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { PopulationOverview } from '../../containers/ClinicPatients/tables/interfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getPopulations(
  order: SortEnum
): Promise<PopulationOverview[]> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/population/populations?order=${order}`
  )
  return response.data
}
