import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortEnum } from '../../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { getPatientEventLogs } from '../../../core/api/patient-event-logs'
import { PatientLogSearchResult } from '../interfaces'

export interface UseEventsProps {
  patientId: string | undefined
  size: number
  order: SortEnum
  update: boolean
}
export const useEvents = ({
  patientId,
  size,
  order,
  update
}: UseEventsProps) => {
  const [events, setEvents] = useState<PatientLogSearchResult | null>(null)
  const [eventsError, setEventsError] = useState<Error | null>(null)
  const { t } = useTranslation()
  useEffect(() => {
    setEvents(null)
    setEventsError(null)
    if (patientId === undefined) {
      setEventsError(new Error(t('No Patient')))
      return
    }
    const handleGetEvents = async () => {
      try {
        const allEvents = await getPatientEventLogs({ patientId, size, order })
        setEvents(allEvents)
      } catch (error) {
        setEventsError(new Error(t('Error getting events')))
      }
    }
    handleGetEvents()
  }, [patientId, size, order, t, update])
  return {
    events,
    eventsError
  }
}
