import config from '../config'
import { AxiosRequest } from './axios-request'

export enum LogLevel {
  error = 'error',
  warn = 'warn',
  info = 'info',
  debug = 'debug',
  trace = 'trace'
}
export type CmiPatientLogNames = `${LogLevel}` | 'all'

export interface CMIPatientLog {
  time: string
  level: LogLevel
  message: string
  stackTrace: string | null
}
export interface CMIPatientLogDTO {
  logs: Array<CMIPatientLog>
  logTypes: {
    [key in CmiPatientLogNames]: number | null
  }
}

export async function getLatestAPIResponseFromPatientManagedProviderPatient(
  providerPatientId: string,
  options: object = {}
): Promise<CMIPatientLogDTO> {
  const response = await AxiosRequest.create().get(
    config.API_URL + `/cmi/patients/${providerPatientId}/logs`,
    options
  )
  return response.data as CMIPatientLogDTO
}
