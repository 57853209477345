import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75rem;
  background-color: var(--yellow-12);
  border-radius: 0.5rem;
  box-sizing: border-box;
`

const StyledIcon = styled(FontAwesomeIcon)`
  color: var(--yellow);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.25rem;
`

const Text = styled.span`
  width: 100%;
  height: auto;
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  white-space: pre-line;
`
interface ErrorComponentProps {
  content: string
  testId?: string
}

export const ErrorComponent = ({ content, testId }: ErrorComponentProps) => {
  return (
    <Container data-testid={testId}>
      <StyledIcon icon={faExclamationTriangle} />
      <Text>{content}</Text>
    </Container>
  )
}
