import {
  faCalendarCircleUser as faCalendarCircleUserRegular,
  faHospital as faHospitalRegular,
  faPeopleArrows as faPeopleArrowsRegular,
  faTriangleExclamation as faTriangleExclamationRegular
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCalendarCircleUser as faCalendarCircleUserSolid,
  faHospital as faHospitalSolid,
  faPeopleArrows as faPeopleArrowsSolid,
  faTriangleExclamation as faTriangleExclamationSolid
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import {
  ProviderManagementTypes,
  ProviderPatientProviderType
} from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { getProviderPlatformTitle } from '../../components/IntegrationComponents/SharedIntegrationFunctions/getProviderPlatformTitle'
import {
  ItemObject,
  SideMenuComponent
} from '../../components/SideMenuComponent/SideMenuComponent'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { getProvider } from '../../core/api/get-provider'
import { PaginatedResponse } from '../../core/api/pagination'
import { useClinicViewsContext } from '../../core/contexts/clinic-view-context'
import { ClinicViewDisplays } from '../../core/entities/clinic.entity'
import { FetchStatus, useFetch } from '../../hooks/useFetch'

export enum ProviderErrorType {
  email = 'email',
  login = 'login',
  requestpatients = 'requestpatients',
  requestdata = 'requestdata'
}
export enum CMIIntegrationState {
  initial = 'initial',
  verificationFailed = 'verificationFailed',
  idle = 'idle',
  verifying = 'verifying',
  running = 'running',
  failed = 'failed',
  failing = 'failing'
}
export interface CMIIntegrationValue {
  id: string
  alias: string
  type: ProviderPatientProviderType
  managedBy: ProviderManagementTypes
  latestRun: Date | null
  state: CMIIntegrationState
  username: string
  email: string
  emailHost: string
  clientId: string
}
const ClinicIntegrationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
`
const PageWrapper = styled.div`
  width: 100%;
  margin-right: 2rem;
`

export const ClinicIntegrations = () => {
  const { t } = useTranslation()
  const clinicViews = useClinicViewsContext()
  const { response, error, status, refresh } =
    useFetch<PaginatedResponse<CMIIntegrationValue>>(getProvider)

  if (status === FetchStatus.Error) {
    return (
      <div data-testid="clinicIntegrationsError">
        <p>{t('Error')}</p>
      </div>
    )
  }
  if (status === FetchStatus.Loading)
    return (
      <div data-testid="clinicIntegrationsLoading">
        <Spinner spinnersize={spinnerSize.large} />
      </div>
    )
  else {
    const data: Array<ItemObject> = [
      {
        icon: {
          regular: faCalendarCircleUserRegular,
          solid: faCalendarCircleUserSolid
        },
        alias: t('clinic_submenu_consultations_title'),
        info: '',
        to: 'consultations',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.LibreViewConsultations
        )
      },
      {
        icon: {
          regular: faTriangleExclamationRegular,
          solid: faTriangleExclamationSolid
        },
        alias: t(
          'clinic_integrations_submenu_title_patient_integration_warnings'
        ),
        info: '',
        to: 'patient-integration-warnings',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.IntegrationTODO
        )
      },
      {
        icon: {
          regular: faPeopleArrowsRegular,
          solid: faPeopleArrowsSolid
        },
        alias: t('clinic_integrations_submenu_title_patient_mapping'),
        info: '',
        to: 'patient-mapping',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.IntegrationTODO
        )
      },
      {
        icon: { regular: faHospitalRegular, solid: faHospitalSolid },
        alias: t('clinic_integrations_sub_menu_title'),
        info: '',
        to: 'clinic-integrations',
        show: clinicViews.Integrations.subViews.includes(
          ClinicViewDisplays.ProviderIntegrations
        ),
        children: response?.data.map((provider: CMIIntegrationValue) => {
          return {
            alias: provider.alias,
            info: getProviderPlatformTitle(provider.type) ?? '',
            to: `clinic-integrations/${provider.id}`,
            show: true
          }
        })
      }
    ]

    return (
      <ClinicIntegrationsWrapper data-testid="ClinicIntegrations">
        <SideMenuComponent items={data} />
        <PageWrapper>
          <Outlet
            context={{
              response,
              error,
              status,
              refresh
            }}
          />
        </PageWrapper>
      </ClinicIntegrationsWrapper>
    )
  }
}
