import { PaginationState, SortingState, Updater } from '@tanstack/react-table'
import { useCallback, useMemo, useReducer } from 'react'
import { SelectedType } from './useDataFilter'

export type FiltersState = { [key: string]: SelectedType }

enum ParamsActionType {
  SetFilters,
  SetSorting,
  SetPagination,
  SetParam
}

interface ParamsState<ParamsType> {
  filters: any
  sorting: SortingState | undefined
  pagination: PaginationState | undefined
  params: ParamsType
}

// TODO - define the type of the request params
type RequestParamsState = any

type ParamsAction =
  | {
      type: ParamsActionType.SetFilters
      payload: {
        filterName: string
        filters: SelectedType
      }
    }
  | { type: ParamsActionType.SetSorting; payload: SortingState }
  | { type: ParamsActionType.SetPagination; payload: PaginationState }
  | {
      type: ParamsActionType.SetParam
      payload: {
        key: string
        value: any
      }
    }

interface HookReturn<T> {
  tableParams: ParamsState<T>
  requestParams: RequestParamsState
  setSearchString: (value: string) => void
  setParam: (paramKey: string, paramValue: string) => void
  setFilters: (filterName: string, filters: SelectedType) => void
  setSorting: (
    sorting: SortingState | ((prevSorting: SortingState) => SortingState)
  ) => void
  setPagination: (
    pagination:
      | PaginationState
      | ((prevPagination: PaginationState) => PaginationState)
  ) => void
}

interface ParamOptions {
  usePagination?: boolean
  searchKey?: string
}

interface InitialValuesType<T> {
  filters?: FiltersState
  sorting?: SortingState
  pagination?: PaginationState
  params: T
}

const DEFAULT_PAGE_SIZE = 20

export function useDataTableParams<ParamsType>(
  initialValues: InitialValuesType<ParamsType> = {
    params: {} as ParamsType
  },
  options: ParamOptions = {
    usePagination: true,
    searchKey: 'searchString'
  }
): HookReturn<ParamsType> {
  const [tableParams, dispatchTableParams] = useReducer<
    React.Reducer<ParamsState<ParamsType>, ParamsAction>
  >(reducer, {
    filters: initialValues.filters ?? {},
    sorting: initialValues.sorting ?? undefined,
    pagination:
      options?.usePagination || options?.usePagination === undefined
        ? {
            pageIndex: 0,
            pageSize: DEFAULT_PAGE_SIZE
          }
        : undefined,
    params: initialValues.params
  })

  const requestParams = useMemo(() => {
    let pagination = {}
    if (tableParams.pagination) {
      pagination = {
        page: tableParams.pagination.pageIndex + 1,
        resultsPerPage: tableParams.pagination.pageSize
      }
    }
    return {
      ...tableParams.filters,
      ...tableParams.params,
      ...pagination,
      sorting: tableParams.sorting
    }
  }, [tableParams])

  const setPagination = useCallback(
    (pagination: PaginationState | Updater<PaginationState>) => {
      if (typeof pagination === 'function') {
        dispatchTableParams({
          type: ParamsActionType.SetPagination,
          payload: pagination(tableParams.pagination as PaginationState)
        })
      } else {
        dispatchTableParams({
          type: ParamsActionType.SetPagination,
          payload: pagination
        })
      }
    },
    [tableParams.pagination]
  )

  const setParam = useCallback((key: string, value: string) => {
    dispatchTableParams({
      type: ParamsActionType.SetParam,
      payload: { key, value }
    })
  }, [])

  const setSearchString = useCallback(
    (value: string) => {
      dispatchTableParams({
        type: ParamsActionType.SetParam,
        payload: {
          key: options.searchKey as string,
          value
        }
      })
    },
    [options.searchKey]
  )

  const setFilters = useCallback(
    (filterName: string, filters: SelectedType) => {
      dispatchTableParams({
        type: ParamsActionType.SetFilters,
        payload: {
          filterName,
          filters
        }
      })
    },
    []
  )

  const setSorting = useCallback(
    (sorting: SortingState | ((prevSorting: SortingState) => SortingState)) => {
      if (typeof sorting === 'function') {
        dispatchTableParams({
          type: ParamsActionType.SetSorting,
          payload: sorting(tableParams.sorting as SortingState)
        })
      } else {
        dispatchTableParams({
          type: ParamsActionType.SetSorting,
          payload: sorting
        })
      }
    },
    [tableParams.sorting]
  )

  return {
    tableParams,
    requestParams,
    setParam,
    setSearchString,
    setPagination,
    setFilters,
    setSorting
  }
}

function reducer<ParamsType>(
  state: ParamsState<ParamsType>,
  action: ParamsAction
): ParamsState<ParamsType> {
  switch (action.type) {
    case ParamsActionType.SetFilters:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: action.payload.filters
        },
        pagination: state.pagination
          ? { ...state.pagination, pageIndex: 0 }
          : state.pagination
      }
    case ParamsActionType.SetSorting:
      return {
        ...state,
        sorting: action.payload,
        pagination: state.pagination
          ? { ...state.pagination, pageIndex: 0 }
          : state.pagination
      }
    case ParamsActionType.SetPagination:
      if (state.pagination !== undefined) {
        return {
          ...state,
          pagination: action.payload
        }
      }
      return state
    case ParamsActionType.SetParam:
      return {
        ...state,
        params: {
          ...state.params,
          [action.payload.key]: action.payload.value
        },
        pagination: state.pagination
          ? { ...state.pagination, pageIndex: 0 }
          : state.pagination
      }
    default:
      return state
  }
}
