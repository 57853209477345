import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TicketBadgeSize, TicketStatus } from '../../constants/constants'

const Wrapper = styled.div<{
  $config: {
    color: string
    backgroundColor: string
    text: string
  }
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: ${(props) => props.$config.color};
`
const WrapperWithBackground = styled(Wrapper)<{
  $config: {
    color: string
    backgroundColor: string
    text: string
  }
}>`
  height: 2rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${(props) => props.$config.color};
  background: ${(props) => props.$config.backgroundColor};
  color: ${(props) => props.$config.color};
  box-sizing: border-box;
`
const Text = styled.span<{ $size: TicketBadgeSize }>`
  color: inherit;
  font-family: inter;
  font-size: ${(props) =>
    props.$size === TicketBadgeSize.large ? '1rem' : '0.75rem'};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: ${(props) =>
    props.$size === TicketBadgeSize.large ? 'capitalize' : 'uppercase'};
`

const getConfig = (status: TicketStatus) => {
  switch (status) {
    case TicketStatus.Open:
      return {
        color: 'var(--blue-darker)',
        backgroundColor: 'var(--blue-gradient)',
        text: 'status_badge_open_text'
      }
    case TicketStatus.Archived:
      return {
        color: 'var(--text-lighter)',
        backgroundColor: 'var(--white-color)',
        text: 'status_badge_archived_text'
      }
  }
}
interface TicketStatusBadgeProps {
  status: TicketStatus
  withBackground: boolean
  size: TicketBadgeSize
}
export const TicketStatusBadge = ({
  status,
  withBackground,
  size
}: TicketStatusBadgeProps) => {
  const { t } = useTranslation()
  const config = getConfig(status)

  return (
    <>
      {withBackground ? (
        <WrapperWithBackground
          $config={config}
          data-testid="TicketStatusBadgeWithBackground"
        >
          <Text data-testid="TicketStatusBadgeText" $size={size}>
            {t(config.text)}
          </Text>
        </WrapperWithBackground>
      ) : (
        <Wrapper
          $config={config}
          data-testid="TicketStatusBadgeWithoutBackground"
        >
          <Text data-testid="TicketStatusBadgeText" $size={size}>
            {t(config.text)}
          </Text>
        </Wrapper>
      )}
    </>
  )
}
