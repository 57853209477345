import { faBrowser, faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import {
  Icon,
  Seperator,
  Text,
  Wrapper
} from '../../../../../components/UploadActivity/UploadContext/UploadContext'
import { PortalContext } from '../../../constants/constants'
interface TicketPortalContextProps {
  context: PortalContext
}
export const TicketPortalContext = ({ context }: TicketPortalContextProps) => {
  return (
    <Wrapper data-testid="TicketPortalContextWrapper">
      <Icon icon={faBrowser}></Icon>
      <Text>{context.type}</Text>
      <Seperator icon={faCircleSmall}></Seperator>
      <Text>{context.releaseVersion}</Text>
      <Seperator icon={faCircleSmall}></Seperator>
      <Text>
        {context.browser.type}({context.browser.version})
      </Text>
    </Wrapper>
  )
}
