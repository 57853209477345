import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import styled from 'styled-components'
import { CMIIntegrationValue } from '../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { useSessionContext } from '../../../../core/contexts/session-context'
import dayjs from '../../../../core/dayjs/dayjs'
import { UserType } from '../../../../core/entities/user.entity'
import { ErrorBoundary } from '../../../ErrorBoundary/ErrorBoundary'
import { Modal } from '../../../Modal/Modal'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  StickyTable,
  TableHeader,
  TableWrapper
} from '../../../TableComponents/TableStyledElements/TableStyledElements'
import { ProviderManagementTypes } from '../ProviderPatients/ProviderPatientsInterfaces'

import { PaginatedResponse } from '../../../../core/api/pagination'
import { FetchStatus } from '../../../../hooks/useFetch'
import { TableLayout } from '../../../TableComponents/TableLayout/TableLayout'
import { getProviderPlatformTitle } from '../../SharedIntegrationFunctions/getProviderPlatformTitle'
import { ProviderIntegrationModel } from '../ProviderIntegrationModel/ProviderIntegrationModel'
import { ClinicIntegrationStatusBadge } from '../StatusBadges/ClinicIntegrationStatusBadge'
import { ActionButtons } from './ActionButtons/ActionButtons'

const Title = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  font-family: inter;
  color: var(--text-primary);
`
export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background-color: var(--blue);
  cursor: pointer;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  color: var(--white-color);
  gap: 0.5rem;
  border: none;
`
export const AddIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
  background-color: inherit;
  line-height: 1rem;
`
const AddText = styled.span`
  font-family: inter;
  font-weight: 600;
  line-height: 1rem;
  font-size: 0.875rem;
  color: inherit;
`
const DataTableWrapper = styled(StickyTable)`
  tr {
    th:first-child,
    td:first-child {
      width: 13.5625rem;
    }
    th:nth-child(6),
    td:nth-child(6) {
      width: 16.5625rem;
    }
  }
`
export const ClinicIntegrationsTable = () => {
  const outletContext = useOutletContext<
    | {
        response: PaginatedResponse<CMIIntegrationValue> | null
        error: Error | null
        status: FetchStatus.Idle | FetchStatus.Success
        refresh: () => void
      }
    | undefined
  >()
  const context = outletContext
  const { t } = useTranslation()
  const { user } = useSessionContext()
  const [showProviderSetup, setShowProviderSetup] = useState<boolean>(false)
  const [provider, setProvider] = useState<CMIIntegrationValue | null>(null)

  const editProvider = (selectedProvider: CMIIntegrationValue) => {
    setShowProviderSetup(true)
    setProvider(selectedProvider)
  }

  const columns: ColumnDef<CMIIntegrationValue>[] = useMemo(
    () => [
      {
        id: 'status',
        header: t('clinic_integrations_table_title_status'),
        sortDescFirst: true,
        cell: ({ row }: { row: Row<CMIIntegrationValue> }) => (
          <ClinicIntegrationStatusBadge
            state={row.original.state}
          ></ClinicIntegrationStatusBadge>
        )
      },
      {
        id: 'alias',
        header: t('clinic_integrations_table_title_alias'),
        enableSorting: true,
        accessorFn: (row: CMIIntegrationValue) => row.alias
      },
      {
        id: 'type',
        header: t('clinic_integrations_table_title_type'),
        enableSorting: false,
        cell: ({ row }: { row: Row<CMIIntegrationValue> }) =>
          getProviderPlatformTitle(row.original.type)
      },
      {
        id: 'managed_by',
        header: t('clinic_integrations_table_title_managed_by'),
        enableSorting: false,
        cell: ({ row }: { row: Row<CMIIntegrationValue> }) =>
          row.original.managedBy === ProviderManagementTypes.clinicmanaged
            ? t('clinic_integrations_table_clinicmanaged_text')
            : t('clinic_integrations_table_patientmanaged_text')
      },
      {
        id: 'latest_run',
        header: t('clinic_integrations_table_title_latest_run'),
        enableSorting: false,
        accessorFn: (row: CMIIntegrationValue) =>
          row.latestRun ? dayjs(row.latestRun).fromNow() : '-'
      },
      {
        id: 'actions',
        header: t('clinic_integrations_table_title_actions'),
        enableSorting: false,
        cell: ({ row }: { row: Row<CMIIntegrationValue> }) => (
          <ActionButtons
            rowValue={row.original}
            refresh={context?.refresh}
            editProvider={editProvider}
          />
        )
      }
    ],
    [context?.refresh, t]
  )

  const table = useReactTable({
    data: context?.response?.data || [],
    columns,
    rowCount: context?.response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      sorting: [{ id: 'alias', desc: false }]
    },
    onSortingChange: () => ({}),
    getRowId: (originalRow) => originalRow.id
  })

  const handleShowProviderSetup = (
    providerProp: CMIIntegrationValue | null
  ) => {
    setProvider(providerProp)
    setShowProviderSetup(!showProviderSetup)
  }

  return (
    <>
      <Modal isOpen={showProviderSetup}>
        <ProviderIntegrationModel
          handleShowProviderSetup={() => setShowProviderSetup(false)}
          provider={provider}
          refreshProviders={context?.refresh ?? (() => null)}
        />
      </Modal>
      <ErrorBoundary>
        <TableWrapper>
          <TableHeader>
            <HeaderTopSection>
              <Title>{t('clinic_integrations_table_title')}</Title>
              <HeaderTopSectionButtons>
                {user?.type === UserType.HCPAdmin && (
                  <AddButton
                    data-testid="AddNewProviderIntegration"
                    onClick={() => handleShowProviderSetup(null)}
                  >
                    <AddIcon icon={faPlus} />
                    <AddText>{t('add_integration_button_text')}</AddText>
                  </AddButton>
                )}
              </HeaderTopSectionButtons>
            </HeaderTopSection>
          </TableHeader>
          <TableLayout
            table={table}
            context={''}
            fetchError={context?.error}
            fetchStatus={context?.status ?? FetchStatus.Idle}
            usesPagination={false}
            WrapperElement={DataTableWrapper}
          />
        </TableWrapper>
      </ErrorBoundary>
    </>
  )
}
