import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ProviderPatientsTableType } from '../ProviderPatientsInterfaces'

const InfoContext = styled.div`
  background: var(--element-bg);
  border: 0;
  border-radius: 0.5rem;
  padding: 0.75rem;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
`

const Icon = styled(FontAwesomeIcon)`
  display: inline-block;
  margin-right: 0.375rem;
  color: var(--blue);
`
interface ProviderPatientsContextProps {
  tableType: ProviderPatientsTableType
}
export function ProviderPatientsContext({
  tableType
}: ProviderPatientsContextProps) {
  const { t } = useTranslation()

  const getTranslationKey = () => {
    switch (tableType) {
      case ProviderPatientsTableType.individual:
        return 'provider_patients_individual_table_context'
      case ProviderPatientsTableType.patientIntegrationsWarnings:
        return 'provider_patients_integration_warnings_table_context'
      case ProviderPatientsTableType.patientMapping:
        return 'provider_patients_mapping_table_context'
    }
  }
  return (
    <InfoContext>
      <Icon icon={faInfoCircle} />
      {t(getTranslationKey())}
    </InfoContext>
  )
}
