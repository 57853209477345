import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IFU from '../../../assets/IFU.svg'
import ManufacturerImageWhite from '../../../assets/ManufacturerImageWhite.svg'
import MDName from '../../../assets/MedicalDeviceName.svg'
import UDI from '../../../assets/UDI.svg'
import CE from '../../../assets/ce.svg'
import { Body } from '../../../containers/PatientInfo/PatientInfo'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import { DeploymentType } from '../../../core/entities/siteSettings.entity'
import { IconWrapper } from '../../AddressComponent/AddressComponent'
import { IFUPdfDownloader } from '../../IFUPdfDownloader/IFUPdfDownloader'
import { MarkDownComponent } from '../../MarkDownComponent/MarkDownComponent'
import { MarkDownStylingModels } from '../../MarkDownComponent/interfaces/interfaces'
import { AboutConfig } from '../About/About'
import {
  IfuWarningComponent,
  getDeploymentTypeShortText
} from '../IfuWarningComponent/IfuWarningComponent'
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
`
const Title = styled.span`
  color: var(--text-primary);
  font-size: 0.875rem;
  font-weight: 700;
  font-family: inter;
  user-select: text;
`
const Icon = styled.img`
  width: 2rem;
  height: 2rem;
`
const FontIcon = styled(FontAwesomeIcon)`
  font-size: 1.375rem;
  color: var(--text-primary);
  font-weight: 300;
`
export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`
export const SubBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  white-space: nowrap;
  align-self: stretch;
  margin-left: 2.75rem;
`
export const SubBodyEntry = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
`
export const SubBodyEntryName = styled.span`
  color: var(--text-lighter);
  font-weight: 600;
  font-size: 0.875rem;
  font-family: inter;
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 2rem;
  height: 2rem;
`

const TitleMarkDownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
interface TitleComponentProps {
  images: Array<string | IconDefinition> | undefined
  title: string
  markDown: null | string
  uncenterImage: boolean
  showTitle?: boolean
}
export const TitleComponent = ({
  images,
  title,
  markDown,
  uncenterImage,
  showTitle
}: TitleComponentProps) => {
  return (
    <TitleWrapper style={uncenterImage ? { alignItems: 'flex-start' } : {}}>
      <IconContainer>
        {images !== undefined &&
          images.map((image, index) => (
            <IconWrapper key={`image${index}`} $index={index}>
              {typeof image === 'string' ? (
                <Icon src={image}></Icon>
              ) : (
                <FontIcon
                  icon={image}
                  style={
                    showTitle === true
                      ? { position: 'absolute', bottom: '0.75rem' }
                      : {}
                  }
                />
              )}
            </IconWrapper>
          ))}
      </IconContainer>
      {showTitle === true && markDown !== null && (
        <TitleMarkDownWrapper>
          <Title>{title}</Title>
          <MarkDownComponent
            stylingModel={MarkDownStylingModels.table}
            text={markDown}
            wrapperStyle={{}}
          />
        </TitleMarkDownWrapper>
      )}
      {!showTitle && markDown !== null && (
        <MarkDownComponent
          stylingModel={MarkDownStylingModels.table}
          text={markDown}
          wrapperStyle={uncenterImage ? { marginTop: '0.5rem' } : {}}
        />
      )}
      {markDown === null && <Title>{title}</Title>}
    </TitleWrapper>
  )
}

interface ProductInformationProps {
  config: AboutConfig
  showFragileInfo: boolean
}
export const ProductInformation = ({
  config,
  showFragileInfo
}: ProductInformationProps) => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  return (
    <>
      <Body data-testid="productInformation" style={{ gap: '1.125rem' }}>
        <TitleComponent
          images={
            siteSettings?.deploymentType === DeploymentType.Production
              ? [CE, MDName]
              : undefined
          }
          title={`Line Portal${
            siteSettings &&
            siteSettings.deploymentType !== DeploymentType.Production
              ? ` (${getDeploymentTypeShortText(siteSettings?.deploymentType)})`
              : ''
          }`}
          markDown={null}
          uncenterImage={false}
        />
        <SubBody>
          <SubBodyEntry>
            <SubBodyEntryName>{t('Release version')}</SubBodyEntryName>
            <Title>{config.releaseVersion}</Title>
          </SubBodyEntry>
          <SubBodyEntry>
            <SubBodyEntryName>{t('Frontend version')}</SubBodyEntryName>
            <Title>{config.frontendVersion}</Title>
          </SubBodyEntry>
          <SubBodyEntry>
            <SubBodyEntryName>{t('Backend version')}</SubBodyEntryName>
            <Title>{config.backendVersion}</Title>
          </SubBodyEntry>
        </SubBody>
      </Body>
      {siteSettings?.deploymentType === DeploymentType.Production && (
        <Body>
          <TitleComponent
            images={[UDI]}
            title={config.productUDI}
            markDown={null}
            uncenterImage={false}
          />
        </Body>
      )}
      <Body>
        <BodyWrapper>
          <TitleComponent
            images={[ManufacturerImageWhite]}
            title={t('Date of manufacture')}
            markDown={null}
            uncenterImage={false}
          />
          <Title>07/02/2025</Title>
        </BodyWrapper>
      </Body>
      {showFragileInfo && (
        <Body data-testid="ProductInfoIFU">
          {siteSettings &&
            siteSettings.deploymentType !== DeploymentType.Production && (
              <IfuWarningComponent
                deploymentType={siteSettings.deploymentType}
              />
            )}
          <BodyWrapper>
            <TitleComponent
              images={[IFU]}
              title={t('Instructions For Use (IFU)')}
              markDown={null}
              uncenterImage={false}
            />
            <IFUPdfDownloader />
          </BodyWrapper>
        </Body>
      )}
    </>
  )
}
