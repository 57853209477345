import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
`

const FileItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 2.25rem;
  width: 100%;
`

const FileName = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--text-primary);
`

const FileActionWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`

const FileSize = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1rem;
  color: var(--text-lighter);
`

const FileAction = styled.div`
  display: flex;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0rem 0.6875rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  color: var(--text-medium);
  &:hover {
    background-color: var(--destructive);
    color: var(--white-color);
  }
`
const FileActionIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  color: inherit;
  cursor: pointer;
`

interface TicketFilesProps {
  attachments: File[] | null
  setAttachments: Dispatch<SetStateAction<File[] | null>>
  inputRef: MutableRefObject<HTMLInputElement | null>
}

const bytesToSize = (bytes: number) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10)
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}
export const TicketAttachments = ({
  attachments,
  setAttachments,
  inputRef
}: TicketFilesProps) => {
  const handleDeleteFile = (index: number) => {
    if (attachments) {
      if (inputRef.current) {
        inputRef.current.value = ''
      }
      const newFiles = attachments.filter((_, i) => i !== index)
      setAttachments(newFiles)
    }
  }
  return (
    <Wrapper>
      {attachments?.map((attachment, index) => (
        <FileItem key={index}>
          <FileName>{attachment.name}</FileName>
          <FileActionWrapper>
            <FileSize>{bytesToSize(attachment.size)}</FileSize>
            <FileAction onClick={() => handleDeleteFile(index)}>
              <FileActionIcon icon={faTrash}></FileActionIcon>
            </FileAction>
          </FileActionWrapper>
        </FileItem>
      ))}
    </Wrapper>
  )
}
