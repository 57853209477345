import {
  faCircleQuestion,
  faHospital,
  faUserCircle,
  faUserMd,
  IconDefinition
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { UserType } from '../../../../core/entities/user.entity'
import { TicketBadgeSize } from '../../constants/constants'

const Wrapper = styled.div<{
  $config: {
    icon: IconDefinition
    color: string
    text: string
  }
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  color: ${(props) => props.$config.color};
`
const Icon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  line-height: 1rem;
  color: inherit;
`

const Text = styled.span<{ $size: TicketBadgeSize }>`
  color: inherit;
  font-family: inter;
  font-size: ${(props) =>
    props.$size === TicketBadgeSize.large ? '1rem' : '0.75rem'};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

interface ReportingUserBadgeProps {
  type: UserType | null
  size: TicketBadgeSize
}

export const ReportingUserBadge = ({ type, size }: ReportingUserBadgeProps) => {
  const { t } = useTranslation()
  const getConfig = () => {
    switch (type) {
      case UserType.Patient:
        return {
          icon: faUserCircle,
          color: 'var(--blue)',
          text: 'ticket_reporting_user_patient_text'
        }
      case UserType.HCP:
        return {
          icon: faUserMd,
          color: 'var(--green)',
          text: 'ticket_reporting_user_hcp_text'
        }
      case UserType.HCPAdmin:
        return {
          icon: faHospital,
          color: 'var(--purple)',
          text: 'ticket_reporting_user_hcp_admin_text'
        }
      default:
        return {
          icon: faCircleQuestion,
          color: 'var(--text-lighter)',
          text: 'ticket_reporting_user_not_authenticated_text'
        }
    }
  }
  const config = getConfig()

  return (
    <Wrapper
      $config={config}
      data-testid={`ReportingUserBadge${type?.toLowerCase() ?? ''}`}
    >
      <Icon icon={config.icon}></Icon>
      <Text data-testid="ReportingUserBadgeText" $size={size}>
        {t(config.text)}
      </Text>
    </Wrapper>
  )
}
