import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLibreConsultations } from '../../core/api/consultations/get-libre-consultations'
import { PaginatedResponse } from '../../core/api/pagination'
import { useFetch } from '../../hooks/useFetch'
import { useFiltersCount } from '../../hooks/useFiltersCount'

import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useDataTableParams } from '../../hooks/useDataTableParams'
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary'
import {
  DataFilter,
  DataFilterType
} from '../TableComponents/Filters/DataFilter/DataFilter'
import { FiltersManager } from '../TableComponents/Filters/DataFilter/FiltersManager'
import { FilterFunctionButton } from '../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { TableLayout } from '../TableComponents/TableLayout/TableLayout'
import { TableSearchBar } from '../TableComponents/TableSearchBar/TableSearchBar'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  StickyTable,
  TableHeader,
  TableWrapper
} from '../TableComponents/TableStyledElements/TableStyledElements'
import { Title } from '../Title/Title'
import {
  ConsultationsData,
  ConsultationsFilter,
  ConsultationTime,
  LatestData,
  PatientAccountLink
} from './constants/constants'
import { ConsultationsAccountLink } from './ConsultationsAccountLink/ConsultationsAccountLink'
import { ConsultationsOptions } from './ConsultationsOptions/ConsultationsOptions'
import {
  ConsultationsSummary,
  ConsultationsSummaryData
} from './ConsultationsSummary/ConsultationsSummary'
import { MrnField } from './ConsultationsTable/MrnField/MrnField'
import { NameField } from './ConsultationsTable/NameField/NameField'
import { ImportConsultations } from './ImportConsultations/ImportConsultations'

const defaults = {
  [ConsultationsFilter.consultationTime]: ConsultationTime.within2days,
  [ConsultationsFilter.latestData]: LatestData.olderthan2days,
  [ConsultationsFilter.patientAccountLink]: PatientAccountLink.libreview
}
const StickyTableWrapper = styled(StickyTable)`
  tr {
    th:first-child,
    td:first-child {
      width: 9.5rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 4.9375rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 14.875rem;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 10.1875rem;
    }
    th:nth-child(5),
    td:nth-child(5) {
      width: 25.125rem;
    }
    th:nth-child(6),
    td:nth-child(6) {
      width: 9.875rem;
    }
    th:nth-child(7),
    td:nth-child(7) {
      width: 3rem;
    }
  }
`
export const Consultations = () => {
  const { t } = useTranslation()
  const [showFilters, setShowFilters] = useState(false)
  const [summary, setSummary] = useState<ConsultationsSummaryData | undefined>(
    undefined
  )
  const [refreshSummary, setRefreshSummary] = useState(false)

  const {
    tableParams,
    requestParams,
    setSearchString,
    setPagination,
    setFilters,
    setSorting,
    setParam
  } = useDataTableParams<any>({
    params: {
      searchString: '',
      importId: ''
    },
    filters: defaults,
    sorting: [{ id: 'consultation_date', desc: false }]
  })
  const filtersCount = useFiltersCount(tableParams.filters)

  const fetchFunction = useCallback(
    (options: AxiosRequestConfig) =>
      getLibreConsultations(requestParams, options),
    [requestParams]
  )

  useEffect(() => {
    if (summary?.id) {
      setParam('importId', summary.id)
    }
  }, [summary, setParam, refreshSummary])

  const { response, error, status, refresh } =
    useFetch<PaginatedResponse<ConsultationsData>>(fetchFunction)

  const columns: ColumnDef<ConsultationsData>[] = useMemo(
    () => [
      {
        id: 'consultation_date',
        header: t('consultation_table_header_title_1'),
        sortingFn: 'datetime',
        accessorFn: (row: ConsultationsData) =>
          dayjs(row.consultationTime).format('DD/MM/YYYY')
      },
      {
        id: 'time',
        header: t('consultation_table_header_title_2'),
        enableSorting: false,
        accessorFn: (row: ConsultationsData) =>
          dayjs(row.consultationTime).format('HH:mm')
      },
      {
        id: 'name',
        header: t('consultation_table_header_title_3'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <NameField row={row.original} />
        )
      },
      {
        id: 'mrn',
        header: t('consultation_table_header_title_4'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <MrnField onPatientCreated={() => refresh()} row={row.original} />
        )
      },
      {
        id: 'account_links',
        header: t('consultation_table_header_title_5'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <ConsultationsAccountLink row={row.original} />
        )
      },
      {
        id: 'latest_data',
        header: t('consultation_table_header_title_6'),
        enableSorting: false,
        accessorFn: (row: ConsultationsData) =>
          row.latestData ? dayjs(row.latestData).format('DD/MM/YYYY') : '-' // ASK SIMON ABOUT EMPTY STATE
      },
      {
        id: 'options',
        cell: ({ row }: { row: Row<ConsultationsData> }) => (
          <ConsultationsOptions
            row={row.original}
            onPatientCreated={() => refresh()}
          />
        )
      }
    ],
    [refresh, t]
  )

  const table = useReactTable({
    data: response?.data || [],
    columns,
    rowCount: response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: { pagination: tableParams.pagination, sorting: tableParams.sorting },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getRowId: (originalRow) => originalRow.id,
    enableSortingRemoval: false
  })

  return (
    <ErrorBoundary>
      <TableWrapper>
        <TableHeader>
          <HeaderTopSection>
            <Title>{t('consultations_title')}</Title>
            <HeaderTopSectionButtons>
              <TableSearchBar handleChange={setSearchString} />
              <FilterFunctionButton
                filtersCount={filtersCount}
                handleClick={handleFilterExpand}
                icon={faBarsFilter}
                text={t('Filters')}
              />
              <ImportConsultations
                setSummary={setSummary}
                setRefreshSummary={setRefreshSummary}
              />
              <ConsultationsSummary
                summary={summary}
                setSummary={setSummary}
                refreshSummary={refreshSummary}
                getLatestSummary={false}
                handleRefresh={() => setRefreshSummary(!refreshSummary)}
              />
            </HeaderTopSectionButtons>
          </HeaderTopSection>

          {showFilters && (
            <FiltersManager>
              <DataFilter
                title={t('consultations_patient_account_link_title')}
                slug={ConsultationsFilter.patientAccountLink}
                type={DataFilterType.singleSelect}
                data={Object.values(PatientAccountLink)}
                initialState={defaults[ConsultationsFilter.patientAccountLink]}
                currentState={
                  tableParams.filters[ConsultationsFilter.patientAccountLink]
                }
                setSelected={(filterValue) =>
                  setFilters(
                    ConsultationsFilter.patientAccountLink,
                    filterValue
                  )
                }
              />
              <DataFilter
                title={t('consultations_consultation_time_title')}
                slug={ConsultationsFilter.consultationTime}
                type={DataFilterType.singleSelect}
                data={Object.values(ConsultationTime)}
                initialState={defaults[ConsultationsFilter.consultationTime]}
                currentState={
                  tableParams.filters[ConsultationsFilter.consultationTime]
                }
                setSelected={(filterValue) =>
                  setFilters(ConsultationsFilter.consultationTime, filterValue)
                }
              />
              <DataFilter
                title={t('consultations_latest_data_title')}
                slug={ConsultationsFilter.latestData}
                type={DataFilterType.singleSelect}
                data={Object.values(LatestData)}
                initialState={defaults[ConsultationsFilter.latestData]}
                currentState={
                  tableParams.filters[ConsultationsFilter.latestData]
                }
                setSelected={(filterValue) =>
                  setFilters(ConsultationsFilter.latestData, filterValue)
                }
              />
            </FiltersManager>
          )}
        </TableHeader>
        <TableLayout
          table={table}
          context={
            <ConsultationsSummary
              summary={summary}
              setSummary={setSummary}
              refreshSummary={refreshSummary}
              getLatestSummary={true}
              handleRefresh={() => setRefreshSummary(!refreshSummary)}
            />
          }
          fetchStatus={status}
          fetchError={error}
          usesPagination={true}
          WrapperElement={StickyTableWrapper}
        ></TableLayout>
      </TableWrapper>
    </ErrorBoundary>
  )

  function handleFilterExpand() {
    setShowFilters((isVisible) => !isVisible)
  }
}
