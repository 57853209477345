import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import saveAs from 'file-saver'
import { useTranslation } from 'react-i18next'
import { getConsultationsSummaryErrorFile } from '../../../../core/api/consultations/get-consultations-summary-error-file'
import { DownloadStatus, useDownloader } from '../../../../hooks/use-downloader'
import {
  Button,
  ButtonIcon,
  ButtonText,
  ConsultationsSummaryData,
  KeyValuePair
} from '../ConsultationsSummary'

interface ErrorFileComponentProps {
  summary: null | undefined | ConsultationsSummaryData
}

export const ErrorFileComponent = ({ summary }: ErrorFileComponentProps) => {
  const { state, download, reset } = useDownloader()
  const { t } = useTranslation()
  async function downloadConsultationsErrorFile() {
    const data = await download(
      (progressCb: (progress: ProgressEvent) => void) => {
        return getConsultationsSummaryErrorFile(
          {
            id: summary?.id ?? ''
          },
          progressCb
        )
      }
    )
    if (data) {
      saveAs(data, 'error_file.csv')
    }
  }

  return summary && summary.rowsWithErrors > 0 ? (
    <KeyValuePair>
      <Button
        disabled={state.status === DownloadStatus.Downloading}
        onClick={downloadConsultationsErrorFile}
      >
        <ButtonIcon icon={faTriangleExclamation} />
        <ButtonText>
          {t('consultation_summary_error_csv_button_text')}
        </ButtonText>
      </Button>
    </KeyValuePair>
  ) : null
}
