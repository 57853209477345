import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { getUploaderPings } from '../../core/api/get-uploader-pings'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { AppsInformation } from './AppsInformation/AppsInformation'
import { BasicInfo } from './BasicInfo/BasicInfo'

export enum ProductType {
  Uploader,
  AdaptMobileApp
}
export enum UploaderPlatform {
  darwin = 'darwin',
  win32 = 'win32',
  ios = 'ios'
}
export interface PatientUploaderPings {
  id: string
  patientId: string
  uploaderVersion: string
  deviceInfo: string
  platformVersion: string
  latestAccess: string
  uploaderPlatform: UploaderPlatform
  productType: ProductType
  productName: string
}
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Body = styled.div`
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 1rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 1.5rem;
`

export const PatientInfo = () => {
  const { patient } = usePatientContext()
  const { user } = useSessionContext()
  const [uploaderPingsError, setUploaderPingsError] = useState<boolean>(false)
  const [uploaderPings, setUploaderPings] = useState<
    PatientUploaderPings[] | null
  >(null)

  useEffect(() => {
    const getPings = async () => {
      try {
        const response = await getUploaderPings(patient?.patientId)
        setUploaderPings(response)
      } catch (err) {
        setUploaderPingsError(true)
      }
    }
    getPings()
  }, [patient?.patientId])

  if (uploaderPingsError) {
    return <div data-testid="uploaderPingsError"> Error getting pings </div>
  }
  return (
    <Wrapper>
      {uploaderPings ? (
        <>
          <BasicInfo user={user} patient={patient} />
          <AppsInformation uploaderPings={uploaderPings} />
        </>
      ) : (
        <Spinner spinnersize={spinnerSize.large}></Spinner>
      )}
    </Wrapper>
  )
}
