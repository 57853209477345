import { CMIIntegrationValue } from '../../containers/ClinicIntegrations/ClinicIntegrations'
import config from '../config'
import { AxiosRequest } from './axios-request'
import { PaginatedResponse } from './pagination'
export async function getProvider(
  options: object = {}
): Promise<PaginatedResponse<CMIIntegrationValue>> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/cmi/providers`,
    options
  )
  return response.data as PaginatedResponse<CMIIntegrationValue>
}
