import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaginatedResponse } from '../../core/api/pagination'
import { useFetch } from '../../hooks/useFetch'
import { useFiltersCount } from '../../hooks/useFiltersCount'

import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary'
import {
  DataFilter,
  DataFilterType
} from '../../components/TableComponents/Filters/DataFilter/DataFilter'
import { FiltersManager } from '../../components/TableComponents/Filters/DataFilter/FiltersManager'
import { FilterFunctionButton } from '../../components/TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { TableLayout } from '../../components/TableComponents/TableLayout/TableLayout'
import { TableSearchBar } from '../../components/TableComponents/TableSearchBar/TableSearchBar'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  StickyTable,
  TableHeader,
  TableWrapper
} from '../../components/TableComponents/TableStyledElements/TableStyledElements'
import { Title } from '../../components/Title/Title'
import { getSupportTickets } from '../../core/api/get-support-tickets'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { useDataTableParams } from '../../hooks/useDataTableParams'
import {
  TicketBadgeSize,
  TicketDTO,
  TicketFiltersEnum,
  TicketReportingTime,
  TicketReportingUser,
  TicketSoftwareVersion,
  TicketStatus,
  TicketSystemContext,
  TicketType
} from './constants/constants'
import { TicketFooterContext } from './TicketFooterContext/TicketFooterContext'
import { ReportingUserBadge } from './TicketTable/ReportingUserBadge/ReportingUserBadge'
import { TicketContext } from './TicketTable/TicketContext/TicketContext'
import { TicketStatusBadge } from './TicketTable/TicketStatusBadge/TicketStatusBadge'
import { TicketTypeBadge } from './TicketTable/TicketTypeBadge/TicketTypeBadge'

const StickyTableWrapper = styled(StickyTable)`
  tr {
    th:first-child,
    td:first-child {
      width: 7.125rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 4.9375rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 7.125rem;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 12rem;
    }
    th:nth-child(5),
    td:nth-child(5) {
      width: 28.0625rem;
    }
    th:nth-child(6),
    td:nth-child(6) {
      width: 11.1875rem;
    }
    th:nth-child(7),
    td:nth-child(7) {
      width: 6.4375rem;
    }
    th:nth-child(8),
    td:nth-child(8) {
      width: 14.125rem;
    }
  }
`

const defaults = {
  [TicketFiltersEnum.status]: [],
  [TicketFiltersEnum.types]: [],
  [TicketFiltersEnum.reportingTime]: undefined,
  [TicketFiltersEnum.reportingUser]: [],
  [TicketFiltersEnum.systemContext]: [],
  [TicketFiltersEnum.softwareVersion]: undefined
}

export const Tickets = () => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  const [showFilters, setShowFilters] = useState(false)
  const navigate = useNavigate()
  const {
    tableParams,
    requestParams,
    setSearchString,
    setPagination,
    setFilters,
    setSorting
  } = useDataTableParams<{
    search: string
    newestPortalVersion: string
    newestUploaderVersion: string
  }>({
    params: {
      search: '',
      newestUploaderVersion: `v${siteSettings?.uploaderVersion}`,
      newestPortalVersion: `v${process.env.REACT_APP_UI_VERSION}`
    },
    filters: defaults,
    sorting: [{ id: 'ticket_date', desc: true }]
  })
  const filtersCount = useFiltersCount(tableParams.filters)

  const fetchFunction = useCallback(
    (options: AxiosRequestConfig) => getSupportTickets(requestParams, options),
    [requestParams]
  )
  const { response, error, status } =
    useFetch<PaginatedResponse<TicketDTO>>(fetchFunction)

  const columns: ColumnDef<TicketDTO>[] = useMemo(
    () => [
      {
        id: 'ticket_date',
        header: t('support&feedback_table_header_title_date'),
        sortingFn: 'datetime',
        enableSorting: true,
        accessorFn: (row: TicketDTO) => dayjs(row.created).format('DD/MM/YYYY')
      },
      {
        id: 'ticket_time',
        header: t('support&feedback_table_header_title_time'),
        enableSorting: false,
        accessorFn: (row: TicketDTO) => dayjs(row.created).format('HH:mm')
      },
      {
        id: 'ticket_type',
        header: t('support&feedback_table_header_title_type'),
        enableSorting: false,
        cell: ({ row }: { row: Row<TicketDTO> }) => (
          <TicketTypeBadge
            size={TicketBadgeSize.small}
            type={row.original.type}
            withBackground={true}
          />
        )
      },
      {
        id: 'ticket_user',
        header: t('support&feedback_table_header_title_user'),
        enableSorting: false,
        cell: ({ row }: { row: Row<TicketDTO> }) => (
          <ReportingUserBadge
            type={row.original.user?.userType || null}
            size={TicketBadgeSize.small}
          />
        )
      },
      {
        id: 'ticket_context',
        header: t('support&feedback_table_header_title_context'),
        enableSorting: false,
        cell: ({ row }: { row: Row<TicketDTO> }) => (
          <TicketContext context={row.original.applicationInfo.context} />
        )
      },
      {
        id: 'ticket_jira',
        header: t('support&feedback_table_header_title_jira'),
        enableSorting: false,
        cell: ({ row }: { row: Row<TicketDTO> }) => (
          <>{t('jira_link_not_linked')}</>
        )
      },
      {
        id: 'ticket_status',
        header: t('support&feedback_table_header_title_status'),
        enableSorting: false,
        cell: ({ row }: { row: Row<TicketDTO> }) => (
          <TicketStatusBadge
            status={row.original.status}
            withBackground={true}
            size={TicketBadgeSize.small}
          />
        )
      },
      {
        id: 'ticket_age',
        header: t('support&feedback_table_header_title_age'),
        enableSorting: false,
        cell: ({ row }: { row: Row<TicketDTO> }) => <>{row.original.age}</>
      }
    ],
    [t]
  )

  const table = useReactTable({
    data: response?.data || [],
    columns,
    rowCount: response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    state: {
      pagination: tableParams.pagination,
      sorting: tableParams.sorting
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getRowId: (originalRow) => originalRow.id,
    enableSortingRemoval: false
  })

  const handleRowClick = (row: TicketDTO) => {
    navigate(`/portal/clinic/tickets/${row.id}`)
  }
  return (
    <ErrorBoundary>
      <TableWrapper>
        <TableHeader>
          <HeaderTopSection>
            <Title>{t('support&feedback_title')}</Title>
            <HeaderTopSectionButtons>
              <TableSearchBar handleChange={setSearchString} />
              <FilterFunctionButton
                filtersCount={filtersCount}
                handleClick={handleFilterExpand}
                icon={faBarsFilter}
                text={t('Filters')}
                datatestid="tickets_filters_button"
              />
            </HeaderTopSectionButtons>
          </HeaderTopSection>
          {showFilters && (
            <FiltersManager>
              <DataFilter
                title={t('support&feedback_status_title')}
                slug={TicketFiltersEnum.status}
                type={DataFilterType.multiSelect}
                data={Object.values(TicketStatus)}
                initialState={defaults[TicketFiltersEnum.status]}
                currentState={tableParams.filters[TicketFiltersEnum.status]}
                setSelected={(filterValue) =>
                  setFilters(TicketFiltersEnum.status, filterValue)
                }
              />
              <DataFilter
                title={t('support&feedback_type_title')}
                slug={TicketFiltersEnum.types}
                type={DataFilterType.multiSelect}
                data={Object.values(TicketType)}
                initialState={defaults[TicketFiltersEnum.types]}
                currentState={tableParams.filters[TicketFiltersEnum.types]}
                setSelected={(filterValue) =>
                  setFilters(TicketFiltersEnum.types, filterValue)
                }
              />
              <DataFilter
                title={t('support&feedback_reporting_time_title')}
                slug={TicketFiltersEnum.reportingTime}
                type={DataFilterType.singleSelect}
                data={Object.values(TicketReportingTime)}
                initialState={defaults[TicketFiltersEnum.reportingTime]}
                currentState={
                  tableParams.filters[TicketFiltersEnum.reportingTime]
                }
                setSelected={(filterValue) =>
                  setFilters(TicketFiltersEnum.reportingTime, filterValue)
                }
              />
              <DataFilter
                title={t('support&feedback_reporting_user_title')}
                slug={TicketFiltersEnum.reportingUser}
                type={DataFilterType.multiSelect}
                data={Object.values(TicketReportingUser)}
                initialState={defaults[TicketFiltersEnum.reportingUser]}
                currentState={
                  tableParams.filters[TicketFiltersEnum.reportingUser]
                }
                setSelected={(filterValue) =>
                  setFilters(TicketFiltersEnum.reportingUser, filterValue)
                }
              />
              <DataFilter
                title={t('support&feedback_system_context_title')}
                slug={TicketFiltersEnum.systemContext}
                type={DataFilterType.multiSelect}
                data={Object.values(TicketSystemContext)}
                initialState={defaults[TicketFiltersEnum.systemContext]}
                currentState={
                  tableParams.filters[TicketFiltersEnum.systemContext]
                }
                setSelected={(filterValue) =>
                  setFilters(TicketFiltersEnum.systemContext, filterValue)
                }
              />
              <DataFilter
                title={t('support&feedback_software_version_title')}
                slug={TicketFiltersEnum.softwareVersion}
                type={DataFilterType.singleSelect}
                data={Object.values(TicketSoftwareVersion)}
                initialState={defaults[TicketFiltersEnum.softwareVersion]}
                currentState={
                  tableParams.filters[TicketFiltersEnum.softwareVersion]
                }
                setSelected={(filterValue) =>
                  setFilters(TicketFiltersEnum.softwareVersion, filterValue)
                }
              />
            </FiltersManager>
          )}
        </TableHeader>
        <TableLayout
          table={table}
          context={<TicketFooterContext />}
          fetchStatus={status}
          fetchError={error}
          usesPagination={true}
          WrapperElement={StickyTableWrapper}
          handleRowClick={handleRowClick}
        />
      </TableWrapper>
    </ErrorBoundary>
  )

  function handleFilterExpand() {
    setShowFilters((isVisible) => !isVisible)
  }
}
