import axios from 'axios'
import config from '../config'

export async function postTicketUnauthorized(
  formData: FormData
): Promise<number> {
  const response = await axios
    .create({
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .post(config.API_URL + '/tickets', formData)
  return response.data as number
}
