import { faGear } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CMIIntegrationValue } from '../../../../../../containers/ClinicIntegrations/ClinicIntegrations'

const Wrapper = styled.button`
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  background: var(--white-color);
  display: flex;
  padding: 0rem 0.625rem;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-primary);
  cursor: pointer;
  &:hover {
    border: 0.0625rem solid var(--brand-primary-16-color);
    background: var(--brand-primary-12-color);
    color: var(--brand-primary-color);
  }
  height: 2.25rem;
  box-sizing: border-box;
`
const Icon = styled(FontAwesomeIcon)`
  color: inherit;
  font-size: 0.875rem;
  line-height: 2.25rem;
`

interface ResetIntegrationProps {
  rowValue: CMIIntegrationValue
  editProvider: (selectedProvider: CMIIntegrationValue) => void
}

export const EditProvider = ({
  rowValue,
  editProvider
}: ResetIntegrationProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper onClick={() => editProvider(rowValue)}>
      <Icon icon={faGear}></Icon>
    </Wrapper>
  )
}
