import config from '../config'
import { AxiosRequest } from './axios-request'

export const getTicketAttachments = async (
  params: { id: string },
  onDownload: (progress: ProgressEvent) => void,
  options: object = {}
) => {
  const response = await AxiosRequest.create({ responseType: 'blob' }).get(
    config.API_URL + `/tickets/${params.id}/download`,
    {
      ...options,
      onDownloadProgress: onDownload
    }
  )
  return response.data as Blob
}
