import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Modal } from '../../../../Modal/Modal'
import { ClinicManagedStatusBadge } from '../../StatusBadges/ClinicManagedStatusBadge'
import { PatientManagedStatusBadge } from '../../StatusBadges/PatientManagedStatusBadge'
import { LinkPatientModal } from '../LinkPatientModal/LinkPatientModal'
import { PatientApiModal } from '../PatientApiModal/PatientApiModal'
import {
  PMIStateValues,
  ProviderPatient,
  ProviderPatientProviderType
} from '../ProviderPatientsInterfaces'
const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
`
const showChevron = () => keyframes`
 0% {
  transform: translateX(150%) translateY(-50%);
  opacity: 0;
}
 100% {
  transform: translateX(0%) translateY(-50%);
  opacity: 1;
}
`
const hideChevron = () => keyframes`
 0% {
  transform: translateX(0%) translateY(-50%);
  opacity: 1;
}
 100% {
  transform: translateX(150%) translateY(-50%);
  opacity: 0;
}
`
const ChevronWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--brand-primary-16-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateX(150%) translateY(50%);
  opacity: 0;
  &.showChevron {
    animation-name: ${showChevron};
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  &.hideChevron {
    animation-name: ${hideChevron};
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
    animation-direction: forward;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`
const Chevron = styled(FontAwesomeIcon)`
  color: var(--brand-primary-color);
  font-size: 0.625rem;
`

interface LinkPatientProps {
  patient: ProviderPatient
  refresh: () => void
}

export const LinkPatientButton = ({ patient, refresh }: LinkPatientProps) => {
  const [showLinkPatientModal, setShowLinkPatientModal] =
    useState<boolean>(false)
  const [chevronClass, setChevronClass] = useState<string>('')
  const isConnecting = patient.pmiStateValue === PMIStateValues.CONNECTING

  const getBadge = () => {
    if (patient.pmiStateValue) {
      return <PatientManagedStatusBadge state={patient.pmiStateValue} />
    } else {
      return (
        <ClinicManagedStatusBadge
          assignedPatientId={patient.assignedPatientId}
          ignored={patient.ignored}
        />
      )
    }
  }

  return (
    <>
      <Modal isOpen={showLinkPatientModal}>
        {patient.providerType ===
        ProviderPatientProviderType.DexcomAPICMIProvider ? (
          <PatientApiModal
            providerPatient={patient}
            setShowLinkPatientModal={() => setShowLinkPatientModal(false)}
            refresh={refresh}
          ></PatientApiModal>
        ) : (
          <LinkPatientModal
            providerPatient={patient}
            closeShowLinkPatientModal={() => setShowLinkPatientModal(false)}
            refresh={refresh}
          />
        )}
      </Modal>
      <Wrapper
        style={isConnecting ? { pointerEvents: 'none' } : {}}
        data-testid={'LinkPatientButtonWrapper' + patient.id}
        onClick={
          isConnecting ? () => null : () => setShowLinkPatientModal(true)
        }
        onMouseEnter={
          isConnecting ? () => null : () => setChevronClass('showChevron')
        }
        onMouseLeave={
          isConnecting ? () => null : () => setChevronClass('hideChevron')
        }
      >
        {getBadge()}
        <ChevronWrapper className={chevronClass}>
          <Chevron icon={faChevronRight}></Chevron>
        </ChevronWrapper>
      </Wrapper>
    </>
  )
}
