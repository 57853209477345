import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { loadIncrement } from '../../constants/loadIncrement'

interface LoadMoreProps {
  setLoadAmount: Dispatch<SetStateAction<number>>
  loadAmount: number
  maxSize?: number
}
const LoadButton = styled.button`
  padding: 1rem 2rem;
  box-sizing: border-box;
  width: 18rem;
  background-color: var(--brand-primary-color);
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--white-color);
  border: none;
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
`
export const LoadMoreButton = ({
  setLoadAmount,
  loadAmount,
  maxSize
}: LoadMoreProps) => {
  const { t } = useTranslation()

  return (
    <LoadButton
      data-testid="TableLoadMoreButton"
      onClick={() => {
        setLoadAmount(loadAmount + loadIncrement)
      }}
    >
      {t('Load more') + ' '}
      {maxSize && t('loadMore', { amount: maxSize - loadAmount })}
    </LoadButton>
  )
}
