import {
  ConsultationsData,
  ConsultationsParams,
  ConsultationTime,
  LatestData,
  PatientAccountLink
} from '../../../components/Consultations/constants/constants'
import config from '../../config'
import dayjs from '../../dayjs/dayjs'
import { AxiosRequest } from '../axios-request'
import { PaginatedResponse } from '../pagination'

type FormattedDateFilter = {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
} | null

export async function getLibreConsultations(
  params: ConsultationsParams & { page: number; resultsPerPage: number },
  options: object = {}
): Promise<PaginatedResponse<ConsultationsData> | undefined> {
  if (params.importId !== '') {
    const todayDate = dayjs(new Date())

    // handle sorting
    const sortBy = params.sorting.length > 0 ? params.sorting[0].id : undefined
    const sortDesc =
      params.sorting.length > 0 ? params.sorting[0].desc : undefined

    // handle filter for empty patienAccountLink

    const patientAccountLinkEmpty =
      params.patientAccountLink === PatientAccountLink.notregistered ||
      params.patientAccountLink === PatientAccountLink.notlinked ||
      undefined

    // Handle filter for empty/invalid consultation time
    const consultationTimeEmpty =
      params.consultationTime === ConsultationTime.notime || undefined

    // Handle filter for empty/invalid latest data
    const latestDataEmpty = params.latestData === LatestData.nodata || undefined

    const patientAccountLinkParam = !patientAccountLinkEmpty
      ? params.patientAccountLink
      : null

    // Transform consultation times into date ranges
    const consultationTimes = !consultationTimeEmpty
      ? formatConsultationTime(todayDate, params.consultationTime)
      : null

    // Transform latest data into date ranges
    const latestDataTimes = !latestDataEmpty
      ? formatLatestData(todayDate, params.latestData)
      : null

    let notLinked = false
    let notRegistered = false

    if (params.patientAccountLink === PatientAccountLink.notlinked) {
      notLinked = true
    }
    if (params.patientAccountLink === PatientAccountLink.notregistered) {
      notRegistered = true
    }

    const formattedParams = {
      page: params.page,
      resultsPerPage: params.resultsPerPage,
      consultationTimeStart: consultationTimes?.start.toISOString(),
      consultationTimeEnd: consultationTimes?.end.toISOString(),
      consultationTimeEmpty,
      latestDataStart: latestDataTimes?.start.toISOString(),
      latestDataEnd: latestDataTimes?.end.toISOString(),
      latestDataEmpty,
      searchString: params.searchString || undefined,
      sortBy,
      sortDesc,
      notLinked,
      notRegistered
    }

    const response = await AxiosRequest.create().get(
      `${config.API_URL}/consultations_uploads/${params.importId}/patients`,
      {
        ...options,
        params: patientAccountLinkParam
          ? { ...formattedParams, patientAccountLink: patientAccountLinkParam }
          : formattedParams
      }
    )
    return response.data as PaginatedResponse<ConsultationsData>
  }
}

/**
 * Transform the consulation time enum into date ranges
 *
 * @param today Initial datetime
 * @param consultationTime Filter value
 */
function formatConsultationTime(
  today: dayjs.Dayjs,
  consultationTime: ConsultationTime | null | undefined
): FormattedDateFilter {
  switch (consultationTime) {
    case ConsultationTime.within4hours:
      return {
        start: today,
        end: today.add(4, 'hour')
      }
    case ConsultationTime.today:
      return {
        start: today,
        end: today.endOf('day')
      }
    case ConsultationTime.within2days:
      return {
        start: today,
        end: today.add(2, 'day').endOf('day')
      }
    case ConsultationTime.within7days:
      return {
        start: today,
        end: today.add(7, 'day').endOf('day')
      }
    case ConsultationTime.within14days:
      return {
        start: today,
        end: today.add(14, 'day').endOf('day')
      }
    case ConsultationTime.within30days:
      return {
        start: today,
        end: today.add(30, 'day').endOf('day')
      }
    case ConsultationTime.past:
      return {
        start: today.subtract(100, 'year'),
        end: today
      }
    case ConsultationTime.notime:
      return null
    default:
      return null
  }
}

/**
 * Transform the latest data filter into date ranges
 *
 * @param todayDate Start date
 * @param latestData Filter for latest data
 * @returns FormattedDateFilter
 */
function formatLatestData(
  todayDate: dayjs.Dayjs,
  latestData: LatestData | null | undefined
): FormattedDateFilter {
  switch (latestData) {
    case LatestData.within2days:
      return {
        start: todayDate.subtract(2, 'day').startOf('day'),
        end: todayDate
      }
    case LatestData.olderthan2days:
      return {
        start: todayDate.subtract(100, 'year'),
        end: todayDate.subtract(2, 'day').endOf('day')
      }
    case LatestData.olderthan7days:
      return {
        start: todayDate.subtract(100, 'year'),
        end: todayDate.subtract(7, 'day').endOf('day')
      }
    case LatestData.olderthan14days:
      return {
        start: todayDate.subtract(100, 'year'),
        end: todayDate.subtract(14, 'day').endOf('day')
      }
    case LatestData.olderthan30days:
      return {
        start: todayDate.subtract(100, 'year'),
        end: todayDate.subtract(30, 'day').endOf('day')
      }
    case LatestData.olderthan90days:
      return {
        start: todayDate.subtract(100, 'year'),
        end: todayDate.subtract(90, 'day').endOf('day')
      }
    default:
      return null
  }
}
