import {
  faCircleSmall,
  faInfoCircle,
  faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { DeleteModal } from '../../components/DeleteModal/DeleteModal'
import {
  ProviderData,
  ProviderInformation
} from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderInformation/ProviderInformation'
import {
  FetchStateEnum,
  PMIStateValues,
  ProviderManagementTypes,
  ProviderPatient,
  ProviderPatientProviderType
} from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'

import { FlexColumnWrapper } from '../../components/FlexWrappers/FlexColumnWrapper'
import {
  getIntegrationType,
  getProviderTypeInfoText
} from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderIntegrationModel/ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'
import { ClinicManagedStatusBadge } from '../../components/IntegrationComponents/ClinicLevelComponents/StatusBadges/ClinicManagedStatusBadge'
import { PatientManagedStatusBadge } from '../../components/IntegrationComponents/ClinicLevelComponents/StatusBadges/PatientManagedStatusBadge'
import { FetchDexcomData } from '../../components/IntegrationComponents/PatientLevelComponents/FetchDexcomData/FetchDexcomData'
import { FetchFitbitData } from '../../components/IntegrationComponents/PatientLevelComponents/FetchFitbitData/FetchFitbitData'
import { getProviderManagementTypeText } from '../../components/IntegrationComponents/SharedIntegrationFunctions/getProviderManagementTypeText'
import { IntegrationTypeLabel } from '../../components/IntegrationTypeLabel/IntegrationTypeLabel'
import { Modal } from '../../components/Modal/Modal'
import { Spinner, spinnerSize } from '../../components/Spinner/Spinner'
import { connectPMIPatient } from '../../core/api/connect-pmi-patient'
import { deletePatientProvider } from '../../core/api/delete-patient-provider'
import { getPatientProviders } from '../../core/api/get-patient-providers'
import { usePatientContext } from '../../core/contexts/patient-context'
import { useSessionContext } from '../../core/contexts/session-context'
import { User, UserType } from '../../core/entities/user.entity'
import { SnackBar, SnackBarType } from '../../libraries/Toast/SnackBar'

const IntegrationPage = styled(FlexColumnWrapper)`
  gap: 1rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: flex-start;
`

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  width: 43rem;
  margin-left: 1rem;
  margin-right: 1rem;
`

const TitleBody = styled.div`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-medium);
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  white-space: pre-line;
`
const IntegrationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 43rem;
  justify-content: center;
  align-items: center;
  border: 0.0625rem solid var(--text-lightest);
  gap: 1.5rem;
`

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`
const Title = styled.span`
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-primary);
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`
const SubTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
  font-family: inter;
  color: ${({ color }) => color};
`
const TextSeperator = styled(FontAwesomeIcon)`
  color: var(--text-lightest);
  font-size: 0.5rem;
  font-weight: 900;
  font-style: normal;
`
const Setup = styled.div`
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  height: 2.25rem;
  background-color: var(--blue);
  color: var(--white-color);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  top: 1.5rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  &:hover {
    background-color: var(--blue-darker);
  }
`
const SetupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
`
const DeleteIntegration = styled.div`
  color: var(--low-color);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`

const BoxWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  background-color: var(--element-bg);
  border-radius: 0.5rem;
`
const InfoLogo = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: var(--blue);
  line-height: 1.25rem;
`
const BoxParagraph = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
`

export const getProviderInformationState = (
  providerPatient: ProviderPatient
) => {
  if (providerPatient.mrn || providerPatient.name || providerPatient.birthday)
    return true
  else return false
}
export const getProviderDataState = (providerPatient: ProviderPatient) => {
  if (
    providerPatient.newestProviderData ||
    providerPatient.newestLocalData ||
    providerPatient.latestFetchTime
  )
    return true
  else return false
}
interface PatientViewIntegrationProps {
  providerPatient: ProviderPatient
  connectPatient: (id: string) => Promise<void>
  updatePage: () => void
  updateFetch: () => void
}
const PatientViewIntegration = ({
  providerPatient,
  connectPatient,
  updatePage,
  updateFetch
}: PatientViewIntegrationProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const { t } = useTranslation()
  const showProviderInformation = getProviderInformationState(providerPatient)
  const showProviderData = getProviderDataState(providerPatient)
  const isNotSetup =
    providerPatient.pmiStateValue === PMIStateValues.DISCONNECTED ||
    providerPatient.pmiStateValue === PMIStateValues.NOT_SETUP
  const { user } = useSessionContext()
  const InfoBox = ({
    providerType
  }: {
    providerType: ProviderPatientProviderType
  }) => {
    if (providerType === ProviderPatientProviderType.DexcomAPICMIProvider) {
      return (
        <BoxWrapper>
          <InfoLogo icon={faInfoCircle} />
          <BoxParagraph>{t('Dexcom data is 3 hours delayed')}</BoxParagraph>
        </BoxWrapper>
      )
    } else if (
      providerType === ProviderPatientProviderType.FitbitAPICMIProvider &&
      providerPatient.fetchState?.fetchState === FetchStateEnum.UpToDate
    ) {
      return (
        <BoxWrapper>
          <InfoLogo icon={faInfoCircle} />
          <BoxParagraph>{t('latest_fitbit_data_synced')}</BoxParagraph>
        </BoxWrapper>
      )
    } else if (
      providerType === ProviderPatientProviderType.FitbitAPICMIProvider &&
      providerPatient.fetchState?.fetchState === FetchStateEnum.NotReady
    ) {
      return (
        <BoxWrapper>
          <InfoLogo
            icon={faTriangleExclamation}
            style={{ color: 'var(--yellow)' }}
          />
          <BoxParagraph>
            {t('rateLimitReached', {
              minutes: providerPatient.fetchState.rateLimitReset?.minutes
            })}
          </BoxParagraph>
        </BoxWrapper>
      )
    } else {
      return null
    }
  }

  const deleteProvider = async () => {
    try {
      await deletePatientProvider({
        patientId: providerPatient.id
      })
      setShowDeleteModal(false)
      updatePage()
    } catch (error) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error occurred while deleting patient provider')
      })
      setShowDeleteModal(false)
      updatePage()
    }
  }
  return (
    <IntegrationsWrapper
      data-testid={'patientViewIntegration' + providerPatient.id}
    >
      <HeaderSection>
        <Title>{providerPatient.providerName}</Title>
        <SetupWrapper>
          {providerPatient.managementType ===
          ProviderManagementTypes.patientmanaged ? (
            isNotSetup ? (
              <Setup
                onClick={() => connectPatient(providerPatient.providerId)}
                data-testid={'setupbutton' + providerPatient.id}
              >
                {t('Setup integration')}
              </Setup>
            ) : (
              <Row>
                {providerPatient.providerType ===
                  ProviderPatientProviderType.DexcomAPICMIProvider &&
                  providerPatient.pmiStateValue ===
                    PMIStateValues.CONNECTED && (
                    <FetchDexcomData
                      updateFetch={updateFetch}
                      providerPatient={providerPatient}
                    />
                  )}
                {providerPatient.providerType ===
                  ProviderPatientProviderType.FitbitAPICMIProvider &&
                  providerPatient.pmiStateValue ===
                    PMIStateValues.CONNECTED && (
                    <FetchFitbitData
                      updateFetch={updateFetch}
                      providerPatient={providerPatient}
                    />
                  )}
                {providerPatient.pmiStateValue ? (
                  <PatientManagedStatusBadge
                    state={providerPatient.pmiStateValue}
                  />
                ) : null}
              </Row>
            )
          ) : (
            <ClinicManagedStatusBadge
              assignedPatientId={providerPatient.assignedPatientId}
              ignored={providerPatient.ignored}
            />
          )}
        </SetupWrapper>
      </HeaderSection>
      <HeaderSection>
        <RowWrapper>
          <IntegrationTypeLabel
            type={getIntegrationType(providerPatient.providerType)}
          />
          <SubTitle data-testid="IntegrationComponentSubTitle">
            {t(getProviderTypeInfoText(providerPatient.providerType))}
          </SubTitle>
          <TextSeperator icon={faCircleSmall} />
          <SubTitle>
            {getProviderManagementTypeText(
              user,
              providerPatient.managementType,
              t
            )}
          </SubTitle>
        </RowWrapper>
        {providerPatient.managementType ===
          ProviderManagementTypes.patientmanaged &&
          !isNotSetup && (
            <DeleteIntegration
              data-testid={'deleteIntegration' + providerPatient.id}
              onClick={() => setShowDeleteModal(true)}
            >
              {t('Remove integration')}
            </DeleteIntegration>
          )}
      </HeaderSection>
      {providerPatient.pmiStateValue === PMIStateValues.CONNECTED && (
        <InfoBox providerType={providerPatient.providerType} />
      )}
      {showProviderData && <ProviderData providerPatient={providerPatient} />}
      {showProviderInformation && (
        <ProviderInformation providerPatient={providerPatient} />
      )}
      <Modal isOpen={showDeleteModal} ariaHideApp={false}>
        <DeleteModal
          title={t('Remove integration?')}
          body={t(
            'Are you sure you want to remove this Dexcom integration? This action can not be undone. Any historical data that was uploaded as part of the integration will remain in the database. If you wish to delete this data, contact Line Systems support.'
          )}
          buttonText={t('Remove integration')}
          acceptfn={deleteProvider}
          cancelfn={() => setShowDeleteModal(false)}
        ></DeleteModal>
      </Modal>
    </IntegrationsWrapper>
  )
}

export function Integrations() {
  const { t } = useTranslation()
  const { patient, refreshPatient } = usePatientContext()
  const [providerPatients, setProviderPatients] =
    useState<null | Array<ProviderPatient>>()
  const [update, setUpdate] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const location = useLocation()
  const { user } = useSessionContext()

  const updatePage = () => setUpdate(!update)
  const updateFetch = () => {
    refreshPatient!()
  }

  const getTitleSection = (user: User | null) => {
    if (user?.type === UserType.Patient) {
      return {
        title: t('Your integrations'),
        body: t('yourIntegrationsBody')
      }
    } else
      return {
        title: t('Patient integrations'),
        body: t('patientIntegrationsBody')
      }
  }
  const titleSection = getTitleSection(user)
  useEffect(() => {
    if (!patient) return

    const getData = async () => {
      setError(null)
      try {
        const data = await getPatientProviders(patient.patientId)
        setProviderPatients(data)
      } catch (err) {
        setError(new Error('failed getting integrations'))
      }
    }
    getData()
  }, [patient, update])

  const openLink = (link: URL) => {
    window.open(link, '_self')
  }
  const connectPatient = async (id: string) => {
    const currentLocation = window.location.href
    const newLocation = currentLocation.substring(
      0,
      currentLocation.length - location.pathname.length
    )

    try {
      const link = await connectPMIPatient(
        `${newLocation}/api/pmi/${patient?.patientId}/connect?providerId=${id}`
      )
      openLink(new URL(link))
    } catch (error) {
      SnackBar({
        type: SnackBarType.Error,
        message: t('Error connecting patient')
      })
    }
  }

  const providerPatientsToRender = providerPatients?.map((providerPatient) => {
    return (
      <PatientViewIntegration
        key={providerPatient.id}
        providerPatient={providerPatient}
        connectPatient={connectPatient}
        updatePage={updatePage}
        updateFetch={updateFetch}
      />
    )
  })

  if (error) {
    return (
      <FlexColumnWrapper>
        <div data-testid="integrationsError">
          {t('Error getting integrations')}
        </div>
      </FlexColumnWrapper>
    )
  }
  if (providerPatientsToRender) {
    return (
      <Wrapper data-testid="integrationsData">
        <TitleSection>
          <Title>{titleSection.title}</Title>
          <TitleBody>{titleSection.body}</TitleBody>
        </TitleSection>
        <IntegrationPage>{providerPatientsToRender}</IntegrationPage>
      </Wrapper>
    )
  }
  return (
    <div data-testid="integrationsLoading">
      <Spinner spinnersize={spinnerSize.large}></Spinner>
    </div>
  )
}
