import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons'
import {
  ColumnDef,
  getCoreRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { AxiosRequestConfig } from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CMIIntegrationValue } from '../../../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { getProviderPatients } from '../../../../../../core/api/get-provider-patients'
import { PaginatedResponse } from '../../../../../../core/api/pagination'
import dayjs from '../../../../../../core/dayjs/dayjs'
import { useDataTableParams } from '../../../../../../hooks/useDataTableParams'
import { useFetch } from '../../../../../../hooks/useFetch'
import { useFiltersCount } from '../../../../../../hooks/useFiltersCount'
import { ErrorBoundary } from '../../../../../ErrorBoundary/ErrorBoundary'
import {
  DataFilter,
  DataFilterType
} from '../../../../../TableComponents/Filters/DataFilter/DataFilter'
import { FiltersManager } from '../../../../../TableComponents/Filters/DataFilter/FiltersManager'
import { FilterFunctionButton } from '../../../../../TableComponents/Filters/FilterFunctionButton/FilterFunctionButton'
import { TableSearchBar } from '../../../../../TableComponents/TableSearchBar/TableSearchBar'
import {
  HeaderTopSection,
  HeaderTopSectionButtons,
  StickyTable,
  TableHeader,
  TableWrapper
} from '../../../../../TableComponents/TableStyledElements/TableStyledElements'

import { useSiteSettingsContext } from '../../../../../../core/contexts/site-settings-context'
import { TableLayout } from '../../../../../TableComponents/TableLayout/TableLayout'
import { PatientManagedStatusBadge } from '../../../StatusBadges/PatientManagedStatusBadge'
import { LinkPatientButton } from '../../LinkPatientButton/LinkPatientButton'
import { MRNField } from '../../MRNField/MRNField'
import { NameField } from '../../NameField/NameField'
import { ProviderPatientsContext } from '../../ProviderPatientsContext/ProviderPatientsContext'
import {
  Filter,
  PMIStateValues,
  ProviderPatient,
  ProviderPatientProviderType,
  ProviderPatientsFilterTypes,
  ProviderPatientsTableType
} from '../../ProviderPatientsInterfaces'
import { ProviderPatientsOptions } from '../../ProviderPatientsOptions/ProviderPatientsOptions'
const Title = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  font-family: inter;
  color: var(--text-primary);
`

interface ProviderPatientsPatientManagedTableProps {
  provider: CMIIntegrationValue | undefined
  defaultFilters: { [key: string]: string | string[] | undefined }
}
export const ProviderPatientsPatientManagedTable = ({
  provider,
  defaultFilters
}: ProviderPatientsPatientManagedTableProps) => {
  const { t } = useTranslation()
  const { siteSettings } = useSiteSettingsContext()
  const [showFilters, setShowFilters] = useState(false)
  const {
    tableParams,
    requestParams,
    setSearchString,
    setPagination,
    setFilters
  } = useDataTableParams<ProviderPatientsFilterTypes>({
    params: {
      searchString: ''
    },
    filters: defaultFilters
  })
  const filtersCount = useFiltersCount(tableParams.filters)
  const params = useMemo(() => {
    return {
      ...requestParams,
      providerId: provider?.id
    }
  }, [provider, requestParams])
  const fetchFunction = useCallback(
    (options: AxiosRequestConfig) => getProviderPatients(params, options, null),
    [params]
  )
  const { response, error, status, refresh } =
    useFetch<PaginatedResponse<ProviderPatient>>(fetchFunction)

  const columns: ColumnDef<ProviderPatient>[] = useMemo(
    () => [
      {
        id: 'status',
        header: t('provider_patient_table_title_status'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) =>
          row.original.providerType ===
          ProviderPatientProviderType.DexcomAPICMIProvider ? (
            <LinkPatientButton patient={row.original} refresh={refresh} />
          ) : (
            row.original.pmiStateValue && (
              <PatientManagedStatusBadge state={row.original.pmiStateValue} />
            )
          )
      },
      {
        id: 'name',
        header: t('provider_patient_table_title_name'),
        sortDescFirst: true,
        accessorFn: true,
        cell: ({ row }: { row: Row<ProviderPatient> }) => (
          <NameField row={row.original} />
        )
      },
      {
        id: 'mrn',
        header: t(`${siteSettings?.mrnType}.provider_patient_table_title_mrn`),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) => (
          <MRNField row={row.original} />
        )
      },
      {
        id: 'latest_local_data',
        header: t('provider_patient_table_title_latest_local_data'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) =>
          row.original.newestLocalData
            ? dayjs(row.original.newestLocalData).format('DD/MM/YYYY HH:mm')
            : '-'
      },
      {
        id: 'latest_provider_data',
        header: t('provider_patient_table_title_latest_provider_data'),
        enableSorting: false,
        cell: ({ row }: { row: Row<ProviderPatient> }) =>
          row.original.newestProviderData
            ? dayjs(row.original.newestProviderData).format('DD/MM/YYYY HH:mm')
            : '-'
      },
      {
        id: 'options',
        cell: ({ row }: { row: Row<ProviderPatient> }) => (
          <ProviderPatientsOptions row={row.original} />
        )
      }
    ],
    [refresh, siteSettings?.mrnType, t]
  )

  const table = useReactTable({
    data: response?.data || [],
    columns,
    rowCount: response?.totalResults || 0,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: tableParams.pagination,
      sorting: [{ id: 'name', desc: false }]
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: () => ({}),
    getRowId: (originalRow) => originalRow.id
  })

  function handleFilterExpand() {
    setShowFilters((isVisible) => !isVisible)
  }

  return (
    <>
      <ErrorBoundary>
        <TableWrapper>
          <TableHeader>
            <HeaderTopSection>
              <Title>{provider?.alias}</Title>
              <HeaderTopSectionButtons>
                <TableSearchBar handleChange={setSearchString} />
                <FilterFunctionButton
                  filtersCount={filtersCount}
                  handleClick={handleFilterExpand}
                  icon={faBarsFilter}
                  text={t('Filters')}
                />
              </HeaderTopSectionButtons>
            </HeaderTopSection>
            {showFilters && (
              <FiltersManager>
                <DataFilter
                  title={t('provider_patients_filter_title_status')}
                  slug={Filter.patientIntegrationState}
                  type={DataFilterType.multiSelect}
                  data={Object.values(PMIStateValues).filter(
                    (value) => value !== PMIStateValues.NOT_SETUP
                  )}
                  initialState={defaultFilters[Filter.patientIntegrationState]}
                  currentState={
                    tableParams.filters[Filter.patientIntegrationState]
                  }
                  setSelected={(filterValue) =>
                    setFilters(Filter.patientIntegrationState, filterValue)
                  }
                />
              </FiltersManager>
            )}
          </TableHeader>
          <TableLayout
            table={table}
            context={
              <ProviderPatientsContext
                tableType={ProviderPatientsTableType.individual}
              />
            }
            fetchError={error}
            fetchStatus={status}
            usesPagination={true}
            WrapperElement={StickyTable}
          />
        </TableWrapper>
      </ErrorBoundary>
    </>
  )
}
