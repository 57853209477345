import { SortEnum } from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import { Upload } from '../../components/Uploads/Uploads'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function getPatientUploads(
  orderBy?: SortEnum,
  size?: number,
  patientId?: string
): Promise<Array<Upload>> {
  const response = await AxiosRequest.create().get(
    `${config.API_URL}/patients/${patientId}/uploads?order=${orderBy}&size=${size}`
  )
  return response.data as Array<Upload>
}
