export const supportedFileTypes = [
  'image/jpeg',
  'image/png',
  'image/heic',
  'video/mp4',
  'video/quicktime',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv'
]
export const getSupportedFileTypesString = (file: string) => {
  switch (file) {
    case 'application/pdf':
      return 'PDF'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'DOCX'
    case 'application/msword':
      return 'DOC'
    case 'image/jpeg':
      return 'JPEG'
    case 'image/png':
      return 'PNG'
    case 'video/quicktime':
      return 'MOV'
    case 'image/heic':
      return 'HEIC'
    case 'video/mp4':
      return 'MP4'
    case 'text/csv':
      return 'CSV'
    default:
      return 'Unsupported file type'
  }
}
