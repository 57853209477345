import { ProviderPatientProviderType } from '../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatientsInterfaces'
import config from '../config'
import { AxiosRequest } from './axios-request'

export async function fetchDataApi(
  cmiPatientId: string | null,
  providerType: ProviderPatientProviderType
): Promise<any> {
  const response = await AxiosRequest.create().put(
    `${config.API_URL}/cmi/patients/${cmiPatientId}/fetch?providerType=${providerType}`
  )
  return response.data
}
