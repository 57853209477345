import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ParsedCountry } from 'react-international-phone'
import styled from 'styled-components'
import { ModalComponent } from '../../../components/Modal/Modal'
import { postTicket } from '../../../core/api/postTicket'
import { postTicketUnauthorized } from '../../../core/api/postTicketUnauthorized'
import { usePatientContext } from '../../../core/contexts/patient-context'
import { useSessionContext } from '../../../core/contexts/session-context'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import { checkIfFileExists } from '../../../helpers/checkIfFileExists'
import { getBrowserSpecs } from '../../../helpers/getBrowserSpecs'
import { getOperatingSystem } from '../../../helpers/getOperatingSystem'
import { useLanguage } from '../../../hooks/use-language'
import { TicketProduct, TicketType } from '../constants/constants'
import { TicketContactInformation } from './TicketContactInformation/TicketContactInformation'
import { TicketDescription } from './TicketDescription/TicketDescription'
import { TicketFooter } from './TicketFooter/TicketFooter'
import { TicketSubmissionFinished } from './TicketSubmissionFinished/TicketSubmissionFinished'

const Wrapper = styled.form`
  display: flex;
  width: 64.9375rem;
  padding: 2rem;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  border-radius: 1.5rem;
  background: var(--white-color);
`

const Title = styled.span`
  color: var(--text-primary);
  font-size: 1.25rem;
  font-family: inter;
  font-weight: 700;
  line-height: 2.25rem;
`
export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  width: 100%;
`
export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 18.9375rem;
`
export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 40rem;
`

export const SectionTitleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`

export const SectionTitle = styled.span`
  color: var(--text-primary);
  text-align: right;
  text-overflow: ellipsis;
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`
export const SectionDescription = styled.span`
  color: var(--text-lighter);
  text-overflow: ellipsis;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  white-space: pre-line;
`

const SectionSeperator = styled.div`
  width: 100%;
  height: 0.0625rem;
  background: var(--text-lightest);
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

export const InputTitle = styled.span`
  color: var(--text-medium);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5rem;
`

interface TicketCreationProps {
  closeFun: () => void
  type: TicketType
  setTicketSuccess: Dispatch<SetStateAction<boolean>>
}
export interface TicketFormValues {
  email?: string
  phone?: string
  description: string
  countryCode?: string
}
export const TicketCreation = ({
  closeFun,
  type,
  setTicketSuccess
}: TicketCreationProps) => {
  const { t } = useTranslation()
  const [ticketData, setTicketData] = useState<null | FormData>(null)
  const [country, setCountry] = useState<ParsedCountry>({
    name: 'dk',
    dialCode: '45',
    iso2: 'dk',
    format: '',
    priority: 1,
    areaCodes: []
  })
  const [attachments, setAttachments] = useState<File[] | null>(null)
  const [removedFiles, setRemovedFiles] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [callAgain, setCallAgain] = useState(false)
  const { user } = useSessionContext()
  const { i18n } = useLanguage()
  const { siteSettings } = useSiteSettingsContext()
  const { patient } = usePatientContext()
  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<TicketFormValues>({
    mode: 'onChange',
    defaultValues: {
      email: undefined,
      phone: undefined,
      description: ''
    }
  })

  const onSubmit = async (data: TicketFormValues) => {
    const base64Url = btoa(window.location.href)
    const browser = getBrowserSpecs()
    const operatingSystem = getOperatingSystem()
    const formData = new FormData()
    const filesToBeRemoved: string[] = []
    formData.append('type', type)
    formData.append('description', data.description)
    formData.append('product', TicketProduct.Portal)
    formData.append('deepLink', base64Url)
    formData.append('browserName', browser.name)
    formData.append('browserVersion', browser.version)
    formData.append('section', window.location.pathname)
    formData.append('portalVersion', `v${process.env.REACT_APP_UI_VERSION}`)
    formData.append('language', i18n.language)

    if (siteSettings?.version) {
      formData.append('backendVersion', siteSettings.version)
    }
    if (siteSettings?.releaseVersion) {
      formData.append('releaseVersion', siteSettings.releaseVersion)
    }
    if (patient?.patientId) {
      formData.append('patientId', patient.patientId)
    }
    if (operatingSystem) {
      formData.append('osName', operatingSystem)
    }
    if (siteSettings?.deploymentName) {
      formData.append('deploymentName', siteSettings.deploymentName)
    }
    if (siteSettings?.productUDI) {
      formData.append('productUDI', siteSettings.productUDI)
    }
    if (data.email) {
      formData.append('email', data.email)
    }
    if (data.phone) {
      formData.append('phone', `+${country.dialCode} ${data.phone}`)
    }
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        if (await checkIfFileExists(attachments[i])) {
          formData.append('files', attachments[i])
        } else {
          filesToBeRemoved.push(attachments[i].name)
        }
      }
    }

    setRemovedFiles(filesToBeRemoved)
    setTicketData(formData)
    setTicketSuccess(true)
  }

  useEffect(() => {
    if (ticketData !== null) {
      const doPostTicket = async () => {
        try {
          setLoading(true)
          setError(false)
          if (user) {
            await postTicket(ticketData)
          } else {
            await postTicketUnauthorized(ticketData)
          }
        } catch (error: any) {
          setError(true)
        } finally {
          setLoading(false)
        }
      }
      doPostTicket()
    }
  }, [ticketData, user, callAgain])

  return (
    <ModalComponent
      closeFun={closeFun}
      closetestid={'closeTicketCreationModal'}
      preventTabClose={true}
    >
      {ticketData === null ? (
        <Wrapper
          onSubmit={handleSubmit(onSubmit)}
          data-testid="TicketCreationWrapper"
        >
          <Title>{t(`ticket_creation_${type.toLowerCase()}_title`)}</Title>
          <TicketDescription
            register={register}
            attachments={attachments}
            setAttachments={setAttachments}
            type={type}
          />
          <SectionSeperator />
          <TicketContactInformation
            register={register}
            country={country}
            setCountry={setCountry}
          />
          <SectionSeperator />
          <TicketFooter isValid={isValid} />
        </Wrapper>
      ) : (
        <TicketSubmissionFinished
          data-testid="TicketCreationCompleteWrapper"
          loading={loading}
          error={error}
          retry={() => setCallAgain(!callAgain)}
          removedFiles={removedFiles}
          closeFun={closeFun}
        />
      )}
    </ModalComponent>
  )
}
