import styled from 'styled-components'
import {
  CMIIntegrationState,
  CMIIntegrationValue
} from '../../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { useSessionContext } from '../../../../../core/contexts/session-context'
import { UserType } from '../../../../../core/entities/user.entity'
import { EditProvider } from './EditProvider/EditProvider'
import { OpenIndividualIntegrationTable } from './OpenIndividualIntegrationTable/OpenIndividualIntegrationTable'
import { ResetIntegration } from './ResetIntegration/ResetIntegration'

const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
`
interface ActionButtonsProps {
  rowValue: CMIIntegrationValue
  refresh: (() => void) | undefined
  editProvider: (selectedProvider: CMIIntegrationValue) => void
}
export const ActionButtons = ({
  rowValue,
  refresh,
  editProvider
}: ActionButtonsProps) => {
  const { user } = useSessionContext()
  return (
    <Wrapper>
      <OpenIndividualIntegrationTable rowValue={rowValue} />
      {user?.type === UserType.HCPAdmin && (
        <>
          {rowValue.state === CMIIntegrationState.failed && (
            <ResetIntegration rowValue={rowValue} refresh={refresh} />
          )}
          <EditProvider rowValue={rowValue} editProvider={editProvider} />
        </>
      )}
    </Wrapper>
  )
}
