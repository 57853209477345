import { UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CMIIntegrationValue } from '../../../../../../containers/ClinicIntegrations/ClinicIntegrations'
import { FormInfoSection } from '../../../../../FormInfoSection/FormInfoSection'
import { ProviderPatientProviderType } from '../../../ProviderPatients/ProviderPatientsInterfaces'
import { BodyText, FormValues } from '../../ProviderIntegrationModel'
import { getProviderTypeInfoText } from '../../ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'
import { InputComponent } from './InputComponent/InputComponent'

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

interface FormPageProps {
  register: UseFormRegister<FormValues>
  provider: CMIIntegrationValue | null
  handleChange: () => void
  currentProvider: ProviderPatientProviderType | null
}

interface getInputFieldsProps {
  currentProvider: ProviderPatientProviderType | null
  register: UseFormRegister<FormValues>
  provider: CMIIntegrationValue | null
  handleChange: () => void
}

const getInputFields = ({
  currentProvider,
  register,
  provider,
  handleChange
}: getInputFieldsProps) => {
  switch (currentProvider) {
    case ProviderPatientProviderType.DexcomAPICMIProvider:
      return (
        <>
          <InputComponent
            label={'clientId'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'clientSecret'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientProviderType.FitbitAPICMIProvider:
      return (
        <>
          <InputComponent
            label={'clientId'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'clientSecret'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientProviderType.MedtronicCarelinkWebCMIProvider:
      return (
        <>
          <InputComponent
            label={'emailAddress'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailHost'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailPassword'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientProviderType.AbbottLibreViewWebCMIProvider:
      return (
        <>
          <InputComponent
            label={'emailAddress'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailHost'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
          <InputComponent
            label={'emailPassword'}
            register={register}
            disabled={provider !== null}
            handleChange={handleChange}
          />
        </>
      )
    case ProviderPatientProviderType.YpsomedAPICMIProvider:
      return <></>
  }
}
export const FormPage = ({
  register,
  provider,
  handleChange,
  currentProvider
}: FormPageProps) => {
  const { t } = useTranslation()
  const getFormPageDescription = (type: ProviderPatientProviderType) => {
    switch (type) {
      case ProviderPatientProviderType.DexcomAPICMIProvider:
        return (
          <TextSection>
            <BodyText>
              {t('setupAPI', {
                provider: getProviderTypeInfoText(currentProvider)
              })}
            </BodyText>
          </TextSection>
        )
      case ProviderPatientProviderType.MedtronicCarelinkWebCMIProvider:
        return (
          <TextSection>
            <BodyText>{t('web_integration_account_description')}</BodyText>
          </TextSection>
        )
      case ProviderPatientProviderType.AbbottLibreViewWebCMIProvider:
        return (
          <TextSection>
            <BodyText>{t('web_integration_account_description')}</BodyText>
          </TextSection>
        )
      case ProviderPatientProviderType.FitbitAPICMIProvider:
        return (
          <TextSection>
            <BodyText>
              {t('setupAPI', {
                provider: getProviderTypeInfoText(currentProvider)
              })}
            </BodyText>
          </TextSection>
        )
    }
  }
  return (
    <>
      {currentProvider && getFormPageDescription(currentProvider)}
      <FormWrapper>
        <InputComponent
          label={'alias'}
          register={register}
          disabled={provider !== null}
          handleChange={handleChange}
        />
        <FormInfoSection>
          {t(
            'Choose an alias that is meaningful to the context of the account. The name will show on the integration list and allow you to distinguish integrations of the same type if there are more than one'
          )}
        </FormInfoSection>
        {currentProvider !== ProviderPatientProviderType.DexcomAPICMIProvider &&
          currentProvider !==
            ProviderPatientProviderType.FitbitAPICMIProvider && (
            <>
              <InputComponent
                label={'username'}
                register={register}
                disabled={provider !== null}
                handleChange={handleChange}
              />
              <InputComponent
                label={'password'}
                register={register}
                disabled={provider !== null}
                handleChange={handleChange}
              />
            </>
          )}
        {getInputFields({
          currentProvider,
          register,
          provider,
          handleChange
        })}
      </FormWrapper>
    </>
  )
}
