import {
  PortalContext,
  TicketProduct,
  UploaderContext
} from '../../constants/constants'
import { TicketPortalContext } from './TicketPortalContext/TicketPortalContext'
import { TicketUploaderContext } from './TicketUploaderContext/TicketUploaderContext'

interface TicketContextProps {
  context: UploaderContext | PortalContext
}
export const TicketContext = ({ context }: TicketContextProps) => {
  switch (context.type) {
    case TicketProduct.Portal:
      return <TicketPortalContext context={context as PortalContext} />
    case TicketProduct.Uploader:
      return <TicketUploaderContext context={context as UploaderContext} />
  }
}
