import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  CMIPatientLog,
  CMIPatientLogDTO,
  CmiPatientLogNames,
  getLatestAPIResponseFromPatientManagedProviderPatient,
  LogLevel
} from '../../../../../../../core/api/getLatestAPIResponseFromPatientManagedProviderPatient'
import dayjs from '../../../../../../../core/dayjs/dayjs'
import { FetchStatus } from '../../../../../../../hooks/useFetch'
import {
  SnackBar,
  SnackBarType
} from '../../../../../../../libraries/Toast/SnackBar'
import { ModalComponent } from '../../../../../../Modal/Modal'
import { Spinner, spinnerSize } from '../../../../../../Spinner/Spinner'
import { TableLayout } from '../../../../../../TableComponents/TableLayout/TableLayout'
import { StickyTable } from '../../../../../../TableComponents/TableStyledElements/TableStyledElements'

const Wrapper = styled.div`
  display: flex;
  width: 53.6875rem;
  height: 40rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1.5rem;
  background-color: var(--white-color);
  padding-bottom: 1rem;
  overflow: hidden;
`
const Header = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  border-bottom: 0.0625rem solid var(--text-lightest);
  background-color: var(--element-bg-80);
`
const Filters = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  flex-wrap: wrap;
`
const FilterMainText = styled.span`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  color: var(--text-primary);
`
const FilterSubText = styled(FilterMainText)`
  color: var(--text-lighter);
`
const Filter = styled.button`
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: flex-end;
  align-items: baseline;
  gap: 0.375rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  cursor: pointer;
  background-color: var(--element-bg-80);
  &:disabled {
    cursor: default;
  }
  &.activeFilter {
    border: 0.0625rem solid var(--brand-primary-16-color);
    background-color: var(--brand-primary-4-color);
    ${FilterMainText} {
      color: var(--brand-primary-color);
    }
    ${FilterSubText} {
      color: var(--text-lighter);
    }
  }
`
const TitleText = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2.25rem;
  text-transform: capitalize;
`

const Value = styled.div<{ $color: string }>`
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 150%;
  color: ${(props) => props.$color};
`

const Body = styled.div`
  overflow: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
const TableWrapper = styled(StickyTable)`
  width: calc(100% - 4rem);
  margin-left: 2rem;
  margin-top: 1rem;
  tr {
    th:nth-child(1),
    td:nth-child(1) {
      width: 9rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 5.5rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
    }
    td:nth-child(2) {
      width: 4rem;
      border-left: none;
    }
    td:nth-child(3) {
      border-left: none;
    }
  }
`
const ValueCapitalize = styled(Value)`
  text-transform: capitalize;
`
const StackTraceWrapper = styled.div`
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  background: var(--element-bg-dark);
  display: inline-block;
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: pre-line;
  user-select: all;
  color: var(--text-medium);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  font-family: roboto;
  width: 100%;
`
interface GetLatestApiResponseModalProps {
  closeFun: () => void
  providerPatientId: string
}
export const GetLatestApiResponseModal = ({
  closeFun,
  providerPatientId
}: GetLatestApiResponseModalProps) => {
  const [selectedFilter, setSelectedFilter] =
    useState<CmiPatientLogNames>('all')
  const [error, setError] = useState<any>(null)
  const [apiResponse, setApiResponse] = useState<CMIPatientLogDTO | null>(null)
  const { t } = useTranslation()
  const [tableData, setTableData] = useState<Array<CMIPatientLog>>([])

  const getLogColorByLevel = (level: LogLevel) => {
    switch (level) {
      case LogLevel.error:
        return 'var(--yellow)'
      case LogLevel.warn:
        return 'var(--yellow)'
      case LogLevel.info:
        return 'var(--text-primary)'
      case LogLevel.debug:
        return 'var(--destructive)'
      case LogLevel.trace:
        return 'var(--destructive)'
    }
  }

  useEffect(() => {
    const getLatestAPIResponse = async () => {
      try {
        setError(false)
        const response =
          await getLatestAPIResponseFromPatientManagedProviderPatient(
            providerPatientId
          )
        setApiResponse(response)
      } catch (error) {
        setError(error)
        SnackBar({
          type: SnackBarType.Error,
          message: t('latest_api_response_provider_patient_fetch_error')
        })
      }
    }
    getLatestAPIResponse()
  }, [providerPatientId, t])

  const getCmiPatientLogFilterText = (
    CmiPatientLogName: CmiPatientLogNames
  ) => {
    switch (CmiPatientLogName) {
      case 'error':
        return t('cmi_patient_log_error_filter')
      case 'warn':
        return t('cmi_patient_log_warn_filter')
      case 'info':
        return t('cmi_patient_log_info_filter')
      case 'debug':
        return t('cmi_patient_log_debug_filter')
      case 'trace':
        return t('cmi_patient_log_trace_filter')
      case 'all':
        return t('cmi_patient_log_all_filter')
    }
  }

  useEffect(() => {
    if (selectedFilter === 'all') {
      setTableData(apiResponse?.logs ?? [])
    } else {
      const newData = apiResponse?.logs.filter(
        (log) => log.level === selectedFilter
      )
      setTableData(newData ?? [])
    }
  }, [apiResponse?.logs, selectedFilter])

  const columns: ColumnDef<CMIPatientLog>[] = useMemo(
    () => [
      {
        id: 'time',
        header: t('cmi_patient_log_key_time'),
        cell: ({ row }: { row: Row<CMIPatientLog> }) =>
          dayjs(row.original.time).format('DD/MM/YYYY HH:mm')
      },
      {
        id: 'level',
        header: t('cmi_patient_log_key_level'),
        cell: ({ row }: { row: Row<CMIPatientLog> }) => (
          <ValueCapitalize $color={getLogColorByLevel(row.original.level)}>
            {row.original.level}
          </ValueCapitalize>
        )
      },
      {
        id: 'message',
        header: t('cmi_patient_log_key_message'),
        cell: ({ row }: { row: Row<CMIPatientLog> }) => (
          <Value $color={getLogColorByLevel(row.original.level)}>
            {row.original.message}
          </Value>
        )
      },
      {
        id: 'floating',
        header: t(''),
        accessorFn: (originalRow: CMIPatientLog) => originalRow.stackTrace,
        cell: ({ row }: { row: Row<CMIPatientLog> }) =>
          row.original.stackTrace ? (
            <StackTraceWrapper>{row.original.stackTrace}</StackTraceWrapper>
          ) : null
      }
    ],
    [t]
  )

  const table = useReactTable({
    data: tableData,
    columns,
    rowCount: tableData.length,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: () => ({}),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row) => true,
    state: {
      expanded: true
    }
  })

  return (
    <ModalComponent
      closeFun={closeFun}
      closetestid={'closeLatestAPIResponseModal'}
    >
      <Wrapper>
        {error ? (
          <span>cant fetch api logs</span>
        ) : (
          <>
            <Header>
              <TitleText>{t('cmi_patient_logs_modal_title')}</TitleText>
              {apiResponse === null ? (
                <Spinner spinnersize={spinnerSize.large} />
              ) : (
                <Filters>
                  <Filter
                    key={'All'}
                    data-testid="AllFilter"
                    className={selectedFilter === 'all' ? 'activeFilter' : ''}
                    onClick={() => setSelectedFilter('all')}
                  >
                    <FilterMainText>
                      {t('cmi_patient_log_all_filter')}
                    </FilterMainText>
                    <FilterSubText>
                      {apiResponse?.logTypes.all ?? 0}
                    </FilterSubText>
                  </Filter>
                  {Object.entries(LogLevel).map(
                    ([key, value]) =>
                      apiResponse?.logTypes[value] !== null && (
                        <Filter
                          key={value}
                          data-testid={value + 'Filter'}
                          className={
                            selectedFilter === value ? 'activeFilter' : ''
                          }
                          onClick={() => setSelectedFilter(value)}
                        >
                          <FilterMainText>
                            {getCmiPatientLogFilterText(value)}
                          </FilterMainText>
                          <FilterSubText>
                            {apiResponse?.logTypes[value] ?? 0}
                          </FilterSubText>
                        </Filter>
                      )
                  )}
                </Filters>
              )}
            </Header>
            <Body>
              {apiResponse && (
                <TableLayout
                  table={table}
                  context={null}
                  fetchStatus={FetchStatus.Success}
                  fetchError={null}
                  usesPagination={false}
                  WrapperElement={TableWrapper}
                />
              )}
            </Body>
          </>
        )}
      </Wrapper>
    </ModalComponent>
  )
}
