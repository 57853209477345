import {
  faArrowUpRightFromSquare,
  faLinkSimple
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ContextMenuItem } from '../../../ContextMenu/ContextMenu'
import { ConsultationsAccountButtonProps } from '../../constants/constants'
import {
  HoverableWrapper,
  Icon,
  Text
} from '../ConsultationsAccountLinkStyledComponents/ConsultationsAccountLinkStyledComponents'

const Link = styled.a`
  text-decoration: none;
`

export const MedtronicCarelinkConsultationsButton = ({
  providerPatient,
  inOptionsMenu
}: ConsultationsAccountButtonProps) => {
  const { t } = useTranslation()

  const translationString = `data-filter.patientaccountlink.${providerPatient.providerType.toLowerCase()}`

  return (
    <>
      {inOptionsMenu ? (
        <Link
          href={`https://carelink.medtronic.eu/clinic/patients/${providerPatient.providerPatientId}/dashboard`}
          target="_blank"
        >
          <ContextMenuItem
            icon={faArrowUpRightFromSquare}
            data-testid="openMedtronicPatientFromOptions"
          >
            {t(`${translationString}.open_in_platform`)}
          </ContextMenuItem>
        </Link>
      ) : (
        <HoverableWrapper
          href={`https://carelink.medtronic.eu/clinic/patients/${providerPatient.providerPatientId}/dashboard`}
          target="_blank"
          data-testid="openMedtronicPatientFromTable"
        >
          <Icon icon={faLinkSimple}></Icon>
          <Text>{t(translationString)}</Text>
        </HoverableWrapper>
      )}
    </>
  )
}
