import config from '../config'
import { AxiosRequest } from './axios-request'

export async function deleteConsultationsImport(
  consultationsId: string,
  options: object = {}
) {
  const response = await AxiosRequest.create().delete(
    config.API_URL + '/consultations_uploads/' + consultationsId,
    options
  )
  return response.data
}
