import { useReducer } from 'react'

export enum DownloadStatus {
  Idle,
  Downloading,
  Completed,
  Failed
}

export enum DownloadActionType {
  DownloadFailed,
  ProgressUpdated,
  Reset,
  Started
}

export interface DownloadState {
  status: DownloadStatus
  progress: number
}

export type DownloadAction =
  | {
      type: DownloadActionType.DownloadFailed
    }
  | {
      type: DownloadActionType.ProgressUpdated
      payload: ProgressEvent
    }
  | {
      type: DownloadActionType.Started
    }
  | {
      type: DownloadActionType.Reset
    }

export type DownloadFunction = (
  progressCb: (progress: ProgressEvent) => void
) => Promise<Blob | null>

/**
 * Hook for downloading a file while handling the progress logic
 *
 * @returns State with status of the download and the progress
 */
export const useDownloader = (): {
  state: DownloadState
  reset: () => void
  download: (downloadFunction: DownloadFunction) => Promise<Blob | null>
} => {
  const [state, dispatch] = useReducer(downloadReducer, {
    status: DownloadStatus.Idle,
    progress: 0
  })

  /**
   * Downloads a file and calls the callback function when the progress changes
   *
   * @param downloadFunction Callback for the progress function
   * @returns Promise<Blob | null>
   */
  async function download(downloadFunction: DownloadFunction) {
    // Ignore if the download is already in progress
    if (state.status === DownloadStatus.Downloading) {
      return null
    }

    try {
      dispatch({ type: DownloadActionType.Started })
      const data = await downloadFunction((event) => {
        dispatch({ type: DownloadActionType.ProgressUpdated, payload: event })
      })
      return data
    } catch (error) {
      dispatch({ type: DownloadActionType.DownloadFailed })
      return null
    }
  }

  function reset() {
    dispatch({ type: DownloadActionType.Reset })
  }

  return {
    download,
    reset,
    state
  }
}

/**
 * React reducer for handling state related to the download process
 * @param state Download state
 * @param action Action for modifying the download state
 * @returns Download state modified
 */
function downloadReducer(
  state: DownloadState,
  action: DownloadAction
): DownloadState {
  switch (action.type) {
    case DownloadActionType.Reset:
      return {
        ...state,
        status: DownloadStatus.Idle,
        progress: 0
      }
    case DownloadActionType.Started:
      return {
        ...state,
        status: DownloadStatus.Downloading,
        progress: 0
      }
    case DownloadActionType.DownloadFailed:
      return {
        ...state,
        status: DownloadStatus.Failed,
        progress: 0
      }
    case DownloadActionType.ProgressUpdated: {
      const progress = Math.round(
        (action.payload.loaded * 100) / action.payload.total
      )

      if (progress >= 100) {
        return {
          ...state,
          status: DownloadStatus.Completed,
          progress
        }
      }

      return {
        ...state,
        status: DownloadStatus.Downloading,
        progress
      }
    }
  }
}
