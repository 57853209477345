import {
  faArrowDownToLine,
  faCircleSmall,
  faInfoCircle
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal } from '../../../components/Modal/Modal'
import { useSessionContext } from '../../../core/contexts/session-context'
import { useSiteSettingsContext } from '../../../core/contexts/site-settings-context'
import { UserType } from '../../../core/entities/user.entity'
import { OperatingSystem } from '../../../helpers/getOperatingSystem'
import { DownloadState, DownloadStatus } from '../../../hooks/use-downloader'
import { AllDownloads } from '../AllDownloads/AllDownloads'
import { Button, OpenUploader } from '../OpenUploader/OpenUploader'
import { Platform } from '../platform-utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  width: 37.5rem;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const Title = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-weight: 700;
  font-size: 1.25rem;
`
const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`
const SubTitle = styled.span`
  color: var(--text-lighter);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
`
const SubTitleLink = styled(SubTitle)`
  color: var(--text-medium);
  text-decoration-line: underline;
  cursor: pointer;
`
const Seperator = styled(FontAwesomeIcon)`
  color: var(--text-lightest);
  font-size: 0.5rem;
  font-weight: 900;
`
const Box = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5rem;
  background-color: var(--element-bg);
`
const BoxWrapper = styled.div`
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  gap: 0.75rem;
`
const InfoLogo = styled(FontAwesomeIcon)`
  font-size: 1.125rem;
  color: var(--blue);
  font-weight: 900;
`
const BoxParagraph = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
`

const ButtonText = styled.span`
  font-family: inter;
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
`

const DownloadIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: var(--text-primary);
  font-weight: 900;
`
const DownloadUploaderButton = styled(Button)`
  background-color: var(--element-bg);
  border: 0.0625rem solid var(--text-lightest);
  &:hover {
    background-color: var(--element-bg-dark);
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

const DownloadProgressWrapper = styled.div`
  width: 100%;
  height: 0.375rem;
  position: absolute;
  bottom: 0;
  left: 0;
`
const DownloadProgress = styled.div<{ $progress: number }>`
  width: ${(props) => props.$progress}%;
  height: 100%;
  background-color: var(--blue);
`
interface InfoBoxProps {
  deploymentName: string | undefined
}
const InfoBox = ({ deploymentName }: InfoBoxProps) => {
  const { t } = useTranslation()
  const { user } = useSessionContext()
  return (
    <Box>
      <BoxWrapper>
        <InfoLogo icon={faInfoCircle} />
        <BoxParagraph>
          {user?.type === UserType.Patient
            ? t('uploaderInfo', {
                deploymentName
              })
            : t('uploaderInfoClinician', {
                deploymentName
              })}
        </BoxParagraph>
      </BoxWrapper>
    </Box>
  )
}

interface DownloadPageProps {
  currentPlatform: Platform
  downloadInstaller: (platform: string) => Promise<void>
  otherPlatforms: Array<Platform>
  state: DownloadState
  setRecentlyDownloaded: Dispatch<SetStateAction<OperatingSystem | null>>
}
interface DownloadButtonProps {
  currentPlatform: Platform
  downloadInstaller: (platform: string) => Promise<void>
  state: DownloadState
  setRecentlyDownloaded: Dispatch<SetStateAction<OperatingSystem | null>>
}

const DownloadButton = ({
  downloadInstaller,
  currentPlatform,
  state,
  setRecentlyDownloaded
}: DownloadButtonProps) => {
  const { t } = useTranslation()
  return (
    <DownloadUploaderButton
      data-testid="downloadUploaderButton"
      disabled={state.status === DownloadStatus.Downloading}
      onClick={() => {
        downloadInstaller(currentPlatform.platform)
        setRecentlyDownloaded(currentPlatform.os)
      }}
    >
      {state.status === DownloadStatus.Downloading ? (
        <>
          <DownloadIcon icon={faArrowDownToLine} />
          <ButtonText>{`${t('Downloading...')} ${
            state.progress
          }% `}</ButtonText>
          <DownloadProgressWrapper data-testid="uploaderDownloadProgress">
            <DownloadProgress $progress={state.progress} />
          </DownloadProgressWrapper>
        </>
      ) : (
        <>
          <DownloadIcon icon={faArrowDownToLine} />
          <ButtonText>
            {t('downloadUploader', {
              platform: currentPlatform?.os
            })}
          </ButtonText>
        </>
      )}
    </DownloadUploaderButton>
  )
}
export const DownloadPage = ({
  currentPlatform,
  downloadInstaller,
  otherPlatforms,
  state,
  setRecentlyDownloaded
}: DownloadPageProps) => {
  const { t } = useTranslation()
  const [showAllDownloads, setShowAllDownloads] = useState<boolean>(false)
  const { siteSettings } = useSiteSettingsContext()
  async function download(platform: string) {
    downloadInstaller(platform)
    setShowAllDownloads(false)
  }
  return (
    <Wrapper>
      <Modal isOpen={showAllDownloads}>
        <AllDownloads
          closeFun={() => setShowAllDownloads(false)}
          allPlatforms={[...otherPlatforms, currentPlatform]}
          downloadInstaller={download}
          setRecentlyDownloaded={setRecentlyDownloaded}
        ></AllDownloads>
      </Modal>
      <SectionWrapper>
        <Title>{`Stenopool ${t('Uploader')}`}</Title>
        <SubTitleWrapper>
          <SubTitle>{currentPlatform?.os}</SubTitle>
          <Seperator icon={faCircleSmall} />
          <SubTitle>{siteSettings?.uploaderVersion ?? 'v x.x.x'}</SubTitle>
          <Seperator icon={faCircleSmall} />
          <SubTitleLink
            data-testid="seeAllDownloads"
            onClick={() => setShowAllDownloads(true)}
          >
            {t('See all versions')}
          </SubTitleLink>
        </SubTitleWrapper>
      </SectionWrapper>
      <InfoBox deploymentName={'Stenopool'} />
      <SectionWrapper>
        <DownloadButton
          downloadInstaller={download}
          currentPlatform={currentPlatform}
          state={state}
          setRecentlyDownloaded={setRecentlyDownloaded}
        />
        <OpenUploader />
      </SectionWrapper>
    </Wrapper>
  )
}
