import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'i18next'
import styled from 'styled-components'
import { HeaderWrapper } from '../../../../components/IntegrationComponents/ClinicLevelComponents/ProviderPatients/ProviderPatients'
import { spinnerSize } from '../../../../components/Spinner/Spinner'
import { ErrorRow } from '../../../../components/Table/ErrorRow'
import { NoDataRow } from '../../../../components/Table/NoDataRow'
import {
  THead,
  TText,
  Td,
  Th,
  Tr
} from '../../../../components/Table/ScrollTableComponent'
import { ScrollTableLoading } from '../../../../components/Table/ScrollTableLoading.tsx'
import { PopulationOverview } from '../interfaces'
const EntryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  gap: 0.5rem;
  flex-direction: column;
`
const Entry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.5rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--text-lightest);
  gap: 0.5rem;
  width: fit-content;
`

const EntryName = styled.span`
  color: var(--text-primary);
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2.25rem;
`
const EntryIcon = styled(FontAwesomeIcon)`
  color: var(--text-lighter);
  font-size: 0.75rem;
  line-height: 2.25rem;
`
const EntryValue = styled.span`
  color: var(--text-lighter);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 2.25rem;
`

interface PopulationEntryProps {
  name: string
  value: number
}
export const PopulationEntry = ({ name, value }: PopulationEntryProps) => {
  return (
    <Entry>
      <EntryName>{name}</EntryName>
      <EntryIcon icon={faUser} />
      <EntryValue>{value}</EntryValue>
    </Entry>
  )
}
export const PopulationGroup = () => {
  return (
    <colgroup data-testid="populationGroup">
      <col style={{ width: '22.5%' }}></col>
      <col style={{ width: '77.5%' }}></col>
    </colgroup>
  )
}
export const PopulationHeader = () => {
  return (
    <THead data-testid="populationHeader">
      <Th>
        <HeaderWrapper>
          <TText>{t('Population')}</TText>
        </HeaderWrapper>
      </Th>
      <Th>
        <HeaderWrapper>
          <TText>{t('Sub-populations')}</TText>
        </HeaderWrapper>
      </Th>
    </THead>
  )
}
export const PopulationBody = ({
  populations,
  error
}: {
  populations: PopulationOverview[] | null
  error: Error | null
}) => {
  const getBody = () => {
    if (error) {
      return <ErrorRow />
    }
    if (populations) {
      if (populations.length === 0) {
        return <NoDataRow />
      } else {
        return populations.map((population) => (
          <Tr data-testid="populationBodyRow" key={population.population.id}>
            <Td>
              <EntryWrapper>
                <PopulationEntry
                  name={population.population.name}
                  value={population.total}
                />
              </EntryWrapper>
            </Td>
            <Td>
              <EntryWrapper>
                {Object.values(population.distribution).map(
                  (distribution: { name: string; size: number } | null) => {
                    return distribution !== null ? (
                      <PopulationEntry
                        key={distribution.name}
                        name={distribution.name}
                        value={distribution.size}
                      />
                    ) : (
                      <></>
                    )
                  }
                )}
              </EntryWrapper>
            </Td>
          </Tr>
        ))
      }
    } else {
      return <ScrollTableLoading size={spinnerSize.large} />
    }
  }
  return <>{getBody()}</>
}
