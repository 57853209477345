import { useCallback, useEffect, useMemo } from 'react'
import { useFiltersManagerContext } from '../components/TableComponents/Filters/DataFilter/FiltersManagerContext'

export type SelectedType = string[] | string | undefined

export interface UseFilterHook {
  selected: SelectedType
  allSelected: boolean
  data: string[]
  toggle: (value: any) => void
  deselectAll: () => void
  selectAll: () => void
  toggleAll: () => void
  reset: () => void
}

export function useDataFilter(
  data: string[],
  initialState: string[] | string | undefined,
  currentState: string[] | string | undefined,
  setSelected: (values: SelectedType) => void
): UseFilterHook {
  const { resetAll, setResetAll } = useFiltersManagerContext()
  const allSelected = useMemo(() => {
    return currentState?.length === data.length
  }, [currentState, data])
  const defaults = useMemo(() => initialState, [initialState])

  const reset = useCallback(() => {
    setSelected(defaults)
  }, [setSelected, defaults])

  useEffect(() => {
    if (resetAll) {
      reset()
    }
    return () => {
      setResetAll(false)
    }
  }, [resetAll, reset, setResetAll])

  const toggle = (value: string) => {
    setSelected(
      (() => {
        if (Array.isArray(currentState)) {
          return currentState.includes(value)
            ? currentState.filter((v) => v !== value)
            : [...currentState, value]
        } else return value
      })()
    )
  }

  const deselectAll = () => {
    setSelected([])
  }

  const selectAll = () => {
    setSelected(data)
  }

  const toggleAll = () => {
    if (allSelected) {
      deselectAll()
    } else {
      selectAll()
    }
  }

  return {
    selected: currentState,
    allSelected,
    data,
    toggle,
    toggleAll,
    deselectAll,
    selectAll,
    reset
  }
}
