import { useTranslation } from 'react-i18next'
import { ConsultationsData } from '../constants/constants'
import { ConsultationsAccountLinkButton } from '../ConsultationsAccountLink/ConsultationsAccountLink'

export function ConsultationsOptionsProvider({
  row
}: {
  row: ConsultationsData
}) {
  const { t } = useTranslation()

  return (
    <>
      {row.providerPatients
        ? row.providerPatients.map((providerPatient) => (
            <ConsultationsAccountLinkButton
              providerPatient={providerPatient}
              row={row}
              inOptionsMenu={true}
            />
          ))
        : null}
    </>
  )
}
