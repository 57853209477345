import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IntegrationTypeLabel } from '../../../../IntegrationTypeLabel/IntegrationTypeLabel'
import { ProviderPatientProviderType } from '../../ProviderPatients/ProviderPatientsInterfaces'
import {
  getIntegrationManagementText,
  getIntegrationType,
  getIntegrationTypeText,
  getProviderTypeInfoText
} from '../ProviderIntegrationModelFunctions/ProviderIntegrationModelFunctions'

const SetupButton = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border: 0.0625rem solid var(--text-lightest);
  border-radius: 0.5rem;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-color);
  color: var(--text-primary);
  &:hover {
    background-color: var(--brand-primary-4-color);
    border-color: var(--brand-primary-12-color);
    color: var(--brand-primary-color);
  }
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`
const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`
const Title = styled.span`
  font-size: 1rem;
  font-family: inter;
  font-weight: 600;
  color: inherit;
`
const SubTitle = styled.span`
  color: inherit;
  font-family: inter;
  font-size: 0.875rem;
  font-weight: 500;
`
const TextSeperator = styled(FontAwesomeIcon)`
  color: var(--text-lightest);
  font-size: 0.5rem;
  font-weight: 900;
  font-style: normal;
`
const SetupIcon = styled(FontAwesomeIcon)`
  font-size: 0.875rem;
  font-weight: 900;
  color: inherit;
`
interface SetupButtonComponentProps {
  provider: ProviderPatientProviderType
  next: () => void
}

export const SetupButtonComponent = ({
  provider,
  next
}: SetupButtonComponentProps) => {
  const { t } = useTranslation()
  return (
    <SetupButton onClick={next} data-testid={`SetupButtonComponent${provider}`}>
      <TitleSection>
        <Title>{t(getProviderTypeInfoText(provider))}</Title>
        <RowWrapper>
          <IntegrationTypeLabel type={getIntegrationType(provider)} />
          <SubTitle>
            {getIntegrationTypeText(getIntegrationType(provider), t)}
          </SubTitle>
          <TextSeperator icon={faCircleSmall} />
          <SubTitle>
            {getIntegrationManagementText(getIntegrationType(provider), t)}
          </SubTitle>
        </RowWrapper>
      </TitleSection>
      <SetupIcon icon={faChevronRight} />
    </SetupButton>
  )
}
