import { faCircleSmall } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'
import { TicketType } from '../../containers/Tickets/constants/constants'
import { TicketCreation } from '../../containers/Tickets/TicketCreation/TicketCreation'
import { useSiteSettingsContext } from '../../core/contexts/site-settings-context'
import { Modal } from '../Modal/Modal'
import { About } from './About/About'
import { Support } from './Support/Support'
export enum PortalFooterTheme {
  light,
  dark
}
interface PortalFooterProps {
  theme: PortalFooterTheme
  showFragileInfo: boolean
}

interface FooterItemProps {
  children: ReactNode
  prefix?: string
  clickFun?: () => void
  style?: CSSProperties
  testid?: string
  $theme?: PortalFooterTheme
}
export const Dot = styled(FontAwesomeIcon)`
  color: var(--text-lightest);
  font-size: 0.5rem;
  font-weight 900;
`
const Footer = styled.div<{ $theme: PortalFooterTheme | undefined }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: fixed;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  bottom: 0rem;
  left: 0rem;
  gap: 0.75rem;
  border-radius: 0rem 0.5rem 0rem 0rem;
  border-top: ${(props) =>
    props.$theme === PortalFooterTheme.light
      ? 'none'
      : '0.0625rem solid var(--transparentWhite80-color)'};
  border-right: ${(props) =>
    props.$theme === PortalFooterTheme.light
      ? 'none'
      : '0.0625rem solid var(--transparentWhite80-color)'};
  background-color: ${(props) =>
    props.$theme === PortalFooterTheme.light
      ? 'none'
      : 'var(--transparentWhite80-color)'};
  backdrop-filter: blur(0.25rem);
`
const ItemWrapper = styled.div<{ $theme: PortalFooterTheme | undefined }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
  color: ${(props) =>
    props.$theme === PortalFooterTheme.light
      ? 'var(--white-color)'
      : 'var(--text-medium)'};
  &:hover {
    color: var(--brand-primary-color);
    background-color: ${(props) =>
      props.$theme === PortalFooterTheme.light
        ? 'var(--white-color)'
        : 'var(--element-bg)'};
  }
`
const ItemPrefix = styled.span<{ $theme: PortalFooterTheme | undefined }>`
  font-family: inter;
  font-weight: 600;
  font-size: 0.75rem;
  color: ${(props) =>
    props.$theme === PortalFooterTheme.light
      ? 'var(--transparentWhite40-color)'
      : 'var(--text-lighter)'};
`
const ItemText = styled.span`
  font-family: inter;
  font-weight: 600;
  font-size: 0.75rem;
  color: inherit;
`

const VersionWrapper = styled.div<{ $theme: PortalFooterTheme | undefined }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: text;
  color: ${(props) =>
    props.$theme === PortalFooterTheme.light
      ? 'var(--white-color)'
      : 'var(--text-medium)'};
`

export const FooterItem = ({
  children,
  prefix,
  clickFun,
  style,
  testid,
  $theme
}: FooterItemProps) => {
  return (
    <ItemWrapper
      onClick={clickFun}
      style={style}
      data-testid={testid}
      $theme={$theme}
    >
      {prefix !== undefined && (
        <ItemPrefix $theme={$theme}>{prefix}</ItemPrefix>
      )}
      {children}
    </ItemWrapper>
  )
}

enum PortalFooterModalType {
  AboutModal,
  SupportModal
}

export const PortalFooter = ({ theme, showFragileInfo }: PortalFooterProps) => {
  const [showPortalFooterModalType, setShowPortalFooterModalType] =
    useState<PortalFooterModalType | null>(null)
  const [showTicketCreation, setShowTicketCreation] =
    useState<TicketType | null>(null)
  const [ticketSuccess, setTicketSuccess] = useState(false)
  const { siteSettings } = useSiteSettingsContext()
  const { t } = useTranslation()

  const config = {
    deploymentName: `${siteSettings?.deploymentName}`,
    siteId: `${siteSettings?.siteId}`,
    productName: 'Line Portal',
    builtBy: 'Line Systems',
    releaseVersion: `${siteSettings?.releaseVersion}`,
    frontendVersion: `\u00A0v${process.env.REACT_APP_UI_VERSION}\u00A0(${process.env.REACT_APP_UI_BUILD})`,
    backendVersion: `\u00A0${siteSettings?.version}\u00A0(${siteSettings?.githubRunId})`,
    productUDI: `${siteSettings?.productUDI}`,
    dateOfManufacture: siteSettings?.dateOfManufacture ?? '-'
  }

  const handleShowTicketCreation = (ticketType: TicketType) => {
    setShowTicketCreation(ticketType)
    setShowPortalFooterModalType(null)
  }

  const handleCloseTicketCreation = () => {
    if (ticketSuccess || window.confirm(t('wanna_close'))) {
      setShowTicketCreation(null)
    }
    setTicketSuccess(false)
  }

  return (
    <Footer data-testid="portalFooter" $theme={theme}>
      <Modal
        isOpen={showPortalFooterModalType === PortalFooterModalType.AboutModal}
        onRequestClose={() => setShowPortalFooterModalType(null)}
      >
        <About
          closeFun={() => setShowPortalFooterModalType(null)}
          config={config}
          showFragileInfo={showFragileInfo}
          showTicketCreation={handleShowTicketCreation}
        />
      </Modal>
      <Modal
        isOpen={
          showPortalFooterModalType === PortalFooterModalType.SupportModal
        }
        onRequestClose={() => setShowPortalFooterModalType(null)}
      >
        <Support
          closeFun={() => setShowPortalFooterModalType(null)}
          config={config}
          showFragileInfo={showFragileInfo}
          showTicketCreation={handleShowTicketCreation}
        />
      </Modal>
      <Modal
        isOpen={showTicketCreation !== null}
        onRequestClose={handleCloseTicketCreation}
      >
        {showTicketCreation && (
          <TicketCreation
            closeFun={handleCloseTicketCreation}
            type={showTicketCreation}
            setTicketSuccess={setTicketSuccess}
          />
        )}
      </Modal>
      <VersionWrapper $theme={theme}>
        <ItemPrefix $theme={theme}>{`${t('Version')}\u00A0`}</ItemPrefix>
        <ItemText data-testid="portalVersion">
          {siteSettings?.releaseVersion}
        </ItemText>
      </VersionWrapper>
      <Dot icon={faCircleSmall} />
      <FooterItem
        clickFun={() =>
          setShowPortalFooterModalType(PortalFooterModalType.AboutModal)
        }
        testid="aboutButton"
        $theme={theme}
      >
        <ItemText>{t('About')}</ItemText>
      </FooterItem>
      <Dot icon={faCircleSmall} />
      <FooterItem
        $theme={theme}
        clickFun={() =>
          setShowPortalFooterModalType(PortalFooterModalType.SupportModal)
        }
        testid="supportButton"
      >
        <ItemText>{t('Support')}</ItemText>
      </FooterItem>
    </Footer>
  )
}
